import { UpsertTargetDto } from '@cozero/dtos'
import { ActGraphData, Target, TargetLevel, TargetTemplate } from '@cozero/models'
import { actApiGatewayClient } from '@cozero/uris'

import { LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import apiSlice from '../api'
import {
  TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES,
  TAG_GET_STRATEGY_BUILDER_STATE,
} from '../strategyBuilder'

import { TAG_TARGETS, TAG_TARGET_GRAPH, TAG_TARGET_TEMPLATES } from './tags'

export const GET_TARGET = 'getTarget'
export const GET_TARGETS = 'getTargets'
export const CREATE_TARGET = 'createTarget'
export const EDIT_TARGET = 'editTarget'
export const DELETE_TARGET = 'deleteTarget'
export const GET_TARGET_TEMPLATES = 'getTargetTemplates'
export const GET_TARGET_GRAPH = 'getTargetGraph'

const targetsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_TARGETS]: builder.query<
      Target[],
      {
        selectedBusinessUnit?: number
        level?: TargetLevel
        query?: { locationIds?: string[]; businessUnit?: string; nameFilter?: string }
      }
    >({
      query: ({ selectedBusinessUnit, level, query }) => ({
        url: actApiGatewayClient.targets.GET_MANY,
        method: 'GET',
        params: {
          businessUnitId: selectedBusinessUnit,
          level,
          ...query,
        },
      }),
      providesTags: (result) => providesList<Target[], typeof TAG_TARGETS>(result, TAG_TARGETS),
    }),

    [GET_TARGET]: builder.query<Target, Target['id']>({
      query: (id) => ({
        url: actApiGatewayClient.targets.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_TARGETS, id: result?.id }],
    }),

    [CREATE_TARGET]: builder.mutation<Target, UpsertTargetDto>({
      query: (target) => ({
        url: actApiGatewayClient.targets.CREATE,
        method: 'POST',
        data: target,
      }),
      invalidatesTags: [
        { type: TAG_TARGETS, id: LIST },
        TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES,
        { type: TAG_GET_STRATEGY_BUILDER_STATE },
      ],
      extraOptions: { maxRetries: 0 },
    }),

    [EDIT_TARGET]: builder.mutation<Target, Partial<Target>>({
      query: (target) => ({
        url: actApiGatewayClient.targets.UPDATE_ONE.replace(':id', target?.id?.toString() ?? ''),
        method: 'PUT',
        data: target,
      }),
      invalidatesTags: (result) => [
        { type: TAG_TARGETS, id: LIST },
        { type: TAG_TARGETS, id: result?.id },
        TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES,
        TAG_TARGET_GRAPH,
      ],
      extraOptions: { maxRetries: 0 },
    }),

    [DELETE_TARGET]: builder.mutation<
      Target,
      { id: Target['id']; level: TargetLevel; businessUnitId?: number }
    >({
      query: ({ id, businessUnitId, level }) => ({
        url: actApiGatewayClient.targets.DELETE_ONE.replace(':id', id?.toString() ?? ''),
        method: 'DELETE',
        params: {
          businessUnitId,
          level,
        },
      }),
      invalidatesTags: (result) => [
        { type: TAG_TARGETS, id: LIST },
        { type: TAG_TARGETS, id: result?.id },
        TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES,
        TAG_TARGET_GRAPH,
      ],
    }),

    [GET_TARGET_TEMPLATES]: builder.query<TargetTemplate[], void>({
      query: () => ({
        url: actApiGatewayClient.targetTemplates.GET_MANY,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<TargetTemplate[], typeof TAG_TARGET_TEMPLATES>(result, TAG_TARGET_TEMPLATES),
    }),

    [GET_TARGET_GRAPH]: builder.query<ActGraphData[][], number[]>({
      query: (selectedTargets) => ({
        url: actApiGatewayClient.targets.GET_PLOT,
        method: 'POST',
        data: {
          selectedTargets,
        },
      }),
      providesTags: () => [TAG_TARGET_GRAPH],
    }),
  }),
})

export const {
  useGetTargetsQuery,
  useGetTargetQuery,
  useCreateTargetMutation,
  useEditTargetMutation,
  useDeleteTargetMutation,
  useGetTargetTemplatesQuery,
  useLazyGetTargetTemplatesQuery,
  useGetTargetGraphQuery,
  useLazyGetTargetGraphQuery,
  usePrefetch,
} = targetsApiSlice

export default targetsApiSlice
