import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Space } from 'antd/es'

import { Organization } from '@cozero/models'

import { ContactModal } from '@/organisms/ContactModal'

import Modal from '@/molecules/Modal'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import classes from './classes.module.less'

interface Props {
  handleCancel: () => void
  visible: boolean
  title: string
  getCalendarLink: () => Promise<string | void>
  goToSubscriptionPage: () => void
  organization?: Organization
}

export function UpgradeModal({
  visible,
  handleCancel,
  title,
  goToSubscriptionPage,
  organization,
}: Props): JSX.Element {
  const { t } = useTranslation('common')
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>()

  const openContactModal = async (): Promise<void> => {
    setSelectedOrganization(organization)
  }

  const closeContactModal = (): void => {
    setSelectedOrganization(undefined)
  }

  return (
    <Modal visible={visible} title={title} onCancel={handleCancel} className={classes.modal}>
      <div className={classes.modalContainer}>
        <Space className={classes.sections} direction="horizontal" size="large">
          <Card className={classes.card}>
            <Title as="h5" className={classes.sectionTitle}>
              {t('subscription.upgrade-modal.subscribe.title')}
            </Title>
            <Text size="xl" color="secondary">
              {t('subscription.upgrade-modal.subscribe.subtitle')}
            </Text>
            <Button
              category="subscriptions"
              action="go-to-subscriptions"
              className={classes.button}
              type="primary"
              onClick={goToSubscriptionPage}
            >
              {t('subscription.upgrade-modal.subscribe.cta')}
            </Button>
          </Card>
          <Card className={classes.card}>
            <Title as="h5" className={classes.sectionTitle}>
              {t('subscription.upgrade-modal.sales.title')}
            </Title>
            <Text size="xl" color="secondary">
              {t('subscription.upgrade-modal.sales.subtitle')}
            </Text>
            <Button
              category="subscriptions"
              action={'go-to-calendar'}
              className={classes.button}
              type="secondary"
              onClick={openContactModal}
            >
              {t('subscription.upgrade-modal.sales.cta')}
            </Button>
          </Card>
        </Space>
      </div>
      <ContactModal selectedOrganization={selectedOrganization} handleCancel={closeContactModal} />
    </Modal>
  )
}
