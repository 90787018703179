import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiCloudUpload, HiOutlineBookOpen, HiOutlineInformationCircle } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Cascader, Col, Form, FormInstance, Modal, Row, Select, Spin, Upload, message } from 'antd'
import ImgCrop from 'antd-img-crop'
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload/interface'

import { skipToken } from '@reduxjs/toolkit/query/react'

import {
  FactorRequest,
  Product,
  ProductTag,
  ProductTypeWithExtraInfo,
  Territory,
} from '@cozero/models'

import LifecycleModalTitle from '@/pages/Organization/NewProduct/LifecycleModalTitle'
import { Step } from '@/pages/Organization/NewProduct/steps'

import ProductTypeSelect from '@/molecules/ProductTypeSelect'
import TagInput from '@/molecules/TagInput'

import Button from '@/atoms/Button'
import InputField from '@/atoms/InputField'
import Text from '@/atoms/Text'

import productConfiguration from '@/assets/lifecycle-steps/product-configuration.svg'
import { useLifecycleContext } from '@/contexts/lifecycle'
import { useLogContext } from '@/contexts/log'
import useLog from '@/hooks/useLog'
import useProducts, { UploadProductConfigurationParams } from '@/hooks/useProducts'
import { selectSelectedBusinessUnitKey } from '@/redux/businessUnits'
import { useGetOrganizationUsersQuery } from '@/redux/organizations'
import { CascaderNode } from '@/types/general'

import { SupplierProduct } from '../..'
import { GRAY_400 } from '../../../../../styles/variables'

import classes from './UpsertProduct.module.less'

const { Option } = Select

interface UpsertProductProps {
  form: FormInstance
  product?: Product
  supplierProduct?: SupplierProduct
  territories: Territory[]
  STEPS: Step[]
  onSubmitNewProductData: (data: ProductConfigurationValues) => Promise<void>
  factorRequest?: FactorRequest
}

export interface ProductConfigurationValues {
  photo: string | UploadProductConfigurationParams | null
  name: string
  productType: number | string
  functionalUnit: number
  mass: number
  massUnit: number
  tags?: string[] | ProductTag[]
  responsibleId?: number
  territory?: number
  selectedSteps?: string[]
}

const UpsertProduct = ({
  form,
  product,
  supplierProduct,
  territories,
  STEPS,
  factorRequest,
  onSubmitNewProductData,
}: UpsertProductProps): JSX.Element => {
  const { t } = useTranslation('common')
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [isUnitEditable, setIsUnitEditable] = useState(true)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [isImageTouched, setIsImageTouched] = useState(false)
  const [tagOptions, setTagOptions] = useState<ProductTag[]>([])
  const { getProductTypes, productTypes, getSignPutProductConfigurationUrl, parseData } =
    useProducts()
  const { getProductTags, createProductTag, deleteProductTag } = useLogContext()
  const { units, getUnits } = useLog()
  const businessUnitKey = useSelector(selectSelectedBusinessUnitKey)
  const { data: users } = useGetOrganizationUsersQuery(
    { businessUnit: businessUnitKey ?? '' } ?? skipToken,
  )

  const [loading, setLoading] = useState(true)
  const { selectedSteps } = useLifecycleContext()

  const continents = useMemo(
    () =>
      territories
        .filter((territory) => territory.continent?.alpha3Code === 'GLO')
        .map((territory) => {
          return { value: territory.id, label: territory.name, children: [] }
        }),
    [territories],
  )
  const possibleTerritories = useMemo(() => {
    const possibleTerritoriesaux = territories
      .filter(
        (territory) =>
          !!territory.continent?.alpha3Code && territory.continent.alpha3Code !== 'GLO',
      )
      .reduce((sorted, next) => {
        const continent = continents.findIndex((continent) => continent.value === next.continentId)
        if (continent > -1) {
          sorted[continent].children.push({ value: next.id, label: next.name, children: [] })
        }
        return sorted
      }, continents as CascaderNode[])
    possibleTerritoriesaux.forEach((continent: CascaderNode) =>
      continent.children.sort((a, b) => a.label.localeCompare(b.label)),
    )
    return possibleTerritoriesaux
  }, [territories])

  const populateData = (data: ProductConfigurationValues): void => {
    data.photo &&
      setFileList([
        {
          uid: '0',
          name: 'Product image',
          status: 'done',
          url: data.photo as string,
        },
      ])
    if (continents) {
      const territory = territories.find((x) => x.id === data.territory)

      form.setFieldsValue({
        ...data,
        territory: territory?.id ? [territory?.continentId, territory?.id] : [],
        tags: data.tags?.map((x) => (x as ProductTag).name || x),
      })
    }
  }

  const getSignedUrl = async (
    newFileList: UploadFile<unknown>[],
  ): Promise<{ name: string; fileObj?: RcFile; signedUrl: string; path: string }[]> => {
    const urls = await getSignPutProductConfigurationUrl(newFileList.map(({ name }) => name))
    return [
      ...newFileList.map((x, index) => ({
        name: x.name,
        fileObj: x.originFileObj,
        signedUrl: urls[index].signedUrl,
        path: urls[index].path,
      })),
    ]
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      newFileList.shift()
    }
    if (!newFileList.length) {
      newFileList.pop()
    } else {
      newFileList[0].status = 'done'
    }

    setIsImageTouched(true)
    setFileList(newFileList)
  }

  const beforeUpload = (file: RcFile): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(t('photo.upload-format'))
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error(t('photo.upload-size'))
    }
    setIsImageTouched(true)
    return isJpgOrPng && isLt2M
  }

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  const onPreview = async (file: UploadFile<unknown>): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
  }

  const mapTags = (tags: (string | ProductTag)[]): ProductTag[] => {
    return tags.map((tag) =>
      typeof tag === 'string' ? tagOptions.find((option) => option.name === tag) : tag,
    ) as ProductTag[]
  }

  const onSubmit = async (
    values: ProductConfigurationValues & { territory: number[] },
  ): Promise<void> => {
    const selectedStepsKeys = selectedSteps.map((x) => STEPS[x.index].stepKey)
    const data: ProductConfigurationValues & { territoryId?: number } = {
      ...values,
      mass: Number(values.mass),
      selectedSteps: selectedStepsKeys || [],
      territoryId: values.territory?.length
        ? values.territory[values.territory.length - 1]
        : undefined,
      productType:
        typeof values.productType === 'number'
          ? values.productType
          : (
              productTypes.find(
                (type) => type.name === values.productType,
              ) as ProductTypeWithExtraInfo
            )?.id,
      tags: values.tags ? mapTags(values.tags as string[]) : [],
    }
    const files = fileList
    if (isImageTouched) {
      const filesToUpload = files.length ? await getSignedUrl(files) : []
      data.photo = filesToUpload[0] || null
    }
    delete data.territory
    await onSubmitNewProductData(data)
    form.resetFields()
  }

  useEffect(() => {
    if (product && territories) {
      const parsedData = parseData(product)
      populateData(parsedData)
    }
    if (supplierProduct) {
      form.setFields([
        { name: 'massUnit', value: supplierProduct.unitId },
        { name: 'mass', value: 1 },
        { name: 'name', value: supplierProduct.productName },
      ])
    }
    if (factorRequest) {
      form.setFields([
        { name: 'massUnit', value: factorRequest.unitId },
        { name: 'mass', value: 1 },
      ])
      setIsUnitEditable(false)
    }
  }, [product, territories, supplierProduct, factorRequest])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true)
      await getProductTypes()
      await getUnits()
      setLoading(false)
    }
    fetchData()
  }, [])

  const handleCancel = (): void => setPreviewVisible(false)

  const dummyRequest: UploadProps['customRequest'] = ({ file, onSuccess }): void => {
    onSuccess && onSuccess(file)
  }

  useEffect(() => {
    const fetchTags = async (): Promise<void> => {
      const tags = await getProductTags('')
      setTagOptions(tags)
    }
    fetchTags()
  }, [])

  return (
    <>
      <Spin spinning={loading}>
        <Row>
          <LifecycleModalTitle
            image={productConfiguration}
            title={t('product.lifecycle-steps.configuration.title')}
            description={t('product.lifecycle-steps.configuration.subtitle')}
          />

          <Form layout="vertical" form={form} onFinish={onSubmit}>
            <Form.Item name="upload">
              <ImgCrop rotate minZoom={0.1}>
                <Upload
                  className={classes.photo}
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={onPreview}
                  onChange={handleChange}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  customRequest={dummyRequest}
                >
                  <Row>
                    <Col span={24}>
                      <HiCloudUpload />
                    </Col>
                    <Col span={24}>{t('product.lifecycle-steps.configuration.upload-picture')}</Col>
                  </Row>
                </Upload>
              </ImgCrop>
            </Form.Item>
            <Row className={classes.formItems}>
              <Col span={7}>
                <Form.Item
                  name="name"
                  label={t('product.lifecycle-steps.configuration.product-name')}
                  rules={[
                    {
                      required: true,
                      message: `${t('product.lifecycle-steps.configuration.product-name')} ${t(
                        'share.success.form.required',
                      )}`,
                    },
                  ]}
                >
                  <InputField
                    placeholder={t('product.lifecycle-steps.configuration.product-name')}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  name="productType"
                  label={`${t('product.lifecycle-steps.configuration.product-type.label')} ${t(
                    'product.lifecycle-steps.configuration.product-type.recommended',
                  )}`}
                  extra={
                    <Col span={24} className={classes.inputDescription}>
                      <Row>
                        <Text color="secondary" size="lg" fontWeight="medium">
                          {t('product.lifecycle-steps.configuration.product-type.description')}
                        </Text>
                      </Row>
                      <Row>
                        <Button
                          prefixIcon={<HiOutlineBookOpen size={20} />}
                          href={t(
                            `product.lifecycle-steps.configuration.product-type.learn-more-link`,
                          )}
                          type="link"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.learnLink}
                        >
                          {t(`product.lifecycle-steps.configuration.product-type.learn-more-title`)}
                        </Button>
                      </Row>
                    </Col>
                  }
                >
                  <ProductTypeSelect
                    productTypes={productTypes}
                    defaultValue={product?.productTypeId ?? form.getFieldValue('productType')}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  name="tags"
                  label={t('product.lifecycle-steps.configuration.tags.label')}
                >
                  <TagInput
                    options={tagOptions.map(({ name }) => name)}
                    onCreateTag={async (name: string) => {
                      const newTag = await createProductTag(name)
                      newTag && setTagOptions([...tagOptions, newTag])
                    }}
                    onDeleteTag={async (name: string) => {
                      const id = tagOptions.find((x) => x.name === name)?.id
                      id && (await deleteProductTag(id))
                      setTagOptions([...tagOptions.filter((x) => x.id !== id)])
                    }}
                    reservedOptions={tagOptions
                      .filter(({ organizationId }) => !organizationId)
                      .map(({ name }) => name)}
                  />
                </Form.Item>
                <Text
                  className={classes.inputDescription}
                  color="secondary"
                  size="lg"
                  fontWeight="medium"
                >
                  {t('product.lifecycle-steps.configuration.tags.description')}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <Row>
                  <Col span={11} className={classes.unitFields}>
                    <Form.Item
                      tooltip={{
                        title: t('product.lifecycle-steps.configuration.tooltips.unit'),
                        icon: <HiOutlineInformationCircle color={GRAY_400} />,
                      }}
                      name="mass"
                      label={t('product.lifecycle-steps.configuration.product-mass')}
                      rules={[
                        {
                          required: true,
                          message: `${t(
                            'product.lifecycle-steps.configuration.product-mass-error',
                          )} ${t('share.success.form.required')}`,
                        },
                      ]}
                      extra={
                        <Button
                          prefixIcon={<HiOutlineBookOpen size={20} />}
                          href={t(
                            `product.lifecycle-steps.configuration.product-mass-learn-more-link`,
                          )}
                          type="link"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.learnLink}
                        >
                          {t(`product.lifecycle-steps.configuration.product-mass-learn-more-title`)}
                        </Button>
                      }
                    >
                      <InputField
                        type="number"
                        min={0}
                        placeholder={t(
                          'product.lifecycle-steps.configuration.product-mass-placeholder',
                        )}
                        disabled={!isUnitEditable}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="massUnit"
                      label=" "
                      requiredMark="optional"
                      rules={[
                        {
                          required: true,
                          message: `${t('product.lifecycle-steps.configuration.unit')} ${t(
                            'share.success.form.required',
                          )}`,
                        },
                      ]}
                    >
                      <Select
                        placeholder={t('product.lifecycle-steps.configuration.unit-placeholder')}
                        disabled={!isUnitEditable}
                      >
                        {units.map((unit) => (
                          <Option value={unit.id} key={unit.id}>
                            {unit.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  tooltip={{
                    title: t('product.lifecycle-steps.configuration.tooltips.productCode'),
                    icon: <HiOutlineInformationCircle color={GRAY_400} />,
                  }}
                  name="productId"
                  label={t('product.lifecycle-steps.configuration.product-code')}
                >
                  <InputField
                    placeholder={t('product.lifecycle-steps.configuration.product-code')}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label={t('product.responsible-user')}
                  name={'responsibleId'}
                  tooltip={{
                    title: t('product.lifecycle-steps.configuration.responsible-user.description'),
                    icon: <HiOutlineInformationCircle color={GRAY_400} />,
                  }}
                >
                  <Select
                    dropdownMatchSelectWidth={false}
                    showSearch
                    size="middle"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label as string)?.toLowerCase().includes(input.toLowerCase())
                    }
                    options={users?.map((user) => ({
                      key: user.id,
                      value: user.id,
                      label: user.email,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <Form.Item
                  tooltip={{
                    title: t('product.lifecycle-steps.configuration.tooltips.territory'),
                    icon: <HiOutlineInformationCircle color={GRAY_400} />,
                  }}
                  label={t('territories.all')}
                  name="territory"
                >
                  <Cascader
                    style={{ width: '100%' }}
                    showSearch
                    dropdownMatchSelectWidth={false}
                    size="large"
                    dropdownClassName={classes.scopesSelect}
                    options={possibleTerritories}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Row>
      </Spin>
    </>
  )
}

export default UpsertProduct
