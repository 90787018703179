import _ from 'lodash'

import { AffectedLogEntry, GroupedMethodologyChangelog } from '@cozero/models'

interface ChangeOfEmissions {
  oldEmissions: number
  newEmissions: number
  percentageChange: number
  years: number[]
}

export function getChangeOfEmissions(
  changelog: GroupedMethodologyChangelog,
  emissionsType: 'organization' | 'product',
): ChangeOfEmissions {
  const logEntries =
    emissionsType === 'organization' ? 'affectedLogEntries' : 'affectedProductLogEntries'
  const oldEmissions = _.sumBy(changelog[logEntries] as AffectedLogEntry[], 'oldValue')
  const newEmissions = _.sumBy(changelog[logEntries] as AffectedLogEntry[], 'newValue')
  const years = _.uniq(_.flatMap(changelog?.affectedYears))
  const percentageChange = ((newEmissions - oldEmissions) / oldEmissions) * 100 ?? 0

  return {
    oldEmissions,
    newEmissions,
    percentageChange,
    years,
  }
}
