import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useParams } from 'react-router'

import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { skipToken } from '@reduxjs/toolkit/query/react'

import { PageFilter, ProductLogEntry, Report } from '@cozero/models'

import { LifecycleStepsID } from '@/pages/Organization/NewProduct/steps'

import FactorsModal from '@/organisms/FactorsModal'
import OverviewRow from '@/organisms/OverviewRow'
import ReportContainer from '@/organisms/ReportContainer/ReportContainer'

import { OverviewCardProps } from '@/molecules/OverviewCard'
import Table from '@/molecules/Table'

import HighlightValue from '@/atoms/HighlightValue'
import * as Skeleton from '@/atoms/Skeleton'

import useBoards from '@/hooks/useBoards'
import useProductLogEntriesTableColumns from '@/hooks/useProductLogEntriesTableColumns'
import useProducts from '@/hooks/useProducts'
import { useGetProductQuery } from '@/redux/products'
import { convertTonnesToGrams, formatNumber } from '@/utils/number'

import classes from './ProductLifecycleStepDetails.module.less'

const ProductLifecycleStepDetails = ({ stepId }: { stepId: LifecycleStepsID }): ReactElement => {
  const { t } = useTranslation('common')
  const { productId } = useParams()
  const { data: product, isFetching } = useGetProductQuery(productId ?? skipToken)
  const { getReportWithData } = useBoards()
  const { getProductLogEntry } = useProducts()
  const [productLogEntries, setProductLogEntries] = useState<ProductLogEntry[]>([])
  const [selectedLogEntry, setSelectedLogEntry] = useState<ProductLogEntry>()
  const [isCustomFactorModalOpen, setIsCustomFactorModalOpen] = useState(false)
  const [graphData, setGraphData] = useState<Report | undefined>()
  const lifecycleGraphFilters: PageFilter[] = [
    {
      value: [productId],
      selectedCondition: { key: 'in', label: 'in' },
      type: 'relationship',
      label: '',
      conditions: [],
      key: 'product',
    },
    {
      value: [stepId],
      selectedCondition: { key: 'in', label: 'in' },
      type: 'relationship',
      label: '',
      conditions: [],
      key: 'lifecycle',
    },
  ]

  useEffect(() => {
    const fetchLogEntries = async (): Promise<void> => {
      const logEntries = (await getProductLogEntry((productId as string).toString(), stepId)) || []
      setProductLogEntries(logEntries)
    }
    productId && fetchLogEntries()
  }, [productId, stepId])

  const columnsData = useProductLogEntriesTableColumns({
    onShowFactors: (entry) => {
      setSelectedLogEntry(entry as ProductLogEntry)
      setIsCustomFactorModalOpen(true)
    },
  })

  const overviewData: OverviewCardProps[] = useMemo(() => {
    const totalEmissionsPerUnit =
      productLogEntries.reduce(
        (acc, val) => acc + val.productEmissions.reduce((acc2, val2) => acc2 + val2.value, 0) ?? 0,
        0,
      ) ?? 0
    const emissions = product?.productEmissions.reduce((acc, x) => acc + x.value, 0) ?? 0
    const percentageValue = (totalEmissionsPerUnit * 100) / emissions
    return [
      {
        content: (
          <Skeleton.Paragraph size="fill" loading={isFetching}>
            <HighlightValue
              value={formatNumber(convertTonnesToGrams(totalEmissionsPerUnit))}
              unit="gCO₂e"
            />
          </Skeleton.Paragraph>
        ),
        headerTitle: t('product.lifecycle-steps.step-footprint-per-unit'),
      },
      {
        content: (
          <Skeleton.Paragraph size="fill" loading={isFetching}>
            <HighlightValue
              value={formatNumber(isNaN(percentageValue) ? 0 : percentageValue) + ' %'}
            />
          </Skeleton.Paragraph>
        ),
        headerTitle: t('product.lifecycle-steps.impact-total-product-unit-footprint'),
      },
    ]
  }, [product, productLogEntries])

  useEffect(() => {
    if (!graphData && productLogEntries.length > 1) {
      fetchGraphData()
    }
  }, [stepId, productLogEntries])

  const fetchGraphData = async (): Promise<void> => {
    if (productId) {
      const reportKey = 'lifecycle-emissions-per-product-unit'
      const report = await getReportWithData(reportKey, lifecycleGraphFilters)
      setGraphData(report)
    }
  }

  return (
    <>
      <OverviewRow
        loadingOverview={false}
        marginBetween={14}
        overviewData={overviewData}
        hideTitle={true}
      />
      {graphData && (
        <div className={classes.graphWrapper}>
          <ReportContainer
            persistedBoardSettings={{ filters: lifecycleGraphFilters }}
            disableDelete={true}
            draggingGraphId={null}
            report={graphData}
            editable={false}
            loading={!graphData}
          />
        </div>
      )}
      <Row className={classes.title}>{t('log.logs')}</Row>
      <Row align="middle" className={classes.wrapper}>
        <Col>
          <Table
            scroll={{ x: 1200 }}
            columns={columnsData as ColumnsType<ProductLogEntry>}
            dataSource={productLogEntries}
            pagination={productLogEntries?.length > 10 ? { pageSize: 10 } : false}
          ></Table>
        </Col>
      </Row>
      {product && (
        <FactorsModal
          isVisible={!!isCustomFactorModalOpen}
          onClose={() => setIsCustomFactorModalOpen(false)}
          logEntry={selectedLogEntry}
          type={'product'}
          isPeriodClosed={false}
        />
      )}
    </>
  )
}

export default ProductLifecycleStepDetails
