import React, { Component, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'

import { ErrorBoundaryProps, ErrorBoundaryState } from './types'
import { withRouter } from './withRouter'

const initialState: ErrorBoundaryState = {
  error: null,
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state = initialState

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error }
  }

  public componentDidUpdate(prevProps: ErrorBoundaryProps, prevState: ErrorBoundaryState): void {
    const { error } = this.state

    // If the user navigates, reset the error state
    if (
      error !== null &&
      prevState.error !== null &&
      this.props.location &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.setState({ error: null })
    }
  }

  public render(): ReactNode {
    if (this.state.error !== null) {
      const { FallbackComponent, renderFallbackUi } = this.props

      if (typeof renderFallbackUi === 'function') {
        return renderFallbackUi({ error: this.state.error })
      } else if (FallbackComponent) {
        return <FallbackComponent error={this.state.error} />
      } else {
        throw new Error('No fallback component or renderFallbackUi function provided')
      }
    }

    return this.props.children
  }
}

export default withTranslation()(withRouter(ErrorBoundary))
