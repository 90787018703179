import React from 'react'
import { Navigate, RouteProps } from 'react-router-dom'

import { useAppSelector } from '@/redux'
import { getFeaturesAllowed, getIsManagerOrAdmin, getUserRole } from '@/redux/auth'
import { config } from '@/utils/config'

type FeatureRouteProps = RouteProps & {
  requiredFeature?: string
  admin?: boolean
  excludeRoles?: string[]
  children?: React.ReactNode
}
const { routes } = config

const FeatureRoute = ({
  children,
  requiredFeature,
  admin,
  excludeRoles,
}: FeatureRouteProps): JSX.Element => {
  const userRole = useAppSelector(getUserRole)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)

  if (admin && !isManagerOrAdmin) {
    return <Navigate to={routes.home} />
  }
  if (requiredFeature && !featuresAllowed.includes(requiredFeature)) {
    return <Navigate to={routes.home} />
  }
  if (userRole && excludeRoles?.includes(userRole)) {
    return <Navigate to={routes.home} />
  }

  return <>{children}</>
}

export default FeatureRoute
