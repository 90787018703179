import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiInformationCircle, HiOutlineFilter, HiPlus } from 'react-icons/hi'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Col, Divider, Row, Typography, message } from 'antd/es'

import debounce from 'lodash/debounce'
import omit from 'lodash/omit'

import { CreateLogDto } from '@cozero/dtos'
import { LogSorter, LogType, PageFilter } from '@cozero/models'
import { routes } from '@cozero/utils'

import CarbonFootprintWrapper from '@/organisms/CarbonFootprintWrapper'
import CompleteProductModal from '@/organisms/CompleteProductModal'
import LogCategoryModal from '@/organisms/LogCategoryModal'
import LogViewsMenu from '@/organisms/LogViewsMenu'
import { EmptyStateCard } from '@/organisms/Onboarding/EmptyStateCard'
import ProductLogsTable from '@/organisms/ProductDataTable'
import ResourceCenterCard from '@/organisms/ResourceCenterCard'

import FiltersDrawer, { PageType } from '@/molecules/FiltersDrawer'
import { OverviewCardProps } from '@/molecules/OverviewCard'

import Button from '@/atoms/Button'
import HighlightValue from '@/atoms/HighlightValue'
import Pill from '@/atoms/Pill'

import { useAppContext } from '@/contexts/app'
import { useFiltersContext } from '@/contexts/filters'
import { useLogContext } from '@/contexts/log'
import { useSubscriptionContext } from '@/contexts/subscription'
import useOverviewStats from '@/hooks/useOverviewStats'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed, getIsUserReadOnly, selectUserOrganization } from '@/redux/auth'
import { isAxiosError } from '@/redux/axiosBaseQuery'
import { selectSelectedBusinessUnit, useGetActiveBusinessUnitsQuery } from '@/redux/businessUnits'
import { useGetCategoriesQuery } from '@/redux/categories'
import { selectProductsFilters } from '@/redux/filters/selectors'
import { useGetActiveLocationsQuery } from '@/redux/locations'
import { useCreateLogMutation } from '@/redux/logs'
import { useGetOrganizationUsersFilteredByBusinessUnitQuery } from '@/redux/organizations'
import { useGetProductCountQuery } from '@/redux/products'
import { COZERO_BLUE_90 } from '@/styles/variables'
import { createFilterOptions, createQueryFilter } from '@/utils/filters'
import { formatNumber } from '@/utils/number'

import classes from './Log.module.less'

enum FilterKey {
  CATEGORY = 'categoryId',
  LOCATION = 'locationId',
  BUSINESS_UNIT = 'businessUnit',
  RESPONSIBLE = 'ownerId',
}
const DEFAULT_PAGE_SIZE = 15

const ProductLogs = (): ReactElement => {
  const state = useLocation().state as unknown as {
    prevPath: string
    showCompletedModal: boolean
    from: string
  } | null
  const { data: productsCount } = useGetProductCountQuery()
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const { suppliers, getSuppliers, getProducts, products } = useAppContext()
  const organization = useAppSelector(selectUserOrganization)
  const [searchParams] = useSearchParams()
  const [drawerOpened, setDrawerOpened] = useState(false)
  const [showCompleteProductModal, setShowCompleteProductModal] = useState(false)
  const selectedFilters = useSelector(selectProductsFilters)
  const { data: businessUnits = [] } = useGetActiveBusinessUnitsQuery(
    {
      businessUnitKey: selectedBusinessUnit?.key ?? '',
    },
    { skip: !selectedBusinessUnit },
  )
  const { data: locations } = useGetActiveLocationsQuery(
    {
      businessUnitKey: selectedBusinessUnit?.key ?? '',
    },
    { skip: !selectedBusinessUnit },
  )
  const [createLog] = useCreateLogMutation()
  const {
    filters,
    sorters,
    pageNumber: currentPage,
    saveFilters,
    saveSorters,
    savePageNumber: setCurrentPage,
  } = useFiltersContext()
  const [productFilters, setProductFilters] = useState(
    filters?.filter((filter) => filter.logType === 'product' || !filter.logType),
  )
  const [productSorters, setProductSorters] = useState(
    sorters?.filter((sorter) => sorter.logType === 'product' || !sorter.logType),
  )
  const { data: overviewStats, loading: overviewLoading } = useOverviewStats({
    type: 'product',
    businessUnitId: selectedBusinessUnit?.id,
    filters:
      filters.filter((x) => x.type !== 'dateRange')?.map((obj) => omit(obj, 'options')) || [],
  })

  const userIsReadOnly = useAppSelector(getIsUserReadOnly)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const { setSubscribeModalSettings, getLimit } = useSubscriptionContext()
  const { data: users } = useGetOrganizationUsersFilteredByBusinessUnitQuery(
    {
      businessUnit: selectedBusinessUnit?.key ?? '',
    },
    {
      skip: !selectedBusinessUnit?.key,
    },
  )
  const { t } = useTranslation('common')
  const { data: categories } = useGetCategoriesQuery()
  const {
    loading: logLoading,
    getCustomers,
    customers,
    getTags,
    getCurrencies,
    reset,
    tags,
  } = useLogContext()

  const [modalOpen, setModalOpen] = useState<'config' | 'quantities' | null>()
  const [newLog, setNewLog] = useState<{ type?: LogType; categoryId?: number }>({
    type: undefined,
    categoryId: undefined,
  })
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(logLoading)
  const { productId } = useParams()
  const queryProduct = searchParams.get('product') || productId
  const [filterOptions, setFilterOptions] = useState<PageFilter[]>(() =>
    createFilterOptions({
      type: 'product',
      categories,
      users,
      businessUnits,
      locations,
      products,
      tags,
      customers,
      t,
      featuresAllowed,
      shownOptions: [
        'businessUnits',
        'locations',
        'owners',
        'categories',
        'products',
        'suppliers',
        'customers',
        'tags',
      ],
      page: 'logs',
    }),
  )

  const childRef = useRef({
    onRefreshTable: () => undefined,
  })

  const goToCreatePage = async (): Promise<void> => {
    const limit = getLimit(organization, 'products')
    if (limit && productsCount && productsCount >= limit.max) {
      setSubscribeModalSettings({
        closable: true,
        title: t('subscription.upgrade-modal.title-limit', {
          limit: limit.max,
          item: t('products.title'),
        }),
        visible: true,
      })
      return
    }
    navigate(routes.log.carbonFootprint.productsUpsert.stepsCreate)
  }

  const onChooseCategory = async (id: number): Promise<void> => {
    setNewLog({ ...newLog, categoryId: id, type: 'product' })
  }

  const overviewData: OverviewCardProps[] = [
    {
      content: (
        <div>
          <HighlightValue
            value={formatNumber(overviewStats?.emissionsDataValue ?? 0).toString() ?? ''}
            unit={t('log.co2-unit')}
          />
          <Row>
            <Pill color="blue">
              <span className={classes.logsPercentage}>{overviewStats?.percentageOfLogs}%</span>
              <span className={classes.totalLogsPill}>
                {' '}
                {t('log.overview-stats.of')} {overviewStats?.totalLogs}{' '}
                {t('products.title').toLocaleLowerCase()}
              </span>
            </Pill>
          </Row>
        </div>
      ),
      headerTitle: t('log.overview-stats.product-footprint'),
      tooltip: {
        triggerElement: <HiInformationCircle color={COZERO_BLUE_90} />,
        subtitle: t('log.overview-stats.product-footprint-info'),
      },
    },
    {
      content: (
        <div>
          <Row>
            <HighlightValue
              value={
                overviewStats?.numberOfQuantities
                  ? formatNumber(overviewStats?.numberOfQuantities)
                  : ''
              }
            />
          </Row>
          <Row>----</Row>
        </div>
      ),
      headerTitle: t('log.overview-stats.number-of-quantities'),
      tooltip: {
        triggerElement: <HiInformationCircle color={COZERO_BLUE_90} />,
        subtitle: t('log.overview-stats.number-of-quantities-info'),
      },
    },
  ]

  useEffect(() => {
    if (newLog.type && newLog.categoryId) {
      submitLog(newLog)
    }
  }, [newLog])

  const submitLog = async (newLog: Partial<CreateLogDto>): Promise<void> => {
    try {
      const createdLog = await createLog(newLog).unwrap()
      if (createdLog) {
        const category = categories?.find((obj) => obj.id === newLog.categoryId)
        message.success(t('log.create-success', { category: category?.name }))
        return navigate(
          `${routes.log.carbonFootprint.products.edit.replace(':id', createdLog.id.toString())}`,
        )
      }
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.status === 402) {
          const limit = getLimit(organization, 'log')
          setSubscribeModalSettings({
            closable: true,
            title: t('subscription.upgrade-modal.title-logs-limit', {
              limit: limit?.max,
              item: t('product.logs.title'),
            }),
            visible: true,
          })
        } else {
          message.error(t('location.errors.log-creation'))
        }
      } else {
        message.error(t('location.errors.log-creation'))
      }
    }
  }

  const onCloseModal = (): void => {
    setModalOpen(null)
  }

  const onOpenModal = (type: 'config' | 'quantities'): void => {
    setModalOpen(type)
  }

  const createConfigLog = async (): Promise<void> => {
    onOpenModal('config')
  }

  const changePage = useCallback((page: number): void => setCurrentPage(page), [])

  const changePageSize = useCallback((pageSize: number): void => setPageSize(pageSize), [])

  async function onSearch(filters: PageFilter[]): Promise<void> {
    saveFilters(filters)
  }

  async function changeSorting(sorters: LogSorter[]): Promise<void> {
    saveSorters(sorters)
  }

  async function fetchData(): Promise<void> {
    setLoading(true)
    await getProducts()
    await getCustomers()
    await getSuppliers()
    await getTags()
    await getCurrencies()
    setLoading(false)
  }

  const openFilters = (): void => {
    const deprecatedView = productFilters.some(
      (x) => x.selectedCondition?.key && x.selectedCondition?.key !== 'in',
    )
    if (!deprecatedView) {
      setDrawerOpened(true)
    } else {
      message.error(t('views.update.failure'))
    }
  }

  useEffect(() => {
    fetchData()
  }, [selectedBusinessUnit?.key])

  useEffect(() => {
    setFilterOptions(
      createFilterOptions({
        type: 'product',
        categories,
        users,
        businessUnits,
        locations,
        customers,
        suppliers,
        products,
        t,
        featuresAllowed,
        page: 'logs',
      }),
    )
  }, [
    categories,
    users,
    businessUnits,
    locations,
    products,
    selectedBusinessUnit?.key,
    customers,
    suppliers,
  ])

  useEffect(() => {
    if (queryProduct && products?.length) {
      const foundProduct = products?.find((obj) => obj.id.toString() === queryProduct)
      if (foundProduct) {
        const queryFilter = createQueryFilter({
          value: [foundProduct.id.toString()],
          type: 'product',
          t,
          options: products,
        })
        saveFilters(queryFilter)
      }
    }
  }, [currentPage, queryProduct, products?.length, selectedBusinessUnit?.key])

  useEffect(() => {
    if (selectedFilters && selectedFilters.length) {
      setProductFilters(selectedFilters?.filter((filter) => filter.logType === 'product'))
    } else {
      setProductFilters(filters?.filter((filter) => filter.logType === 'product'))
    }
  }, [filters, selectedFilters])

  useEffect(() => {
    setProductSorters(sorters)
  }, [sorters])

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    if (
      state?.prevPath.includes(routes.log.carbonFootprint.productsUpsert.base) &&
      state?.showCompletedModal
    ) {
      setShowCompleteProductModal(true)
    }
  }, [state])

  return (
    <>
      <CarbonFootprintWrapper
        overviewData={overviewData}
        overviewLoading={overviewLoading}
        showOverview={false}
      >
        <FiltersDrawer
          search={debounce(onSearch, 500)}
          pageType={PageType.PRODUCT}
          filters={[...productFilters] as (PageFilter & { key: FilterKey })[]}
          visible={drawerOpened}
          filterOptions={
            [...filterOptions] as (PageFilter & {
              options: {
                key?: string
                value: string
                label: string
              }[]
            })[]
          }
          onClose={() => setDrawerOpened(false)}
          featuresAllowed={featuresAllowed}
        />
        <Row justify="start" className={classes.section}>
          <Col span={24}>
            <Row gutter={16} justify="end">
              {!userIsReadOnly && (
                <Col>
                  <Button
                    category={'products'}
                    action={'add'}
                    type="primary"
                    onClick={goToCreatePage}
                    prefixIcon={<HiPlus />}
                    fillParent
                  >
                    {t('product.create-product-button')}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <div className={classes.container}>
          {products ? (
            <div>
              <div className={classes.logsContainer}>
                <Row justify="end">
                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <LogViewsMenu logType="location" />
                      </Col>
                      <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                          onClick={() => openFilters()}
                          category={'logs'}
                          action={'open-filter'}
                          className={`${classes.buttonRow} ${
                            productFilters?.filter((filter) => filter.type !== 'date')?.length &&
                            classes.activeButton
                          }`}
                        >
                          <HiOutlineFilter />
                          <Typography
                            className={`${classes.buttonTitle} ${
                              productFilters?.length && classes.activeTitle
                            }`}
                          >
                            {t('log.filter.add')}
                          </Typography>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider className={classes.tableDivider} />
                <ProductLogsTable
                  childRef={childRef}
                  featuresAllowed={featuresAllowed as string[]}
                  currentPage={currentPage || 1}
                  setCurrentPage={changePage}
                  setPageSize={changePageSize}
                  pageSize={pageSize}
                  defaultPageSize={DEFAULT_PAGE_SIZE}
                  filters={productFilters}
                  sorters={productSorters}
                  selectedBusinessUnit={selectedBusinessUnit ?? undefined}
                  loading={loading}
                  setSort={debounce(changeSorting, 800)}
                />
              </div>
            </div>
          ) : (
            <Row className={classes.logEmptyState}>
              <Col span={24}>
                <EmptyStateCard
                  title={t('log.onboarding.product-title')}
                  description={t('log.onboarding.description')}
                  buttonText={t('log.onboarding.create-log')}
                  type={t('log.onboarding.log')}
                  onClick={createConfigLog}
                />
              </Col>
              <ResourceCenterCard title={t('onboarding.demo.resource-card-title')} />
            </Row>
          )}
        </div>
      </CarbonFootprintWrapper>
      <LogCategoryModal
        open={modalOpen === 'config'}
        onChooseCategory={onChooseCategory}
        onClose={onCloseModal}
      />
      <CompleteProductModal
        showModal={showCompleteProductModal}
        text={t('product.lifecycle-steps.complete.description')}
        buttonText={t('product.lifecycle-steps.complete.btn-text')}
        onClick={() => setShowCompleteProductModal(false)}
        onCancel={() => setShowCompleteProductModal(false)}
      />
    </>
  )
}

export default ProductLogs
