import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlinePlus } from 'react-icons/hi'
import { useNavigate } from 'react-router'

import { Spin, Tag } from 'antd/es'

import { PushpinOutlined, UserOutlined } from '@ant-design/icons'
import { isEmpty } from 'lodash-es'
import { RenderExpandIconProps } from 'rc-table/lib/interface'

import { BusinessUnit } from '@cozero/models'
import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'

import Button from '@/atoms/Button'

import { useLazyGetBusinessUnitQuery } from '@/redux/businessUnits'
import { config } from '@/utils/config'
import { updateTreeData } from '@/utils/tree'

import classes from './classes.module.less'

interface Props {
  loadedBusinessUnits: BusinessUnit[]
  isManagerOrAdmin: boolean
  loading?: boolean
}

const BusinessUnitsTable = ({
  loadedBusinessUnits,
  isManagerOrAdmin,
  loading = false,
}: Props): JSX.Element => {
  const [getBusinessUnit] = useLazyGetBusinessUnitQuery()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>(loadedBusinessUnits)

  const columns = [
    {
      title: t('business-unit.key'),
      dataIndex: 'title',
      key: 'title',
    },

    {
      title: t('business-unit.tag'),
      dataIndex: ['metadata', 'tags'],
      key: 'tags',
      render(_text: string, record: BusinessUnit) {
        return record?.metadata?.tags?.map((tag) => <Tag key={tag}>{tag}</Tag>)
      },
    },
    {
      title: t('settings.bu.locations'),
      dataIndex: 'locations',
      key: 'locations',
      render(_text: string, record: BusinessUnit) {
        return (
          <span>
            <PushpinOutlined className={classes.icon} />
            {record.locations?.length || 0}
          </span>
        )
      },
    },
    {
      title: t('settings.bu.users'),
      dataIndex: 'users',
      key: 'users',
      render(_text: string, record: BusinessUnit) {
        return (
          <span>
            <UserOutlined className={classes.icon} />
            {record.users?.length || 0}
          </span>
        )
      },
    },
    {
      title: t('settings.bu.active.title'),
      dataIndex: 'active',
      key: 'active',
      render(_text: string, record: BusinessUnit) {
        return record.active ? <Tag color="green">{t('yes')}</Tag> : <Tag>{t('no')}</Tag>
      },
    },
    {
      title: t('actions.title'),
      key: 'action',
      render(_text: string, record: BusinessUnit) {
        return (
          <span className={classes.actions}>
            {isManagerOrAdmin && (
              <Button
                category="business-unit"
                action="Edit Business Unit"
                type="secondary"
                onClick={() =>
                  navigate(
                    routes.organization.editBusinessUnit.replace(':id', record.id.toString()),
                  )
                }
                data-cy="edit-business-unit-btn"
              >
                {t('actions.edit.title')}
              </Button>
            )}
          </span>
        )
      },
    },
  ]

  async function getChildrenRows(record: BusinessUnit): Promise<void> {
    const parentBusinessUnit = await getBusinessUnit({ id: `${record.id}` }).unwrap()
    if (parentBusinessUnit) {
      const newTree = updateTreeData(
        businessUnits,
        record.id,
        (parentBusinessUnit.children as BusinessUnit[]) || [],
      )
      setBusinessUnits(newTree)
    }
  }

  const ExpandIcon = ({
    expanded,
    onExpand,
    record,
    expandable,
  }: RenderExpandIconProps<BusinessUnit>): ReactNode => {
    if (expandable && !isEmpty(record.children)) {
      return (
        <button
          onClick={(e) => onExpand(record, e)}
          className={`${classes.expandButton} ${expanded ? classes.active : ''} `}
        >
          <HiOutlinePlus size="15" />
        </button>
      )
    }
    return null
  }

  useEffect(() => {
    setBusinessUnits(loadedBusinessUnits)
  }, [loadedBusinessUnits])

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={businessUnits}
        rowKey="id"
        expandable={{
          expandIcon: ExpandIcon,
          onExpand: (_expanded: boolean, record: BusinessUnit) => getChildrenRows(record),
        }}
        rowClassName={classes.tableRow}
      />
    </Spin>
  )
}

export default BusinessUnitsTable
