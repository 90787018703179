import React, { Key, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Badge, Col, Row, Space, Table, Timeline } from 'antd/es'
import { ColumnsType } from 'antd/es/table'

import { ClockCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

import { ClosedPeriod, EmissionFactorChangelog, OutdatedEmissionFactor } from '@cozero/models'

import Modal from '@/molecules/Modal'

import Button from '@/atoms/Button'

import { useFactorContext } from '@/contexts/factor'
import { formatNumber } from '@/utils/number'

import classes from './OutdatedEmissionFactorsModal.module.less'

interface Props {
  open: boolean
  closedPeriod: ClosedPeriod
  visibilityHandler: (open: boolean) => void
  onClose?: () => void
}

const OutdatedEmissionFactorsModal = ({
  open,
  closedPeriod,
  onClose,
  visibilityHandler,
}: Props): ReactElement => {
  const { t } = useTranslation('common')
  const [loading, setLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState<
    Partial<EmissionFactorChangelog>[] | OutdatedEmissionFactor[]
  >([])
  const [currentPageChangelogs, setCurrentPageChangelogs] = useState(1)
  const [totalRecordsChangelog, setTotalRecordsChangelog] = useState(5)
  const [changelogTables, setChangelogTables] = useState<EmissionFactorChangelog[][]>([])
  const [closedPeriodDisplay, setClosedPeriodDisplay] = useState<ClosedPeriod>()

  const { applyFactorChanges, getFactorChangelogs } = useFactorContext()
  const submitHandler = async (): Promise<void> => {
    try {
      setLoading(true)
      await applyFactorChanges(selectedRows as Partial<EmissionFactorChangelog>[])

      setLoading(false)
      closeHandler()
    } catch (error) {
      setLoading(false)
    }
  }

  const closeHandler = (): void => {
    if (onClose) {
      onClose()
    }
    return visibilityHandler(false)
  }

  const columns = [
    {
      title: t('custom-reports.emission-factors-modal.table.column-emission-factor'),
      render(_text: string, record: OutdatedEmissionFactor | EmissionFactorChangelog) {
        return (
          <span>
            {(
              record as EmissionFactorChangelog
            )?.emissionFactor?.activityDataSource?.name?.toString() ??
              record?.emissionFactor?.toString()}
          </span>
        )
      },
      key: 'emissionFactor',
      sorter: true,
    },
    {
      title: t('custom-reports.emission-factors-modal.table.column-category'),
      render(_text: string, record: OutdatedEmissionFactor | EmissionFactorChangelog) {
        return (
          <span>
            {(record as EmissionFactorChangelog)?.category?.name?.toString() ??
              record?.category?.toString()}
          </span>
        )
      },
      key: 'category',
      sorter: true,
    },
    {
      title: t('custom-reports.emission-factors-modal.table.column-old-value'),
      dataIndex: 'oldValue',
      key: 'oldValue',
      sorter: true,
    },
    {
      title: t('custom-reports.emission-factors-modal.table.column-new-value'),
      dataIndex: 'newValue',
      key: 'newValue',
      sorter: true,
    },
    {
      title: t('custom-reports.emission-factors-modal.table.column-carbon-footprint'),
      key: 'carbon-footprint',
      width: 300,
      render(_text: string, record: OutdatedEmissionFactor) {
        return (
          <Space size={'small'}>
            <span className={classes.carbonFootprintValue}>
              {formatNumber(record.carbonFootprint)}
            </span>
            <span className={`${classes.carbonFootprintValue} ${classes.unit}`}>tCO2</span>
            <Badge
              className={`${classes.variationBadge} ${
                record.carbonFootprintVariation > 0 ? classes.positive : classes.negative
              }`}
              count={`${record.carbonFootprintVariation >= 0 ? '+' : ''}${formatNumber(
                record.carbonFootprintVariation,
              )}%`}
            />
          </Space>
        )
      },
    },
  ]

  const rowSelection = {
    onChange: (
      selectedRowKeys: Key[],
      _selectedRows: OutdatedEmissionFactor[] | EmissionFactorChangelog[],
    ) => {
      setSelectedRows(_selectedRows)
    },
  }

  const fetchChangelogs = async (page: number): Promise<void> => {
    const result = await getFactorChangelogs(page, 1)
    changelogTables[page - 1] = result.docs
    setTotalRecordsChangelog(result.totalRecords)
    setChangelogTables([...changelogTables])
  }

  useEffect(() => {
    if (closedPeriod) {
      const aux = { ...closedPeriod }
      aux.outdatedEmissionFactors = aux.outdatedEmissionFactors?.filter(
        (v, i, a) => a.findIndex((v2) => v2.oldEmissionId === v.oldEmissionId) === i,
      )
      setClosedPeriodDisplay(aux)
    }
  }, [closedPeriod])

  useEffect(() => {
    fetchChangelogs(currentPageChangelogs)
  }, [currentPageChangelogs])

  return (
    <Modal
      title={t('custom-reports.emission-factors-modal.title')}
      visible={open}
      onOk={submitHandler}
      okButtonProps={{ loading: loading, disabled: !selectedRows.length || loading }}
      onCancel={closeHandler}
      confirmLoading={loading}
      okText={t('custom-reports.emission-factors-modal.action')}
    >
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: 'flex',
        }}
      >
        <Row justify="space-between">
          <Col className={classes.secondTitle}>
            {t('custom-reports.emission-factors-modal.second-title')} {closedPeriod.name}
          </Col>
          <Col className={classes.dateRanges}>
            {moment(closedPeriod.startDate).format('MMM DD, YYYY')} to{' '}
            {moment(closedPeriod.endDate).format('MMM DD, YYYY')}
          </Col>
        </Row>
        <Row>
          <Col span={24} className={classes.timelineWrapper}>
            <Timeline>
              <Timeline.Item>
                <span className={classes.timelineTitle}>
                  {t('custom-reports.emission-factors-modal.current-title')}
                </span>
                <Col className={classes.alertWrapper} span={24}>
                  <Alert
                    message={t('custom-reports.emission-factors-modal.alert-text')}
                    type="info"
                    showIcon
                  />
                </Col>

                <Table
                  className={`${classes.table}`}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                  dataSource={closedPeriodDisplay?.outdatedEmissionFactors}
                  columns={columns}
                  rowKey="oldEmissionId"
                />
              </Timeline.Item>
              <Timeline.Item></Timeline.Item>
            </Timeline>
          </Col>
          {totalRecordsChangelog > 0 &&
            changelogTables.map((previousChanges, index) =>
              index + 1 <= totalRecordsChangelog ? (
                <Col key={index} span={24} className={classes.timelineWrapper}>
                  <Timeline
                    className={`${index === changelogTables.length - 1 ? classes.fade : ''}`}
                  >
                    <Timeline.Item>
                      <span className={`${classes.timelineTitle}`}>
                        {t('custom-reports.emission-factors-modal.previous-title')}{' '}
                        {moment(previousChanges[0].createdAt).format('MMMM DD, YYYY')} by{' '}
                        {previousChanges[0].createdBy.firstName}{' '}
                        {previousChanges[0].createdBy.lastName}
                      </span>
                      {index !== changelogTables.length - 1 ? (
                        <Table
                          className={`${classes.table}`}
                          dataSource={previousChanges}
                          columns={columns as ColumnsType<EmissionFactorChangelog>}
                          rowKey="logEntryId"
                        />
                      ) : null}
                    </Timeline.Item>
                    <Timeline.Item></Timeline.Item>
                  </Timeline>
                </Col>
              ) : null,
            )}
          <Col span={24} className={classes.timelineWrapper}>
            <Button
              prefixIcon={<ClockCircleOutlined />}
              disabled={currentPageChangelogs >= totalRecordsChangelog + 1}
              category="closed-periods"
              action="edit"
              onClick={(e) => {
                e?.stopPropagation()
                setCurrentPageChangelogs(currentPageChangelogs + 1)
                totalRecordsChangelog
              }}
            >
              {t('custom-reports.emission-factors-modal.load-more-changelogs')}
            </Button>
          </Col>
        </Row>
      </Space>
    </Modal>
  )
}

export default OutdatedEmissionFactorsModal
