import React, { useContext } from 'react'

import { Store } from 'antd/es/form/interface'

import { ApiIntegration, AuditEvent, Product, Role, User, UserFunction } from '@cozero/models'

import useAdminHandler from '../hooks/useAdmin'

export interface AdminContextInterface {
  getAuditEvents: () => Promise<AuditEvent[] | void>
  createProduct: (product: Partial<Product>) => Promise<Product | void>
  deleteProduct: (productId: number) => Promise<void>
  error?: Error
  loading: boolean
  roles: Role[]
  getRoles: () => Promise<void>
  transferProduct: (productId: number, newParentId: number) => Promise<void>
  getUserFunctions: () => Promise<UserFunction[] | void>
  createUserFunction: (body: Partial<UserFunction>) => Promise<UserFunction | void>
  updateUserFunction: (id: number, body: Partial<UserFunction>) => Promise<UserFunction | void>
  deleteUserFunction: (id: number) => Promise<void>
  userFunctions: UserFunction[]
  reset: () => void
  exportUsers: () => Promise<User[] | void>
}

export const adminContext = React.createContext<AdminContextInterface | undefined>(undefined)

const { Provider } = adminContext

export function useAdminContext(): AdminContextInterface {
  const contextValue = useContext(adminContext)
  if (contextValue === undefined) {
    throw new Error('Context must be inside a Provider')
  }
  return contextValue
}

interface ProviderProps {
  children: React.ReactNode
}

const AdminProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const adminAcessors = useAdminHandler()
  return <Provider value={adminAcessors}>{children}</Provider>
}

export default AdminProvider
