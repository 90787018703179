export const GET_ACTIVE_PRODUCTS = 'getActiveProducts'
export const GET_PRODUCTS = 'getProducts'
export const GET_PRODUCT = 'getProduct'
export const CREATE_PRODUCT = 'createProduct'
export const GET_ORGANIZATION_TOTAL_PRODUCT_EMISSIONS = 'getOrganizationTotalProductEmissions'
export const EDIT_PRODUCT = 'editProduct'
export const GET_PRODUCT_COUNT = 'getProductCount'

export const TAG_ACTIVE_PRODUCTS = 'PRODUCTS'
export const TAG_PRODUCT = 'PRODUCT'

export default [TAG_ACTIVE_PRODUCTS, TAG_PRODUCT]
