import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowLeft, HiPencil } from 'react-icons/hi'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useNavigate, useParams } from 'react-router'

import { Col, Row } from 'antd'

import { skipToken } from '@reduxjs/toolkit/query/react'

import { routes } from '@cozero/utils'

import Button from '../../../../atoms/Button'
import * as Skeleton from '../../../../atoms/Skeleton'
import { useGetProductQuery } from '../../../../redux/products'

import classes from './ProductDetailsHeader.module.less'

const ProductDetailsHeader = (): ReactElement => {
  const navigate = useNavigate()
  const { productId } = useParams()
  const { t } = useTranslation('common')
  const { data: product, isFetching: loadingProduct } = useGetProductQuery(productId ?? skipToken)

  return (
    <Row justify="space-between" align="middle" className={classes.wrapper}>
      <Col className={classes.headerIconName}>
        <Button
          action="goBack"
          category="product-info"
          type="text"
          className={classes.goBack}
          onClick={() => navigate(routes.log.carbonFootprint.products.base)}
        >
          <HiArrowLeft />
        </Button>
        <Skeleton.Paragraph size="lg" loading={loadingProduct}>
          <h2>{product?.name}</h2>
        </Skeleton.Paragraph>
      </Col>
      <Col>
        <Button
          prefixIcon={<HiPencil />}
          action="goBack"
          category="product-info"
          type="primary"
          onClick={() => {
            if (productId && product) {
              navigate(
                routes.log.carbonFootprint.productsUpsert.stepsEdit.replace(':id', productId),
              )
            }
          }}
        >
          {t('products.edit-product')}
        </Button>
      </Col>
    </Row>
  )
}

export default ProductDetailsHeader
