import React, { ReactElement, memo, useMemo } from 'react'

import classes from './Dot.module.less'

export type DotColor = 'default' | 'success' | 'danger' | 'scope1' | 'scope2' | 'scope3'

interface Props {
  type: DotColor
}

const Dot = ({ type = 'default' }: Props): ReactElement => {
  const dotClasses = useMemo(() => {
    return [classes.dot, classes[type]].join(' ')
  }, [type])

  return <div className={dotClasses} />
}

export default memo(Dot)
