import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineMail } from 'react-icons/hi'
import { useLocation, useNavigate } from 'react-router'

import { Row } from 'antd'

import _ from 'lodash'
import moment from 'moment'

import { FactorRequest } from '@cozero/models'
import { User } from '@cozero/models/src'

import LifecycleModalTitle from '@/pages/Organization/NewProduct/LifecycleModalTitle'

import CustomHeader from '@/organisms/CustomModalHeader'
import FactorRequestForm from '@/organisms/FactorRequestForm'
import {
  FactorRequestUrlState,
  FormValues,
  InitialFormData,
  ParsedFormValues,
} from '@/organisms/FactorRequestForm/types'
import RequestFactorSuccessModal from '@/organisms/RequestFactorSuccessModal'

import LayoutFooter from '@/molecules/LayoutFooter'

import productConfiguration from '@/assets/lifecycle-steps/product-configuration.svg'
import { useFactorContext } from '@/contexts/factor'
import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import { config } from '@/utils/config'

import classes from './classes.module.less'

function FactorRequestPage(): JSX.Element {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const location = useLocation()
  const [isFormValid, setIsFormValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [successfulRequestModal, setSuccessfulRequestModal] = useState(false)
  const [initialValues, setInitialValues] = useState<InitialFormData[]>([
    {
      name: 'locale',
      value: 'en',
    },
  ])
  const factorRequest = useRef<(FactorRequest & { responsibleUser: User }) | undefined>()
  const [form, setForm] = useState<FormValues>()
  const { createFactorRequest } = useFactorContext()
  const user = useAppSelector(selectUser)

  useEffect(() => {
    const { state } = location as { state: FactorRequestUrlState }
    if (state) {
      populateForm(state)
    }
  }, [location])

  const populateForm = ({ logEntry, emission }: FactorRequestUrlState): void => {
    const { supplierId, activityDataSourceId, territory } = logEntry
    const initialValuesObj: InitialFormData[] = [
      { name: 'supplierId', value: supplierId as number },
      { name: 'activityDataSourceId', value: [activityDataSourceId] },
      {
        name: 'territoryId',
        value:
          territory && territory.continentId ? [territory.continentId, territory.id] : undefined,
      },
      {
        name: 'denominatorUnitId',
        value: emission.factor?.denominatorUnitId,
      },
      {
        name: 'year',
        value: moment(emission.factor?.validityStartDate),
      },
    ]
    setInitialValues((prev) => [...prev, ...initialValuesObj])
  }

  const getFormValues = (values: FormValues): void => {
    setForm(values)
  }

  const parseForm = (form: FormValues): ParsedFormValues => {
    const parsedForm = _.cloneDeep({
      ...form,
      activityDataSourceId: 0,
      territoryId: 0,
    })
    parsedForm.activityDataSourceId = form.activityDataSourceId.at(-1) as number
    if (form.territoryId?.length) {
      parsedForm.territoryId = form.territoryId.at(-1) as number
    }
    return parsedForm
  }

  const openFactorRequestEmail = (): void => {
    if (factorRequest.current) {
      window.open(
        `mailto:${factorRequest.current.responsible}?subject=${t(
          'suppliers.factor.request-modal.email-subject',
        )}&body=${t('suppliers.factor.request-modal.email-body.title', {
          supplier: factorRequest.current.responsibleUser.username ?? t('factors.supplier'),
        })}%0D%0A%0D%0A ${t('suppliers.factor.request-modal.email-body.paragraph-1', {
          organizationName: factorRequest.current.organization.name,
          supplierCompany: factorRequest.current.responsibleUser.organization?.name,
        })}%0D%0A%0D%0A ${t(
          'suppliers.factor.request-modal.email-body.paragraph-2',
        )}%0D%0A%0D%0A${t('suppliers.factor.request-modal.email-body.signature', {
          user:
            user?.firstName && user?.lastName
              ? `${user?.firstName} ${user?.lastName}`
              : user?.username,
        })}`,
      )
    }
  }

  const handleSave = async (): Promise<void> => {
    if (form) {
      setIsSubmitting(true)
      const data = await createFactorRequest(parseForm(form))
      factorRequest.current = data
      setSuccessfulRequestModal(true)
      setIsSubmitting(false)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.sider}></div>
      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <CustomHeader
            showArrow={true}
            title={t('suppliers.factor.request-general')}
            onClose={() => navigate(-1)}
            goBack={() => {
              navigate(-1)
            }}
          />
        </div>
        <Row className={classes.content}>
          <LifecycleModalTitle
            image={productConfiguration}
            title={t('suppliers.factor.request-info.title')}
            description={t('suppliers.factor.request-info.description')}
          />
          <FactorRequestForm
            initialValues={initialValues}
            setIsFormValid={setIsFormValid}
            getFormValues={getFormValues}
          />
        </Row>
        <LayoutFooter
          hideCancelButton={false}
          categoryOkButton="factor-requests"
          categoryCancelButton="factor-requests"
          isDataValid={true}
          okButtonIcon={<HiOutlineMail />}
          onCancelClick={() => {
            navigate(-1)
          }}
          isLoading={isSubmitting}
          onSaveClick={handleSave}
          saveButtonText={t('suppliers.factor.send')}
          cancelButtonText={t('actions.back')}
          saveButtonDisabled={!isFormValid || isSubmitting}
        />
      </div>
      <RequestFactorSuccessModal
        visible={successfulRequestModal}
        openFactorRequestEmail={openFactorRequestEmail}
        closeModal={() => {
          setSuccessfulRequestModal(false)
          navigate(-1)
        }}
      />
    </div>
  )
}

export default FactorRequestPage
