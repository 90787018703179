import React from 'react'
import { useTranslation } from 'react-i18next'

import { Space, Tooltip } from 'antd/es'
import { ColumnsType } from 'antd/es/table'

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

import { FactorRequest, Territory } from '@cozero/models'

import Table from '@/molecules/Table'

import Button from '@/atoms/Button'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'

import classes from './classes.module.less'

interface Props {
  factorRequests: FactorRequest[]
  updateFactorRequest: (
    id: number,
    factorRequest: Partial<FactorRequest>,
  ) => Promise<FactorRequest | void>
}

function FactorRequestsTable({ factorRequests, updateFactorRequest }: Props): JSX.Element {
  const { t } = useTranslation('common')

  const columns: ColumnsType<FactorRequest> = [
    {
      key: 'supplier',
      title: 'Supplier',
      render(text: string, record: FactorRequest) {
        return (
          <span className={classes.nameSubcontainer}>
            <Text size="xl" className={classes.categoryName}>
              {record.supplier?.name}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'territory',
      title: t('customer-requests.territory'),
      render(text: string, record: FactorRequest) {
        if (!record.territory) {
          return
        }
        return (
          <span className={classes.nameSubcontainer}>
            <Text size="xl" className={classes.categoryName}>
              {(record.territory as Territory).name}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'product',
      title: t('customer-requests.product'),
      render(text: string, record: FactorRequest) {
        if (!record.product) {
          return
        }
        return (
          <span className={classes.nameSubcontainer}>
            <Text size="xl" className={classes.categoryName}>
              {record.product.name}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'requester',
      title: t('customer-requests.requester'),
      render(text: string, record: FactorRequest) {
        const user = record.requester
        if (!user) {
          return null
        }
        const userName = user.firstName ? `${user.firstName} ${user.lastName}` : user.email
        return (
          <span className={classes.nameSubcontainer}>
            <Text size="xl" className={classes.categoryName}>
              {userName}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'responsible',
      title: t('customer-requests.responsible'),
      render(text: string, record: FactorRequest) {
        return (
          <span className={classes.nameSubcontainer}>
            <Text size="xl" className={classes.categoryName}>
              {record.responsible}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'unit_sources',
      title: t('customer-requests.activity-data'),
      render(text: string, record: FactorRequest) {
        const activityData = record.activityDataSource?.name as string
        return (
          <span className={classes.nameSubcontainer}>
            <Text size="xl" className={classes.categoryName}>
              {activityData}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'factor',
      title: t('customer-requests.value'),
      render(text: string, record: FactorRequest) {
        return (
          <div className={classes.nameSubcontainer}>
            {record.value ? (
              <span>
                {record.value} {record.unit.key}
              </span>
            ) : (
              <Tag type={'warning'}>{t('customer-requests.missing')}</Tag>
            )}
          </div>
        )
      },
    },
    {
      key: 'dueDate',
      title: t('customer-requests.due-date'),
      render(text: string, record: FactorRequest) {
        if (!record.dueDate) {
          return
        }
        const past = moment(record.dueDate).diff(moment()) < 0
        const status =
          past && record.status === 'REQUESTED'
            ? 'danger'
            : record.status === 'REQUESTED'
            ? 'warning'
            : 'info'
        return (
          <span className={classes.nameSubcontainer}>
            <Tag type={status}>{moment(record.dueDate).format('LL')}</Tag>
          </span>
        )
      },
    },
    {
      key: 'note',
      title: t('customer-requests.note'),
      ellipsis: {
        showTitle: false,
      },
      render(text: string, record: FactorRequest) {
        return (
          <span className={classes.nameSubcontainer}>
            <Tooltip title={record.note}>{record.note}</Tooltip>
          </span>
        )
      },
    },
    {
      title: t('customer-requests.status'),
      key: 'action',
      width: 250,
      fixed: 'right',
      render(text: string, record: FactorRequest) {
        return (
          <Space className={classes.actions}>
            {record.status === 'SUBMITTED' ? (
              <div>
                <Button
                  onClick={() =>
                    updateFactorRequest(record.id, {
                      status: 'ACCEPTED',
                      unitId: record.unitId,
                    })
                  }
                  prefixIcon={<CheckCircleOutlined />}
                  style={{ marginRight: 10 }}
                  type={'primary'}
                  action={'accept'}
                  category={'factor-requests'}
                >
                  {t('customer-requests.accept')}
                </Button>
                <Button
                  onClick={() =>
                    updateFactorRequest(record.id, { status: 'REJECTED', unitId: record.unitId })
                  }
                  type="primary"
                  color="danger"
                  prefixIcon={<CloseCircleOutlined />}
                  action={'reject'}
                  category={'factor-requests'}
                >
                  {t('customer-requests.reject')}
                </Button>
              </div>
            ) : (
              <Tag>
                {record.status.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase())} {t('on')}{' '}
                {record.updatedAt
                  ? moment(record.updatedAt).format('DD.MM.YYYY')
                  : moment(record.createdAt).format('DD.MM.YYYY')}
              </Tag>
            )}
          </Space>
        )
      },
    },
  ]

  return (
    <Table
      dataSource={factorRequests}
      rowKey="id"
      className={classes.table}
      columns={columns}
      scroll={{ x: 1500 }}
      showWrapper={false}
    />
  )
}

export default FactorRequestsTable
