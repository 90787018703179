import React, { ReactNode, Suspense, lazy } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { RouteObject } from 'react-router-dom'

import { routes } from '@cozero/utils'

import ClimateActionProvider from '@/contexts/climateAction'
import LogProvider from '@/contexts/log'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'
import SuspenseSpinner from '../SuspenseSpinner'

const NewClimateAction = lazy(() => import('@/pages/Act/ClimateActions/New'))
const EditClimateAction = lazy(() => import('@/pages/Act/ClimateActions/Edit'))
const EditTarget = lazy(() => import('@/pages/Act/Targets/EditTargets'))
const NewTarget = lazy(() => import('@/pages/Act/Targets/NewTargets'))

const Wrappers = ({ children }: { children: ReactNode }): ReactElement => (
  <LogProvider>
    <PrivateRoute>
      <FeatureRoute requiredFeature="act">{children}</FeatureRoute>
    </PrivateRoute>
  </LogProvider>
)

const getCustomActRoutes = (): RouteObject[] => [
  {
    path: routes.act.createClimateAction,
    element: (
      <Wrappers>
        <ClimateActionProvider>
          <Suspense fallback={<SuspenseSpinner />}>
            <NewClimateAction />
          </Suspense>
        </ClimateActionProvider>
      </Wrappers>
    ),
  },
  {
    path: routes.act.editClimateAction,
    element: (
      <Wrappers>
        <ClimateActionProvider>
          <Suspense fallback={<SuspenseSpinner />}>
            <EditClimateAction />
          </Suspense>
        </ClimateActionProvider>
      </Wrappers>
    ),
  },
  {
    path: routes.act.newTarget,
    element: (
      <Wrappers>
        <ClimateActionProvider>
          <Suspense fallback={<SuspenseSpinner />}>
            <NewTarget />
          </Suspense>
        </ClimateActionProvider>
      </Wrappers>
    ),
  },
  {
    path: routes.act.editTarget,
    element: (
      <Wrappers>
        <ClimateActionProvider>
          <Suspense fallback={<SuspenseSpinner />}>
            <EditTarget />
          </Suspense>
        </ClimateActionProvider>
      </Wrappers>
    ),
  },
]

export default getCustomActRoutes
