import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineFilter } from 'react-icons/hi'

import { Col, Row, Spin } from 'antd'

import debounce from 'lodash/debounce'

import {
  ActivityDataSource,
  EmissionFactor,
  EmissionFactorWithIncludes,
  FactorOrigin,
  Page,
  PageFilter,
  Territory,
  Unit,
} from '@cozero/models'

import classes from '@/pages/Log/Factors/classes.module.less'

import CustomFactorModal from '@/organisms/CustomFactorModal'
import FactorsTable from '@/organisms/FactorsTable'

import FactorRequestButton from '@/molecules/FactorRequestButton'
import FiltersDrawer, { PageType } from '@/molecules/FiltersDrawer'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { useFactorContext } from '@/contexts/factor'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed } from '@/redux/auth'
import { createFilterOptions } from '@/utils/filters'

const EmissionFactors = (): ReactElement => {
  const { t } = useTranslation('common')
  const {
    getFactors,
    updateCustomFactor,
    createCustomFactor,
    getFactorFilters,
    getFactorRequests,
  } = useFactorContext()
  const [factors, setFactors] = useState<Page<EmissionFactorWithIncludes> | undefined>()
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [selectedFactor, setSelectedFactor] = useState<EmissionFactorWithIncludes | undefined>()
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [filterOptions, setFilterOptions] = useState<PageFilter[]>([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [filterData, setFilterData] = useState<{
    factorOrigins: FactorOrigin[]
    units: Unit[]
    territories: Territory[]
    activityDataSources: ActivityDataSource[]
  }>({ factorOrigins: [], units: [], territories: [], activityDataSources: [] })
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const [filters, setFilters] = useState<PageFilter[]>([])

  async function fetchData(): Promise<void> {
    setLoading(true)
    const fetchedFactors = await getFactors({ used: true, page, pageSize, filters })
    await getFactorRequests()
    if (fetchedFactors) {
      setFactors(fetchedFactors)
    }
    setLoading(false)
  }

  async function updateFactor(
    id: number,
    values: Partial<EmissionFactorWithIncludes>,
  ): Promise<void> {
    updateCustomFactor(id, values)
    onCloseModal()
    fetchData()
  }

  async function createFactor(
    factor: EmissionFactor,
    values: Partial<EmissionFactorWithIncludes>,
  ): Promise<void> {
    createCustomFactor(factor.id, {
      ...values,
      activityDataSourceId: factor.activityDataSourceId,
      denominatorUnitId: factor.denominatorUnitId,
      unitId: factor.unitId,
      territoryId: factor.territoryId,
      originId: factor.originId,
      value: parseFloat(`${values.value}`),
    })
    onCloseModal()
    fetchData()
  }

  const onFilterFactors = async (changedFilters: PageFilter[]): Promise<void> => {
    setFilters(changedFilters)
  }

  const onCloseModal = (): void => {
    setSelectedFactor(undefined)
    setModalIsOpen(false)
  }

  const openDrawer = async (): Promise<void> => {
    const fetchedData = await getFactorFilters()
    setFilterData(fetchedData)
    setFilterDrawerOpen(true)
  }

  const onSelectFactor = (factor: EmissionFactorWithIncludes): void => {
    setModalIsOpen(true)
    setSelectedFactor(factor)
  }

  useEffect(() => {
    fetchData()
  }, [page, pageSize, filters])

  useEffect(() => {
    setFilterOptions(
      createFilterOptions({
        type: 'factors',
        t,
        featuresAllowed,
        shownOptions: ['factorOrigins', 'units', 'activityDataSources', 'territories'],
        page: 'factors',
        activityDataSources: filterData.activityDataSources,
        factorOrigins: filterData.factorOrigins,
        territories: filterData.territories,
        units: filterData.units,
      }),
    )
  }, [filterData])

  return (
    <Spin spinning={loading}>
      <div className={classes.innerContainer}>
        <Row className={classes.headerRow}>
          <Col span={24}>
            <Title size="sm">{t('settings.factors.title')}</Title>
          </Col>
          <Col xxl={12} xl={12} lg={16} span={24} className={classes.header}>
            <Text size="xl" color="secondary">
              {t('settings.factors.subtitle')}
            </Text>
          </Col>
        </Row>

        <Row justify="end">
          <FactorRequestButton />
        </Row>

        <Row>
          <Col span={24} className={classes.factorsTableContainer}>
            <Row justify="end" className={classes.filterButton}>
              <Col>
                <Button
                  onClick={openDrawer}
                  category={'factors'}
                  action={'open-filter'}
                  prefixIcon={<HiOutlineFilter />}
                >
                  <Text size="xl" fontWeight="medium">
                    {t('log.filter.add')}
                  </Text>
                </Button>{' '}
              </Col>
            </Row>

            <FactorsTable
              factors={factors}
              page={page}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setPage={setPage}
              setSelectedFactor={onSelectFactor}
            />
          </Col>
        </Row>
      </div>
      <FiltersDrawer
        visible={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        search={debounce(onFilterFactors, 500)}
        pageType={PageType.FACTOR}
        filterOptions={
          ([...(filterOptions ?? [])] as (PageFilter & {
            options: {
              key?: string
              value: string
              label: string
            }[]
          })[]) ?? []
        }
      />
      <CustomFactorModal
        isVisible={modalIsOpen}
        onClose={onCloseModal}
        selectedFactor={selectedFactor}
        updateCustomFactor={updateFactor}
        createCustomFactor={createFactor}
      />
    </Spin>
  )
}

export default EmissionFactors
