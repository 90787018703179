import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Col from 'antd/es/col'

import { Board, Report, ReportType } from '@cozero/models'

import ErrorGraphView from '@/organisms/ErrorGraphView'

import LoadingSpinner from '@/atoms/LoadingSpinner'
import Text from '@/atoms/Text'

import { useFiltersContext } from '@/contexts/filters'
import useBoards from '@/hooks/useBoards'
import i18n from '@/i18n'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'

import OverviewCard from '../OverviewCard'

import classes from './GraphStatisticsCard.module.less'
import { UnitMeasureText } from './UnitMeasureText'

const FETCH_TIMEOUT = 5 * 1000 * 60 // 5 minutes

interface GraphStatisticsCardProps {
  report: Report | null
  includeChildrenBUsData?: boolean
}

export const GraphStatisticsCard = ({
  report,
  includeChildrenBUsData = false,
}: GraphStatisticsCardProps): JSX.Element | null => {
  const { t } = useTranslation('common')

  const { filters } = useFiltersContext()
  const [statsLoading, setStatsLoading] = useState<boolean>(true)
  const [isRefetching, setIsRefetching] = useState<boolean>(false)
  const [statsError, setStatsError] = useState<boolean>(false)
  const [currentStatData, setCurrentStatData] = useState<Report | null>(report)
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const { getReportWithData } = useBoards()
  const abortControllerRef = useRef<AbortController>()
  const fetchTime = useRef<number>(new Date().getTime())
  const location = useLocation()

  useEffect(() => {
    if (currentStatData?.type === ReportType.STATISTIC && selectedBusinessUnit) {
      fetchGraphData()
    }
  }, [filters, selectedBusinessUnit?.key])

  useEffect(() => {
    return function cleanup() {
      abortControllerRef.current?.abort()
    }
  }, [location.pathname])

  useEffect(() => {
    return function cleanup() {
      abortControllerRef.current?.abort()
    }
  }, [location.pathname])
  if (!currentStatData) {
    return <></>
  }

  const fetchGraphData = async (): Promise<void> => {
    setStatsLoading(true)

    // Check if previous request was executed and trigger abort
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    // Assign new controller to the current ref for every new graph request
    abortControllerRef.current = new AbortController()

    /** Using try & catch here so that when AbortController is triggered
     * for the previous request, we set Loading false only when the report is fetched
     * or throws an actual error and not when the request has been cancelled
     **/
    try {
      const reportWithData = await getReportWithData(
        currentStatData?.key,
        filters,
        undefined,
        abortControllerRef.current?.signal,
        includeChildrenBUsData,
      )
      if (reportWithData) {
        setCurrentStatData(reportWithData)
        setStatsError(false)
        setStatsLoading(false)
        setIsRefetching(false)
      }
    } catch (e) {
      const isTimeoutResponse = e.response?.status === 504
      const canRefetch = new Date().getTime() < fetchTime.current + FETCH_TIMEOUT

      if (isTimeoutResponse && canRefetch) {
        setIsRefetching(true)
        fetchGraphData()
      } else if (e.message !== 'canceled') {
        setStatsError(true)
        setStatsLoading(false)
        setIsRefetching(false)
      }
    }
  }
  if (!currentStatData) {
    return null
  }

  const { type, data, cubeJSQuery, title } = currentStatData

  return (
    <>
      {type === ReportType.STATISTIC && !statsError && (
        <Col flex="auto" style={{ maxWidth: '50%' }}>
          <div className={classes.statisticContainer}>
            {isRefetching ? (
              <LoadingSpinner
                className={classes.calculatingSpinner}
                title={t('share.reports.calculating.title')}
                text={t('share.reports.calculating.text', { joinArrays: '\n' })}
              />
            ) : statsLoading ? (
              <LoadingSpinner title={t('share.reports.loading')} />
            ) : statsError ? (
              <ErrorGraphView />
            ) : (
              <OverviewCard
                style={{ height: '100%' }}
                loading={statsLoading}
                content={
                  <>
                    {data && data.totalRow && data.seriesNames && data.seriesNames[0] && (
                      <Text
                        mode="title"
                        fontWeight="medium"
                        size="lg"
                        className={classes.emissionsValue}
                      >
                        {data.totalRow
                          ? `${data.totalRow[data.seriesNames[0].key]?.toLocaleString(
                              i18n.language,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            )}`
                          : ''}
                      </Text>
                    )}
                    <UnitMeasureText cubeJSQuery={cubeJSQuery} />
                  </>
                }
                headerTitle={title}
              />
            )}
          </div>
        </Col>
      )}
    </>
  )
}
