import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineFilter, HiPlus } from 'react-icons/hi'

import { Col, Row, Space } from 'antd/es'

import { Board, PageFilter, Report } from '@cozero/models'

import DateRangePickerFilter from '@/molecules/DateRangePickerFilter'
import { GraphStatisticsCard } from '@/molecules/GraphStatisticsCard'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

interface Props {
  disableAddReport?: boolean
  selectedBoard?: Board
  filters: PageFilter[]
  openDrawer?: () => void
  openReportMenu?: () => void
  loading: boolean
  onFilterSearch: (filters: PageFilter[]) => Promise<void>
  logRange?: { startDate?: Date; endDate?: Date }
  featuresAllowed: string[]
}

const DashboardHeader = ({
  disableAddReport,
  selectedBoard,
  filters,
  openDrawer,
  openReportMenu,
  loading,
  onFilterSearch,
  logRange,
}: Props): ReactElement => {
  const { t } = useTranslation('common')
  const isEmpty =
    !selectedBoard || !selectedBoard.reportToBoards || selectedBoard.reportToBoards.length <= 0
  return (
    <>
      <Row gutter={[16, 24]} style={{ paddingBottom: 36 }}>
        <Col span={12}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Title size="sm">{t('layout.dashboard.title')}</Title>
            <Text size="xl" color="secondary">
              {t('layout.dashboard.description')}
            </Text>
          </div>
        </Col>
      </Row>
      {!isEmpty && (
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <Row gutter={10} justify="end">
            <Col>
              <Button
                onClick={openDrawer}
                category="logs"
                action="open-filter"
                fillParent
                type="ghost"
                prefixIcon={<HiOutlineFilter />}
              >
                {t('log.filter.add')}
              </Button>
            </Col>
            <Col>
              <DateRangePickerFilter
                filters={filters}
                logRange={logRange}
                saveFilters={onFilterSearch}
                visualStyle="ghost"
              />
            </Col>
            {selectedBoard?.customizable && !disableAddReport && (
              <Col>
                <Button
                  category="home"
                  action="add-report"
                  loading={loading}
                  onClick={openReportMenu}
                  type="primary"
                  prefixIcon={<HiPlus />}
                >
                  {t('share.reports.add-board')}
                </Button>
              </Col>
            )}
          </Row>
          <Row gutter={[8, 8]}>
            {selectedBoard?.reportToBoards?.map((card) => (
              // TODO: typing of card is not correct, would require a major refactoring
              <GraphStatisticsCard
                key={card.report?.id}
                report={card.report as Report | null}
                includeChildrenBUsData
              />
            ))}
          </Row>
        </Space>
      )}
    </>
  )
}

export default DashboardHeader
