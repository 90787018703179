import { useState } from 'react'
import { Layout } from 'react-grid-layout'
import { useTranslation } from 'react-i18next'

import { Store } from 'antd/es/form/interface'

import { debounce, isEqual, omit, pick } from 'lodash-es'

import {
  Board,
  IGraphData,
  PageFilter,
  Product,
  Report,
  ReportCategory,
  ReportSetting,
  ReportToBoard,
} from '@cozero/models'
import { reportsApiGatewayClient } from '@cozero/uris'

import { BoardsContextInterface } from '../contexts/boards'
import { useAppSelector } from '../redux'
import { selectUserOrganization } from '../redux/auth'
import { selectUser } from '../redux/auth'
import { selectSelectedBusinessUnit } from '../redux/businessUnits'
import axios from '../utils/axios'

/**
 * A hook providing the boards and the state for editing these
 */
const useBoards = (): BoardsContextInterface => {
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const { t } = useTranslation('common')
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingMainBoard, setLoadingMainBoard] = useState<boolean>(false)
  const [loadingBoards, setLoadingBoards] = useState<boolean>(false)
  const [loadingCalculations, setLoadingCalculations] = useState<boolean>(false)
  const [boards, setBoards] = useState<Board[]>([])
  const [selectedBoard, setSelectedBoard] = useState<Board>()
  const [initialSelectedBoard, setInitialSelectedBoard] = useState<Board>()
  const [selectedProduct, setSelectedProduct] = useState<Product>()
  const [reports, setReports] = useState<Report[]>([])
  const [editingBoard, setEditingBoard] = useState<boolean>(false)
  const [reportSettings, setReportSettings] = useState<ReportSetting[]>([])
  const [lastDeletedReportId, setLastDeletedReportId] = useState<number>()
  const organization = useAppSelector(selectUserOrganization)
  const user = useAppSelector(selectUser)

  /**
   * getBoards.
   *
   * Fetch all available boards
   */
  async function getBoards(): Promise<void> {
    try {
      setReportSettings([])
      setLoadingBoards(true)
      const { data } = await axios.get<Board[]>(reportsApiGatewayClient.boards.GET_MANY)
      setBoards(data)
    } catch (e) {
      setError(e)
    } finally {
      setLoadingBoards(false)
    }
  }

  /**
   * getBoard.
   *
   * Fetch individual board
   */
  const getBoard = async ({ boardId }: { boardId: number }): Promise<void> => {
    try {
      setLoadingMainBoard(true)
      const { data } = await axios.get<Board>(
        reportsApiGatewayClient.boards.GET_ONE.replace(':id', boardId.toString()),
      )
      setSelectedBoard(data)
    } catch (e) {
      setError(e)
    } finally {
      setLoadingMainBoard(false)
    }
  }

  /**
   * getBoardData.
   *
   * Fetch the board, set as the selected board
   * @param dateRange the date range for the data in the board
   * @param args.board the board to fetch
   * @param args.selectedLocation
   * @param args.selectedProduct
   */
  const getBoardData = async ({
    board,
    filters,
    sharePageId,
    includeChildrenBUsData,
    closedPeriodId,
  }: {
    board?: Board
    filters?: PageFilter[]
    sharePageId?: number
    closedPeriodId?: number
    includeChildrenBUsData?: number
  }): Promise<Board | undefined> => {
    try {
      setError('')
      setLoadingCalculations(true)
      if (!board) {
        throw new Error(t('share.reports.errors.no-board'))
      }
      if (!selectedBusinessUnit) {
        throw new Error(t('share.reports.errors.no-board'))
      }

      const { data: fetchedBoard } = await axios.post<Board>(
        reportsApiGatewayClient.boards.GET_DATA.replace(':id', board.id.toString()),
        { filters: filters?.map((x) => ({ ...omit(x, 'options') })), settings: reportSettings },
        {
          params: {
            businessUnitId: selectedBusinessUnit?.id,
            sharePageId,
            closedPeriodId,
            includeChildrenBUsData,
          },
        },
      )

      setSelectedBoard(fetchedBoard)
      setInitialSelectedBoard(fetchedBoard)
      return fetchedBoard
    } catch (e) {
      setError(e.message)
    } finally {
      setLoadingCalculations(false)
    }
  }

  /**
   * saveBoardLayout.
   *
   * Save the Board layout to the backend
   *
   * @param board the board to save
   * @returns fetchedBoard the current board from the backend
   */
  const saveBoardLayout = async (board: Board): Promise<Board | void> => {
    try {
      if (!board) {
        throw new Error(t('share.reports.errors.no-board'))
      }

      setLoading(true)

      const { data: fetchedBoard } = await axios.post<Board>(
        reportsApiGatewayClient.boards.UPDATE_ONE.replace(':id', board.id.toString()),
        board,
      )

      return fetchedBoard
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  /**
   * getAvailableReports.
   * Fetch all available reports for the org.
   */
  const getAvailableReports = async (): Promise<void> => {
    try {
      setLoading(true)
      const { data: reports } = await axios.get<Report[]>(reportsApiGatewayClient.reports.GET_MANY)

      if (reports) {
        setReports(reports)
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  /**
   * getAvailableReports.
   * Fetch all available reports for the org.
   */
  const searchReportCategories = async (searchText: string): Promise<ReportCategory[]> => {
    let categories: ReportCategory[] = []
    try {
      setLoading(true)
      const { data } = await axios.get<ReportCategory[]>(
        reportsApiGatewayClient.reportCategories.SEARCH,
        {
          params: {
            searchText,
          },
        },
      )
      categories = data
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
    return categories
  }

  /**
   * updateReportToBoard.
   *
   * Update the grid data
   * @param board
   * @param reportId the id of the report
   * @param newValues the new layout data for the grid data
   * @returns
   */
  const updateReportToBoard = async (
    board: Board,
    reportId: number,
    newValues: Partial<ReportToBoard>,
  ): Promise<void> => {
    try {
      if (board) {
        await axios.put(
          reportsApiGatewayClient.reports.UPDATE_GRID_DATA.replace(':id', reportId.toString()),
          newValues,
          {
            params: { boardId: board.id, sharePageId: newValues.sharePageId },
          },
        )
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  const updateBoardLayout = (board: Board, boardLayout?: Layout[], sharePageId?: number): void => {
    if (board && boardLayout && boardLayout.length > 0) {
      board.reportToBoards?.forEach((gridData) => {
        const newGrid = boardLayout.find((layout) => gridData.id.toString() === layout.i.toString())
        if (newGrid) {
          const newValues = {
            xCoord: newGrid.x,
            yCoord: newGrid.y,
          }
          if (!isEqual(newValues, pick(gridData, ['xCoord', 'yCoord']))) {
            updateReportToBoard(board, (gridData.report as Report).id, {
              xCoord: newGrid.x,
              yCoord: newGrid.y,
              sharePageId,
            })
          }
        }
      })
    }
  }

  const updateBoardWidth = (board: Board, boardId: number): void => {
    const boardToUpdate = board.reportToBoards.find((current) => current.id === boardId)
    if (boardToUpdate && boardToUpdate.reportId) {
      updateReportToBoard(board, boardToUpdate.reportId, { width: boardToUpdate.width })
    }
  }

  const editReportsOfBoard = async (
    reports: Store,
    id: number,
    sharePageId?: number,
    closedPeriodId?: number,
  ): Promise<void> => {
    try {
      await axios.put(
        reportsApiGatewayClient.boards.UPDATE_REPORT_GRID_DATA.replace(':id', id.toString()),
        reports,
        {
          params: { sharePageId, closedPeriodId },
        },
      )
    } catch (error) {
      throw new Error(`${t('share.reports.errors.add')}: ${error.message ?? ''}`)
    }
  }

  /**
   * removeReportFromSelectedBoard.
   * Remove a specific report from the currently selected board
   * @param reportId
   * @param userId?
   * @param sharePageId?
   */
  const removeReportFromSelectedBoard = async (
    reportId: number,
    userId?: number,
    sharePageId?: number,
  ): Promise<void> => {
    try {
      if (selectedBoard) {
        await axios.delete(
          reportsApiGatewayClient.reports.DELETE_REPORT_GRID_DATA.replace(
            ':id',
            reportId.toString(),
          ),
          {
            params: {
              boardId: selectedBoard.id,
              userId,
              boardDocLevel: selectedBoard.level,
              ...(selectedBoard.type === 'share' ? { sharePageId } : {}),
            },
          },
        )
        setLastDeletedReportId(reportId)
      }
    } catch (error) {
      throw new Error(`${t('share.reports.errors.remove')}: ${error.message ?? ''}`)
    }
  }

  /**
   * clearUpdatedLayout.
   * Reset the board to its initial state and persist
   */
  const clearUpdatedLayout = (): void => {
    if (initialSelectedBoard) {
      updateBoard(initialSelectedBoard)
    }
  }

  /**
   * updateBoard.
   * Save the board layout to the api, debounce the save by 250 ms.
   */
  function updateBoard(board: Board): void {
    const debouncedSave = debounce(saveBoardLayout, 250)
    debouncedSave(board)
    setSelectedBoard(board)
  }

  /**
   * getGraphData
   * Get the data from one specific graph
   */
  const getGraphData = async (
    report: { id?: number; key?: string },
    filters?: PageFilter[],
    forecastConfig?: number,
  ): Promise<IGraphData | undefined> => {
    try {
      setLoading(true)
      const { data: graphData } = await axios.post<IGraphData>(
        reportsApiGatewayClient.reports.GET_GRAPH_DATA,
        {
          report,
          requestedBusinessUnitId: selectedBusinessUnit?.id,
          filters,
          forecastConfig,
        },
        { params: { organizationId: organization?.id } },
      )
      return graphData
    } catch (e) {
      setError(e.message)
      throw new Error(e.message)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Get a report with the relevant data
   */
  const getReportWithData = async (
    key: string,
    filters?: PageFilter[],
    setting?: ReportSetting,
    abortSignal?: AbortSignal,
    includeChildrenBUsData = false,
  ): Promise<Report | undefined> => {
    if (!selectedBusinessUnit) {
      return
    }
    const { data: report } = await axios.post<Report>(
      reportsApiGatewayClient.reports.GET_REPORT_WITH_DATA.replace(':key', key),
      {
        requestedBusinessUnitId: selectedBusinessUnit.id,
        filters,
        setting,
        locale: user?.locale || 'en',
        includeChildrenBUsData,
      },
      { signal: abortSignal },
    )
    return report
  }

  return {
    error,
    loading,
    loadingBoards,
    loadingMainBoard,
    getBoards,
    getBoard,
    boards,
    selectedBoard,
    setSelectedBoard,
    setLoading,
    loadingCalculations,
    setLoadingCalculations,
    selectedProduct,
    setSelectedProduct,
    getAvailableReports,
    reports,
    editReportsOfBoard,
    updateReportToBoard,
    updateBoardWidth,
    removeReportFromSelectedBoard,
    editingBoard,
    setEditingBoard,
    clearUpdatedLayout,
    updateBoardLayout,
    getBoardData,
    reportSettings,
    setReportSettings,
    getGraphData,
    getReportWithData,
    searchReportCategories,
    lastDeletedReportId,
  }
}

export default useBoards
