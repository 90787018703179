import React, { ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'

import { routes } from '@cozero/utils'

import Router from '@/templates/Router'

import AdminProvider from '@/contexts/admin'
import AppContextProvider from '@/contexts/app'
import BoardsProvider from '@/contexts/boards'
import FactorContextProvider from '@/contexts/factor'
import {
  default as SubscriptionProvider,
  default as SubscriptionsContextProvider,
} from '@/contexts/subscription'
import { useLoginWithJwtTokenMutation } from '@/redux/auth'
import { resetAuthState } from '@/redux/auth/slice'
import { resetSelectedBusinessUnit } from '@/redux/businessUnits'

import { useInitMixpanel } from './hooks/useMixpanel'
import useUserLocale from './hooks/useUserLocale'

export const BaseLayout = (): ReactElement => {
  const dispatch = useDispatch()
  const [loginWithToken] = useLoginWithJwtTokenMutation()

  useUserLocale()
  useInitMixpanel()

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries()) as { token: string }
  const pathname = window.location.pathname

  const validateToken = async (token: string): Promise<void> => {
    await loginWithToken(token)
  }

  useEffect(() => {
    // To login suppliers
    if (params.token && pathname === routes.onboarding) {
      dispatch(resetAuthState())
      dispatch(resetSelectedBusinessUnit())
      validateToken(params.token)
    }
  }, [params.token, pathname])

  return (
    <IntercomProvider appId="qopytuvo" apiBase="https://api-iam.intercom.io">
      <BrowserRouter>
        <AppContextProvider>
          <AdminProvider>
            <SubscriptionProvider>
              <BoardsProvider>
                <FactorContextProvider>
                  <SubscriptionsContextProvider>
                    <Router />
                  </SubscriptionsContextProvider>
                </FactorContextProvider>
              </BoardsProvider>
            </SubscriptionProvider>
          </AdminProvider>
        </AppContextProvider>
      </BrowserRouter>
    </IntercomProvider>
  )
}
