import React, { ReactElement, memo } from 'react'

import { Menu, MenuItemProps } from 'antd/es'

import { MenuClickEventHandler, MenuInfo } from 'rc-menu/lib/interface'

import { useMixpanel } from '@/hooks/useMixpanel'
import { AnalyticsEvent } from '@/types/analytics'

const MenuItem = ({
  onClick,
  action,
  category,
  children,
  disabled,
  ...rest
}: MenuItemProps & { action: string; category: AnalyticsEvent }): ReactElement => {
  const { trackAction } = useMixpanel()

  const onClickHandler: MenuClickEventHandler = (event: MenuInfo): void => {
    if (disabled) {
      return
    }
    if (action && category) {
      trackAction(category, action)
    }

    if (event && onClick) {
      return onClick(event)
    }
  }

  return (
    <Menu.Item {...rest} onClick={onClickHandler}>
      {children}
    </Menu.Item>
  )
}

export default memo(MenuItem)
