import { createApi, retry } from '@reduxjs/toolkit/query/react'

import ACTION_AREA_TAGS from '../actionAreas/tags'
import API_KEY_TAGS from '../apiKeys/constants'
import USER_TAGS from '../auth/constants'
import { axiosBaseQuery } from '../axiosBaseQuery'
import BUSINESS_UNIT_TAGS from '../businessUnits/constants'
import CATEGORY_TAGS from '../categories/tags'
import CLIMATE_ACTION_TAGS from '../climateActions/constants'
import { default as CONFIGURATION_VIEW_TAGS } from '../configViews/constants'
import INDUSTRY_TAGS from '../industries/constants'
import LOCATION_TAGS from '../locations/tags'
import LOG_TAGS from '../logs/tags'
import ORDER_TAGS from '../orders/tags'
import ORGANIZATION_TAGS from '../organizations/tags'
import PRODUCT_TAGS from '../products/constants'
import SCOPE_TAGS from '../scopes/tags'
import SOLUTION_TAG from '../solutions/tags'
import TARGET_TAGS from '../targets/tags'
import { default as USERS_TAGS, default as USER_FUNCTIONS_TAGS } from '../users/constants'

/*
 * Default retry functionality
 * Should be overridden on the endpoint query level
 * with `extraOptions: { maxRetries: 0 }`
 */
const baseQueryWithRetry = retry(axiosBaseQuery, {
  retryCondition: (_error, queryArgs, extra) => {
    if (extra.attempt < 2 && queryArgs.method === 'get') {
      return true
    }
    return false
  },
})

/*
 * All tags provided by the api slice,
 * the tag constants are defined in the relevant
 * store slice and exported from there
 */
const tagTypes = [
  ...CATEGORY_TAGS,
  ...SCOPE_TAGS,
  ...TARGET_TAGS,
  ...ACTION_AREA_TAGS,
  ...CLIMATE_ACTION_TAGS,
  ...LOCATION_TAGS,
  ...PRODUCT_TAGS,
  ...BUSINESS_UNIT_TAGS,
  ...ORDER_TAGS,
  ...SOLUTION_TAG,
  ...ORGANIZATION_TAGS,
  ...LOG_TAGS,
  ...USER_TAGS,
  ...INDUSTRY_TAGS,
  ...USERS_TAGS,
  ...USER_FUNCTIONS_TAGS,
  ...API_KEY_TAGS,
  ...CONFIGURATION_VIEW_TAGS,
]

/*
 * The global api slice, which stores all documents
 * fetched using RTK query
 * Slice specific endpoints should be injected in the slice
 * specific directory
 */
const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithRetry,
  tagTypes,
  endpoints: () => ({}),
})

export default apiSlice
