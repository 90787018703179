import {
  StrategyBuilderOverviewValuesData,
  StrategyBuilderOverviewValuesDto,
  UpsertForecastConfigDto,
} from '@cozero/dtos'
import {
  ForecastRegion,
  ForecastScenario,
  ForecastUnit,
  StrategyBuilderState,
} from '@cozero/models'
import { actApiGatewayClient } from '@cozero/uris'

import { FormValues } from '@/molecules/ForecastOnboardingSettings/ForecastOnboardingSettings'

import { providesList } from '@/utils/redux'

import { TAG_CONSOLIDATED_GRAPH, TAG_WATERFALL_GRAPH } from '../actGraphs'
import apiSlice from '../api'

const GET_STRATEGY_BUILDER_STATE = 'getStrategyBuilderState'
const GET_FORECAST_REGIONS = 'getForecastRegions'
const GET_FORECAST_SCENARIOS = 'getForecastScenarios'

const SETUP_STRATEGY_BUILDER = 'setupStrategyBuilder'
const CREATE_FORECAST_CONFIG = 'createForecastConfig'
const GET_FORECAST_UNITS = 'getForecastUnits'
const GET_STRATEGY_BUILDER_OVERVIEW_VALUES = 'getStrategyBuilderOverviewValues'

export const TAG_GET_STRATEGY_BUILDER_STATE = 'TAG_GET_STRATEGY_BUILDER_STATE'
export const TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES = 'TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES'
const TAG_FORECAST_REGIONS = 'TAG_FORECAST_REGIONS'
const TAG_FORECAST_SCENARIOS = 'TAG_FORECAST_SCENARIOS'
const TAG_FORECAST_UNITS = 'TAG_FORECAST_UNITS'

const strategyBuilderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_STRATEGY_BUILDER_STATE]: builder.query<StrategyBuilderState, { businessUnitId: number }>({
      query: ({ businessUnitId }) => ({
        url: '/act/strategy-builder',
        method: 'GET',
        params: { businessUnitId },
      }),
      providesTags: () => [{ type: TAG_GET_STRATEGY_BUILDER_STATE }],
    }),

    [SETUP_STRATEGY_BUILDER]: builder.mutation<
      void,
      FormValues & {
        closedPeriodId: number
      }
    >({
      query: (data) => {
        return {
          url: '/act/strategy-builder/setup',
          method: 'POST',
          data,
        }
      },
      invalidatesTags: () => [
        { type: TAG_GET_STRATEGY_BUILDER_STATE },
        { type: TAG_WATERFALL_GRAPH },
        { type: TAG_CONSOLIDATED_GRAPH },
        { type: TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES },
      ],
    }),

    [CREATE_FORECAST_CONFIG]: builder.mutation<{ id: number }, UpsertForecastConfigDto>({
      query: (data) => ({
        url: actApiGatewayClient.forecastConfigs.CREATE_CONFIG,
        method: 'POST',
        data,
      }),
      invalidatesTags: () => [{ type: TAG_GET_STRATEGY_BUILDER_STATE }],
    }),

    [GET_FORECAST_SCENARIOS]: builder.query<ForecastScenario[], void>({
      query: () => ({
        url: actApiGatewayClient.forecastConfigs.GET_SCENARIOS,
        method: 'GET',
      }),
      providesTags: (res) =>
        providesList<ForecastScenario[], typeof TAG_FORECAST_SCENARIOS>(
          res,
          TAG_FORECAST_SCENARIOS,
        ),
    }),
    [GET_FORECAST_REGIONS]: builder.query<ForecastRegion[], void>({
      query: () => ({
        url: actApiGatewayClient.forecastConfigs.GET_REGIONS,
        method: 'GET',
      }),
      providesTags: (res) =>
        providesList<ForecastRegion[], typeof TAG_FORECAST_REGIONS>(res, TAG_FORECAST_REGIONS),
    }),
    [GET_FORECAST_UNITS]: builder.query<ForecastUnit[], void>({
      query: () => ({
        url: actApiGatewayClient.forecastConfigs.GET_FORECAST_UNITS,
        method: 'GET',
      }),
      providesTags: (res) =>
        providesList<ForecastUnit[], typeof TAG_FORECAST_UNITS>(res, TAG_FORECAST_UNITS),
    }),
    [GET_STRATEGY_BUILDER_OVERVIEW_VALUES]: builder.query<
      StrategyBuilderOverviewValuesData,
      StrategyBuilderOverviewValuesDto
    >({
      query: (data) => ({
        url: actApiGatewayClient.overviewValues.GET_VALUES,
        method: 'POST',
        data,
      }),
      providesTags: () => [{ type: TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES }],
    }),
  }),
})

export const {
  useGetStrategyBuilderStateQuery,
  useGetForecastRegionsQuery,
  useGetForecastScenariosQuery,
  useSetupStrategyBuilderMutation,
  useCreateForecastConfigMutation,
  useGetForecastUnitsQuery,
  useGetStrategyBuilderOverviewValuesQuery,
  useLazyGetStrategyBuilderOverviewValuesQuery,
} = strategyBuilderApiSlice

export default strategyBuilderApiSlice
