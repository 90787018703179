import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row, Space } from 'antd'

import CompleteProductModal from '@/organisms/CompleteProductModal'
import SupplierShareModal from '@/organisms/SupplierShareModal'

import completeIcon from '../../../assets/circle-complete.svg'
import Alert from '../../../atoms/Alert'
import Text from '../../../atoms/Text'

import FaqSection from './FaqSection'
import StepRequest from './StepRequest/StepRequest'
import StepShare from './StepShare/StepShare'
import classes from './SupplierOnboarding.module.less'
import useSupplierOnboarding from './hooks/useSupplierOnboarding'

const SupplierOnboarding = ({ step }: { step: 'STEP_1' | 'STEP_2' }): JSX.Element => {
  const { t } = useTranslation('common')

  const {
    welcomeTitle,
    user,
    factorRequest,
    whyShareTexts,
    endedOnboarding,
    goToCreatePage,
    onSubmit,
    onUpload,
    showShareModal,
    showProductCreatedModal,
    setShowProductCreatedModal,
    setShareProductLifecycleSteps,
    shareProductLifecycleSteps,
    totalProductEmissions,
    selectedProduct,
    productLifecycleEmissions,
    comment,
    setComment,
    fileList,
    isSubmitting,
    setShowShareModal,
  } = useSupplierOnboarding()

  return (
    <div className={classes.mainWrapper}>
      <Row gutter={[0, 10]}>
        <Col md={24}>
          <Text mode="title" size="xs" fontWeight="medium">
            {welcomeTitle(user?.firstName)}
          </Text>
        </Col>
        <Col md={24}>
          <Text className={classes.suppliersTitle} mode="title" size="sm" fontWeight="medium">
            <span>{t('onboarding.suppliers.titlePt1')}</span>
            <span className={classes.var}>{` ${
              factorRequest?.requester?.firstName
                ? factorRequest?.requester?.firstName
                : factorRequest?.requester.email
            } `}</span>
            <span>{t('onboarding.suppliers.titlePt2')}</span>
            <span className={classes.var}>{` ${factorRequest?.organization.name} `}</span>
            <span>{t('onboarding.suppliers.titlePt3')}</span>
          </Text>
        </Col>
        <Col md={24}>
          <Alert
            type="default"
            title={t('onboarding.suppliers.alert.title')}
            message={t('onboarding.suppliers.alert.text', {
              organization: factorRequest?.organization.name,
            })}
          />
        </Col>
      </Row>

      <div className={classes.container}>
        <Row gutter={[24, 14]}>
          <Col span={24}>
            <Row className={classes.reasonsSection}>
              <Col md={24}>
                <Text mode="title" size="xs" fontWeight="medium">
                  {t('onboarding.suppliers.reasons.title')}
                </Text>
              </Col>
              {whyShareTexts.map((step) => (
                <Col lg={8} md={24} key={step.title}>
                  <Space className={classes.stepWrapper}>
                    <img src={completeIcon} alt="alt" />
                    <div className={classes.stepTitleWrapper}>
                      <Text mode="title" size="xs" fontWeight="medium" color="default">
                        {step.title}
                      </Text>
                      <Text size="xl" color="secondary">
                        {step.subtitle}
                      </Text>
                    </div>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>
          <StepRequest
            step={step}
            endedOnboarding={endedOnboarding}
            shareProductLifecycleSteps={shareProductLifecycleSteps}
            showShareModal={showShareModal}
            factorRequest={factorRequest}
            totalProductEmissions={totalProductEmissions}
            goToCreatePage={goToCreatePage}
          />
          <StepShare
            step={step}
            endedOnboarding={endedOnboarding}
            onSubmit={onSubmit}
            shareProductLifecycleSteps={shareProductLifecycleSteps}
            setShareProductLifecycleSteps={setShareProductLifecycleSteps}
            showShareModal={showShareModal}
            totalProductEmissions={totalProductEmissions}
            selectedProduct={selectedProduct}
            productLifecycleEmissions={productLifecycleEmissions}
            factorRequest={factorRequest}
            comment={comment}
            setComment={setComment}
            fileList={fileList}
            onUpload={onUpload}
            isSubmitting={isSubmitting}
          />
        </Row>
        <SupplierShareModal visible={showShareModal} closeModal={() => setShowShareModal(false)} />
        <CompleteProductModal
          showModal={showProductCreatedModal}
          text={t('onboarding.suppliers.create-product-modal.text')}
          buttonText={t('onboarding.suppliers.create-product-modal.buttonText')}
          onClick={() => setShowProductCreatedModal(false)}
          onCancel={() => setShowProductCreatedModal(false)}
        />
        <FaqSection />
      </div>
    </div>
  )
}

export default SupplierOnboarding
