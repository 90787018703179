import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd'

import Modal from '@/molecules/Modal'

import Button from '@/atoms/Button'

import completeProductCreation from '@/assets/lifecycle-steps/complete.svg'

import classes from './CompleteProductModal.module.less'

interface CompleteProductModalProps {
  showModal: boolean
  text: string
  buttonText: string
  onCancel: (isVisible: boolean) => void
  onClick: (isVisible: boolean) => void
}

const CompleteProductModal = ({
  showModal,
  text,
  buttonText,
  onCancel,
  onClick,
}: CompleteProductModalProps): ReactElement => {
  return (
    <Modal
      bodyStyle={{ overflowY: 'unset', marginTop: '100px', marginBottom: '100px' }}
      className={classes.finishProductCreationModal}
      visible={showModal}
      hideFooter
      customHeader={<></>}
      onCancel={() => onCancel(false)}
    >
      <Row align="middle" justify="center">
        <Col span={24} className={classes.content}>
          <img src={completeProductCreation} className={classes.icon} alt="logo" />
        </Col>
        <Col span={10} className={`${classes.content} ${classes.subtitle}`}>
          {text}
        </Col>
        <Col span={24}>
          <Button
            category="products"
            action={'ok'}
            type="primary"
            data-cy="ok-modal-button"
            color={'blue'}
            className={classes.button}
            onClick={() => onClick(false)}
          >
            {buttonText}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default CompleteProductModal
