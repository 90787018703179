import React, { ReactElement, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineSparkles, HiPencilAlt, HiSaveAs, HiTrash } from 'react-icons/hi'

import { Dropdown, Menu, message } from 'antd/es'

import { groupBy, isEmpty, isEqual, omit } from 'lodash-es'

import { ConfigFilter, ConfigSorter, ConfigurationView, LogType } from '@cozero/models'

import AlertDialog from '@/molecules/AlertDialog'
import EditViewMenu from '@/molecules/EditViewMenu'

import Button from '@/atoms/Button'
import MenuItem from '@/atoms/Menu/MenuItem'

import { useFiltersContext } from '@/contexts/filters'
import { useAppSelector } from '@/redux'
import { getIsAdmin, selectUser, useLazyGetUserDataQuery } from '@/redux/auth'
import {
  PartialConfigurationViewWithId,
  useDeleteConfigurationViewMutation,
  useGetConfigurationViewsQuery,
  useSaveConfigurationViewMutation,
  useUpdateConfigurationViewMutation,
} from '@/redux/configViews/api'
import { truncate } from '@/utils/string'

import classes from './LogViewsMenu.module.less'

interface Props {
  logType: LogType
  buttonHeight?: number
}

const LogViewsMenu = ({ logType, buttonHeight }: Props): ReactElement => {
  const { t } = useTranslation()
  const user = useAppSelector(selectUser)
  const isAdmin = useAppSelector(getIsAdmin)
  const [getMe] = useLazyGetUserDataQuery()

  const { data: configurationViews } = useGetConfigurationViewsQuery()
  const [deleteConfigurationView] = useDeleteConfigurationViewMutation()
  const [updateConfigurationView] = useUpdateConfigurationViewMutation()
  const [saveConfigurationView] = useSaveConfigurationViewMutation()

  const { sorters, filters, saveConfig } = useFiltersContext()

  const [editing, setEditing] = useState<Partial<ConfigurationView> | undefined>(undefined)
  const [showConfirm, setShowConfirm] = useState<number | undefined>()
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)

  const groupedViews = useMemo(() => {
    if (configurationViews) {
      const filteredConfigurationViews = configurationViews?.filter(
        (view) => view.logType === logType,
      )
      return groupBy(filteredConfigurationViews ?? [], 'shared')
    }
  }, [configurationViews])

  const onViewClick = (view: Partial<ConfigurationView>): void => {
    saveConfig({
      filters: view.filters,
      sorters: view.sorters,
    })
  }

  const onDeleteView = async (id?: number): Promise<void> => {
    try {
      if (id) {
        await deleteConfigurationView(id)

        setShowConfirm(undefined)
        clearSettings()
        await getMe()
        return message.success(t('views.delete.successful'))
      }
    } catch (error) {
      return message.error(t('views.delete.failure'))
    }
  }

  const onUpdateView = async (view: PartialConfigurationViewWithId): Promise<void> => {
    try {
      await updateConfigurationView(view)
      await getMe()
      return message.success(t('views.update.success'))
    } catch (error) {
      return message.error(t('views.update.failure'))
    }
  }
  const onSaveAsView = async ({ title, shared }: Partial<ConfigurationView>): Promise<void> => {
    try {
      const view = await saveConfigurationView({
        title,
        shared,
        filters: filters?.map((obj) => omit(obj, 'options')) as ConfigFilter[],
        sorters: sorters as ConfigSorter[],
        logType,
      })

      if (view) {
        await getMe()
        return message.success(t('views.save.success'))
      }
    } catch (error) {
      return message.error(t('views.save.failure'))
    }
  }

  const onSubmit = (): void => {
    setIsDropdownVisible(false)
    const currentEditing = editing

    if (currentEditing) {
      const currentEditingId = currentEditing.id
      if (currentEditingId) {
        onUpdateView({ ...currentEditing, id: currentEditingId })
      } else {
        onSaveAsView(editing)
      }
      return setEditing(undefined)
    }
  }

  const onCancel = (): void => {
    setIsDropdownVisible(false)
    return setEditing(undefined)
  }

  const startEditing = (): void => setEditing({ title: '', shared: false })

  const clearSettings = (): void => {
    setIsDropdownVisible(false)
    saveConfig({ filters: [], sorters: [] })
  }

  const renderMenuItems = (views: ConfigurationView[]): ReactElement[] => {
    return views.map((item) => {
      const { id, title, owner, sorters, filters, shared } = item
      const active: boolean = isEqual(
        { sorters: sorters, filters: filters },
        { sorters: sorters, filters: filters },
      )

      return (
        <MenuItem
          key={id}
          className={`${classes.menuItem} ${active ? classes.active : ''}`}
          onClick={() =>
            onViewClick({ sorters: sorters as ConfigSorter[], filters: filters as ConfigFilter[] })
          }
          category="view"
          action={item.title as string}
        >
          <div className={classes.menuItemInner}>
            <span>{truncate(title || '', 25)}</span>
            {owner?.id === user?.id && (
              <div className={classes.menuItemActions}>
                <HiPencilAlt
                  onClick={() => setEditing({ title, id, shared })}
                  title={t('views.update.title')}
                />
                <HiTrash
                  className={classes.danger}
                  onClick={() => setShowConfirm(id)}
                  title={t('views.delete.title')}
                />
              </div>
            )}
          </div>
        </MenuItem>
      )
    })
  }

  const Overlay = (): JSX.Element => {
    return (
      <Menu className={classes.menu}>
        <MenuItem
          className={classes.menuItem}
          icon={<HiSaveAs size="18" />}
          onClick={startEditing}
          disabled={isEmpty(filters) && isEmpty(sorters)}
          category="view"
          action="edit"
        >
          {t('views.save-single')}
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          icon={<HiOutlineSparkles size="18" />}
          onClick={clearSettings}
          category="view"
          action="clear"
        >
          {t('views.default')}
        </MenuItem>
        {!isEmpty(groupedViews) && <Menu.Divider />}
        {groupedViews?.false && (
          <Menu.ItemGroup title={t('views.user')}>
            {renderMenuItems(groupedViews.false)}
          </Menu.ItemGroup>
        )}
        {groupedViews?.true && (
          <Menu.ItemGroup title={t('views.shared')}>
            {renderMenuItems(groupedViews.true)}
          </Menu.ItemGroup>
        )}
      </Menu>
    )
  }

  return (
    <>
      <Dropdown
        open={isDropdownVisible}
        overlay={
          editing !== undefined ? (
            <EditViewMenu
              view={editing}
              onChange={setEditing}
              onSubmit={onSubmit}
              onCancel={onCancel}
              userIsAdmin={isAdmin}
            />
          ) : (
            <Overlay />
          )
        }
        trigger={[editing ? 'click' : 'hover']}
        onVisibleChange={(val) => {
          setIsDropdownVisible(val)
          if (!val) {
            setEditing(undefined)
          }
        }}
      >
        <Button
          action="open-log-view-menu"
          style={buttonHeight ? { height: buttonHeight } : {}}
          category="view"
        >
          {t('views.title_plural')}
        </Button>
      </Dropdown>
      <AlertDialog
        visible={!!showConfirm}
        onOk={() => onDeleteView(showConfirm)}
        onClose={() => setShowConfirm(undefined)}
        message={t('actions.delete_item.confirm', { item: t('views.title') })}
      />
    </>
  )
}

export default LogViewsMenu
