import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Col, Row } from 'antd'

import _ from 'lodash'
import moment from 'moment'

import { GroupedMethodologyChangelog } from '@cozero/models'

import InfoBanner from '@/pages/Log/Factors/CalculationView/InfoBanner'
import LifecycleModalTitle from '@/pages/Organization/NewProduct/LifecycleModalTitle'

import CustomHeader from '@/organisms/CustomModalHeader'
import ImpactedClosedPeriodsTable from '@/organisms/ImpactedClosedPeriodsTable'
import OrganisationImpactedLogsTable from '@/organisms/OrganisationImpactedLogsTable'
import OrganisationImpactedProductsTable from '@/organisms/OrganisationImpactedProductsTable'
import OverviewRow from '@/organisms/OverviewRow'

import LayoutFooter from '@/molecules/LayoutFooter'
import { OverviewCardProps } from '@/molecules/OverviewCard'

import HighlightValue from '@/atoms/HighlightValue'
import Pill from '@/atoms/Pill'

import { useAppSelector } from '@/redux'
import { selectLocale } from '@/redux/auth'
import { selectCalculationChangelogRecord } from '@/redux/methodologyChangelogs'
import { languages } from '@/utils/config'
import { formatNumber } from '@/utils/number'
import { getChangeOfEmissions } from '@/utils/recalculations'

import classes from './CalculationView.module.less'

interface ContentData {
  oldEmissions: number
  newEmissions: number
  percentageChange: number
  years: number[]
  title: string
}

const CalculationView = (): ReactElement => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const selectedCalculationChangelogRecord = useAppSelector(selectCalculationChangelogRecord)
  const locale = useAppSelector(selectLocale)

  const generateContentData = useCallback(
    (
      selectedCalculationChangelogRecord: GroupedMethodologyChangelog | undefined,
      type: 'organization' | 'product',
    ): ContentData | undefined => {
      if (!selectedCalculationChangelogRecord) {
        return
      }
      const { oldEmissions, newEmissions, percentageChange, years } = getChangeOfEmissions(
        selectedCalculationChangelogRecord,
        type,
      )
      if (oldEmissions && newEmissions) {
        return {
          oldEmissions,
          newEmissions,
          percentageChange,
          years,
          title: t(`calculation-changes.impact-modal.${type}-logs-card-title`),
        } as ContentData
      }
    },
    [],
  )

  const title = useMemo(() => {
    if (selectedCalculationChangelogRecord) {
      const subcategoryName = selectedCalculationChangelogRecord?.subcategory.name
      const changelogChangeSource =
        selectedCalculationChangelogRecord?.methodologyChangelogGlobal.changeSource
      const createdDate = moment(selectedCalculationChangelogRecord?.createdAt)
        .locale(locale ?? languages[0].id)
        .format('ll')
      return `${subcategoryName} • ${t(
        `calculation-changes.changes.${changelogChangeSource}`,
      )} • ${createdDate}`
    }
    return ''
  }, [selectedCalculationChangelogRecord])

  const navigateBack = useCallback((): void => {
    navigate(-1)
  }, [])

  const overviewData: OverviewCardProps[] = useMemo(() => {
    const organizationContentData = generateContentData(
      selectedCalculationChangelogRecord,
      'organization',
    )
    const productContentData = generateContentData(selectedCalculationChangelogRecord, 'product')
    const contentData = _.compact([organizationContentData, productContentData])

    return contentData.map(({ oldEmissions, newEmissions, percentageChange, years, title }) => ({
      content: (
        <div>
          <Row align={'middle'} gutter={12}>
            <Col>
              <HighlightValue striked value={formatNumber(oldEmissions)} />
            </Col>
            <Col>
              <HighlightValue value="→" />
            </Col>
            <Col>
              <HighlightValue value={formatNumber(newEmissions)} unit={t('co2-tonnes')} />
            </Col>
          </Row>
          <Row>
            <Pill color={percentageChange <= 0 ? 'green' : 'orange'}>
              <strong>{percentageChange < 0 ? '-' : percentageChange > 0 ? '+' : ''}</strong>
              <strong>{formatNumber(Math.abs(percentageChange))}%</strong>
              <span>
                {' '}
                {t('targets.in')} {years.join(', ')}
              </span>
            </Pill>
          </Row>
        </div>
      ),
      headerTitle: title,
    }))
  }, [selectedCalculationChangelogRecord])

  return (
    <>
      <div className={classes.sider}></div>
      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <CustomHeader
            showArrow={true}
            title={t('calculation-changes.impact-modal.title')}
            onClose={navigateBack}
            goBack={navigateBack}
          />
        </div>
        <Row className={classes.content}>
          <LifecycleModalTitle
            image={selectedCalculationChangelogRecord?.subcategory?.category?.image?.url ?? ''}
            title={title}
            description={
              <>
                {t('calculation-changes.impact-modal.subtitle-first')}
                <br />
                {t('calculation-changes.impact-modal.subtitle-second')}
              </>
            }
            fitParent={true}
          />
          {selectedCalculationChangelogRecord?.methodologyChangelogGlobal
            .changeReasonDescription && (
            <InfoBanner
              description={
                selectedCalculationChangelogRecord?.methodologyChangelogGlobal
                  .changeReasonDescription as string
              }
            />
          )}
          <Col span={24}>
            <OverviewRow
              loadingOverview={false}
              marginBetween={14}
              overviewData={overviewData}
              hideTitle={true}
            />
          </Col>
          <OrganisationImpactedLogsTable />
          <OrganisationImpactedProductsTable />
          <ImpactedClosedPeriodsTable />
        </Row>
        <LayoutFooter
          hideCancelButton
          categoryOkButton="factor-requests"
          isDataValid={true}
          onCancelClick={navigateBack}
          onSaveClick={navigateBack}
          saveButtonDisabled={false}
          categoryCancelButton="calculations-changes"
          saveButtonText={t('calculation-changes.impact-modal.done-btn')}
        />
      </div>
    </>
  )
}

export default CalculationView
