import { ConfigurationView } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

import {
  DELETE_CONFIGURATION_VIEW,
  GET_CONFIGURATION_VIEWS,
  SAVE_CONFIGURATION_VIEW,
  TAG_CONFIGURATION_VIEWS,
  UPDATE_CONFIGURATION_VIEW,
} from './constants'

export type PartialConfigurationViewWithId = Partial<ConfigurationView> &
  Pick<ConfigurationView, 'id'>

const configViewsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_CONFIGURATION_VIEWS]: builder.query<ConfigurationView[], void>({
      providesTags: [TAG_CONFIGURATION_VIEWS],
      query: () => ({
        url: centralApiGatewayClient.configViews.GET_MANY,
        method: 'GET',
      }),
    }),
    [SAVE_CONFIGURATION_VIEW]: builder.mutation<ConfigurationView, Partial<ConfigurationView>>({
      invalidatesTags: [TAG_CONFIGURATION_VIEWS],
      query: (data) => ({
        url: centralApiGatewayClient.configViews.CREATE,
        method: 'POST',
        data,
      }),
    }),
    [DELETE_CONFIGURATION_VIEW]: builder.mutation<void, number>({
      invalidatesTags: [TAG_CONFIGURATION_VIEWS],
      query: (viewId) => ({
        url: centralApiGatewayClient.configViews.DELETE_ONE.replace(':id', viewId.toString()),
        method: 'DELETE',
      }),
    }),
    [UPDATE_CONFIGURATION_VIEW]: builder.mutation<
      ConfigurationView,
      PartialConfigurationViewWithId
    >({
      invalidatesTags: [TAG_CONFIGURATION_VIEWS],
      query: (updatedView) => ({
        url: centralApiGatewayClient.configViews.UPDATE_ONE.replace(
          ':id',
          updatedView.id.toString(),
        ),
        method: 'PUT',
        data: updatedView,
      }),
    }),
  }),
})

export const {
  useGetConfigurationViewsQuery,
  useDeleteConfigurationViewMutation,
  useSaveConfigurationViewMutation,
  useUpdateConfigurationViewMutation,
} = configViewsSlice

export default configViewsSlice
