import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'

import { ClosedPeriod, Log } from '@cozero/models'

import { useClosedPeriod } from '@/hooks/useClosedPeriod'
import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import {
  selectCalculationChangelogRecord,
  useFindChangelogLogsQuery,
} from '@/redux/methodologyChangelogs'

interface IUseImpactedLogs {
  mappedLogs: ILogTableContent[]
  closedPeriods: ClosedPeriod[]
  logsPage: number
  logsPageSize: number
  setLogPage: Dispatch<SetStateAction<number>>
  setLogsPageSize: Dispatch<SetStateAction<number>>
  closedPeriodsPage: number
  closedPeriodsPageSize: number
  setClosedPeriodsPage: Dispatch<SetStateAction<number>>
  setClosedPeriodsPageSize: Dispatch<SetStateAction<number>>
  loadingLogs: boolean
  loadingClosedPeriodLogs: boolean
  totalLogs: number
  totalClosedLogs: number
}

export interface ILogTableContent {
  id: number
  categoryName: string
  description: string | null
  locationName: string
  businessUnitName: string
  startDate: Date | null
  endDate: Date | null
  affectedEmissionSum: number | undefined
}

const useImpactedLogs = (): IUseImpactedLogs => {
  const selectedCalculationChangelogRecord = useAppSelector(selectCalculationChangelogRecord)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const { id } = useParams()
  const [logsPage, setLogsPage] = useState(1)
  const [logsPageSize, setLogsPageSize] = useState(10)
  const [closedPeriodsPage, setClosedPeriodsPage] = useState(1)
  const [closedPeriodsPageSize, setClosedPeriodsPageSize] = useState(10)
  const [closedPeriods, setClosedPeriods] = useState<ClosedPeriod[]>([])
  const { getManyClosedPeriods } = useClosedPeriod()
  const { data: { data: logs = [], total: totalLogs = 0 } = {}, isLoading: loadingLogs } =
    useFindChangelogLogsQuery(
      {
        businessUnitId: selectedBusinessUnit?.id,
        changelogIds: selectedCalculationChangelogRecord?.ids ?? [],
        globalChangelogId: selectedCalculationChangelogRecord?.methodologyChangelogGlobalId,
        page: logsPage,
        pageSize: logsPageSize,
      },
      { skip: !selectedBusinessUnit },
    )
  const {
    data: { data: closedPeriodLogs, total: totalClosedLogs } = { data: [], total: 0 },
    isLoading: loadingClosedPeriodLogs,
  } = useFindChangelogLogsQuery(
    {
      businessUnitId: selectedBusinessUnit?.id,
      changelogIds: selectedCalculationChangelogRecord?.ids ?? [],
      globalChangelogId: selectedCalculationChangelogRecord?.methodologyChangelogGlobalId,
      page: logsPage,
      pageSize: logsPageSize,
      isClosedPeriod: true,
    },
    { skip: !selectedBusinessUnit },
  )

  const mappedLogs: ILogTableContent[] = useMemo(() => {
    return (
      (logs.map((log: Log) => {
        const { category, description, location, startDate, endDate, affectedEmissionSum, id } = log
        const businessUnitName = selectedBusinessUnit?.title ?? ''
        const locationName = location?.name ?? ''
        return {
          id,
          categoryName: category.name,
          description,
          locationName,
          businessUnitName,
          startDate,
          endDate,
          affectedEmissionSum,
        }
      }) as ILogTableContent[]) || []
    )
  }, [logs])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (closedPeriodLogs?.length > 0) {
        const closedPeriodIds = closedPeriodLogs
          .map((item) => item.closedPeriodId)
          .filter((id) => id !== null) as number[]

        if (closedPeriodIds.length > 0) {
          const result = await getManyClosedPeriods(
            closedPeriodsPage,
            closedPeriodsPageSize,
            undefined,
            closedPeriodIds,
          )

          if (result.docs) {
            setClosedPeriods(result.docs)
          }
        }
      }
    }
    fetchData()
  }, [closedPeriodLogs])

  return {
    mappedLogs,
    logsPage,
    logsPageSize,
    setLogPage: setLogsPage,
    setLogsPageSize,
    loadingLogs,
    loadingClosedPeriodLogs,
    closedPeriods,
    setClosedPeriodsPage,
    setClosedPeriodsPageSize,
    closedPeriodsPage,
    closedPeriodsPageSize,
    totalLogs,
    totalClosedLogs,
  }
}

export default useImpactedLogs
