export enum CalculationMethodType {
  //deprecated
  Consumption = 'consumption',
  ConsumptionGridMix = 'consumption_grid_mix',
  ConsumptionFugitives = 'consumption_fugitives',
  ConsumptionHomeOffice = 'consumption_home_office',
  Direct = 'direct',
  Distance = 'distance',
  DistanceRouting = 'distance_routing',
  DistanceLogisticsRouting = 'distance_logistics_routing',
  DistanceLogistics = 'distance_logistics',
  DistancePerJourney = 'distance_per_journey',
  DistancePerJourneyRouting = 'distance_per_journey_routing',
  EcotransitTruckDirect = 'truck_direct_ecotransit',
  EcotransitTrainDirect = 'train_direct_ecotransit',
  EcotransitInlandDirect = 'inland_direct_ecotransit',
  EcotransitSeashipDirect = 'seaship_direct_ecotransit',
  EcotransitAirplaneDirect = 'airplane_direct_ecotransit',
  EcotransitLogisticDirect = 'logistic_direct_ecotransit',
  GridMix = 'grid_mix',
  Journeys = 'number_of_journeys',
  Flights = 'number_of_flights',
  LogisticsArea = 'logistics_area',
  MarketingSize = 'marketing_size',
  MarketingPaper = 'marketing_paper',
  MarketingTime = 'marketing_time',
  QuantityOfGoods = 'quantity_of_goods',
  QuantityOfGoodsCapitalGoods = 'quantity_of_goods_capital_goods',
  QuantityOfGoodsTransported = 'quantity_of_goods_transported',
  QuantityOfGoodsRouting = 'quantity_of_goods_transported_routing',
  DigitalProductWebsiteSize = 'products_digital_website_size',
  DigitalProductSizeViewTime = 'products_digital_size_viewtime_total',
  DigitalProductVideoSize = 'products_digital_video_size',
  MediaAudiovisual = 'media_audiovisual',
  DoohArea = 'dooh_area',
  DoohDimensions = 'dooh_dimensions',
  PaperDimensions = 'paper_dimensions',
  PaperStandardSize = 'paper_standard_size',
  PaperWeight = 'paper_weight',
  Spend = 'spend',
  Duration = 'duration',
  UsesProduct = 'uses_product',
  VehicleUse = 'vehicle_use',
  PercentageProductQuantity = 'percentage_product_quantity',
  IroningClothes = 'ironing_clothes',
  WarehouseStorage = 'warehouse_storage',
  CleaningClothes = 'cleaning_clothes',
  DryingClothes = 'drying_clothes',
  ConsumerTransport = 'consumer_transport',
  RetailStorage = 'retail_storage',
}

export enum StandardUnit {
  CO2Tonnes = 'CO2e tonne',
  Tonnes = 'tonne',
  Kilometers = 'km',
  Miles = 'mile',
  Days = 'day',
  Years = 'year',
  Kilograms = 'kg',
  TonneKm = 'tonne km',
  TonneMile = 'ton mile',
  KilowattHours = 'kWh',
  Hours = 'hour',
  Journeys = 'journey',
  Units = 'unit',
  EUR = 'EUR',
  Meters = 'm',
  CubicMeters = 'm3',
  SquaredMeters = 'm2',
  Seconds = 'second',
  Months = 'month',
  Times = 'time',
  Percentage = '%',
  Density = 't/m2',
  Mb = 'mb',
  Kb = 'kb',
}

export enum Scope {
  FuelAndEnergy = 3,
  Direct = 16,
  OutOfScope = 18,
}

export enum ActivityDataSource {
  defaultOther = 'default-other',
}

export enum Countries {
  Germany = 'DEU',
  France = 'FRA',
  UnitedKingdom = 'GBR',
  USA = 'USA',
}

export enum Continents {
  Global = 'GLO',
  Europe = 'EEU',
  Asia = 'ABB',
  Africa = 'FFF',
  NorthAmerica = 'NNN',
  SouthAmerica = 'SRR',
  Oceania = 'UUU',
}

export enum Origins {
  Average = 'calculated-average',
}

export enum ClosedPeriodStatus {
  DRAFT = 'draft',
  CLOSED = 'closed',
}

export enum CalculationExternalValues {
  FILE_SIZE_FOR_BUFFERING = 5.625,
  INK_PRINTING = 0.001892,
}

export enum OperatingLeakage {
  'domestic-refrigeration' = 0.0003,
  'small-hermetic-stand-alone-refrigeration-units' = 0.0075,
  'condensing-units' = 0.5,
  'centralised-supermarket-refrigeration-systems' = 184.5,
  'land-transport-refrigeration' = 0.6,
  'marine-transport-refrigeration' = 600,
  'industrial-systems' = 5.2,
  'small-stationary-air-conditioning' = 0.045,
  'medium-stationary-air-conditioning' = 0.9,
  'large-stationary-air-conditioning-chillers' = 5.4,
  'heat-pumps' = 0.18,
  'light-duty-mobile-air-conditioning' = 0.073,
  'other-mobile-air-conditioning' = 0.4,
}

export enum InstalationLeakage {
  'domestic-refrigeration' = 0.0006,
  'small-hermetic-stand-alone-refrigeration-units' = 0.005,
  'condensing-units' = 0.1,
  'centralised-supermarket-refrigeration-systems' = 20.5,
  'land-transport-refrigeration' = 0.008,
  'marine-transport-refrigeration' = 15,
  'industrial-systems' = 0.65,
  'small-stationary-air-conditioning' = 0.0075,
  'medium-stationary-air-conditioning' = 0.15,
  'large-stationary-air-conditioning-chillers' = 0.9,
  'heat-pumps' = 0.03,
  'light-duty-mobile-air-conditioning' = 0.00365,
  'other-mobile-air-conditioning' = 0.02,
}

export enum DisposalLeakage {
  'domestic-refrigeration' = 0.035,
  'small-hermetic-stand-alone-refrigeration-units' = 0.2,
  'condensing-units' = 0.75,
  'centralised-supermarket-refrigeration-systems' = 82,
  'land-transport-refrigeration' = 0.8,
  'marine-transport-refrigeration' = 450,
  'industrial-systems' = 9.75,
  'small-stationary-air-conditioning' = 0.45,
  'medium-stationary-air-conditioning' = 4.5,
  'large-stationary-air-conditioning-chillers' = 36,
  'heat-pumps' = 1.05,
  'light-duty-mobile-air-conditioning' = 0.219,
  'other-mobile-air-conditioning' = 1.2,
}
