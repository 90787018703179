/*
 * StepRequest.tsx
 *
 * Code for the first step of the supplier onboarding process. This step is where the supplier
 * sees all the data that is requested by their customer.
 */
import React, { useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'

import { Col, Input, Row, Switch, UploadProps } from 'antd'

import moment from 'moment'

import { FactorRequest, FactorRequestStatus, Product } from '@cozero/models'

import Avatar from '@/atoms/Avatar'
import Button from '@/atoms/Button'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'

import placeholderImage from '@/assets/placeholder-image.png'

import ActionCard from '../ActionCard'
import classes from '../SupplierOnboarding.module.less'

interface IStepRequest {
  step: 'STEP_1' | 'STEP_2'
  endedOnboarding: boolean
  shareProductLifecycleSteps: boolean
  showShareModal: boolean
  factorRequest: FactorRequest | undefined
  totalProductEmissions: number | undefined
  goToCreatePage: () => Promise<void>
}

const StepRequest = ({
  step,
  endedOnboarding,
  factorRequest,
  totalProductEmissions,
  goToCreatePage,
}: IStepRequest): JSX.Element => {
  const { t } = useTranslation('common')

  const status = endedOnboarding
    ? t('onboarding.suppliers.done')
    : step !== 'STEP_1'
    ? t('onboarding.suppliers.in-progress')
    : t('onboarding.suppliers.todo')

  return (
    <Col span={24}>
      <Row>
        <Col span={24}>
          <ActionCard
            active={status === t('onboarding.suppliers.todo')}
            title={t('onboarding.suppliers.steps.request.title')}
            text={t('onboarding.suppliers.steps.request.text')}
            status={status}
            stepNumber={1}
          >
            <>
              <Row className={classes.generalInfo}>
                <Col offset={1} span={10}>
                  <Row className={classes.propertyWrapper} align="middle">
                    <Col span={6}>
                      <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                        {t('onboarding.suppliers.steps.request.fields.product')}
                      </Text>
                    </Col>
                    <Col span={18} className={classes.tagWrapper}>
                      <Tag
                        truncateText
                        size="sm"
                        type={factorRequest?.activityDataSource?.name ? 'default' : 'danger'}
                      >
                        {factorRequest?.activityDataSource?.name?.toString() ??
                          t('onboarding.suppliers.missing')}
                      </Tag>
                    </Col>
                  </Row>
                  <Row className={classes.propertyWrapper}>
                    <Col span={6}>
                      <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                        {t('onboarding.suppliers.steps.request.fields.emissions')}
                      </Text>
                    </Col>
                    <Col span={18} className={classes.tagWrapper}>
                      <Tag size="sm">
                        <>
                          {(factorRequest?.value ?? totalProductEmissions ?? '--') + ' '} tCO₂e/
                          {factorRequest?.unit.name}
                        </>
                      </Tag>
                      {!factorRequest?.value && !totalProductEmissions && (
                        <Tag truncateText size="sm" type="danger">
                          {t('onboarding.suppliers.missing')}
                        </Tag>
                      )}
                    </Col>
                  </Row>
                  <Row className={classes.propertyWrapper}>
                    <Col span={6}>
                      <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                        {t('onboarding.suppliers.steps.request.fields.dueDate')}
                      </Text>
                    </Col>
                    <Col span={18} className={classes.tagWrapper}>
                      <Tag type="danger" truncateText size="sm">
                        {moment(factorRequest?.dueDate).format('MMM DD, YYYY') !== 'Invalid date'
                          ? moment(factorRequest?.dueDate).format('MMM DD, YYYY')
                          : '--'}
                      </Tag>
                    </Col>
                  </Row>
                </Col>
                <Col span={13}>
                  <Row className={classes.propertyWrapper}>
                    <Col>
                      <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                        {t('onboarding.suppliers.steps.request.fields.customer')}
                      </Text>
                    </Col>
                    <Col offset={1} span={20}>
                      <Row>
                        <Col>
                          <Avatar
                            image={
                              factorRequest?.requester?.photo?.url
                                ? { url: factorRequest?.requester?.photo?.url }
                                : placeholderImage
                            }
                            size="xs"
                          />
                        </Col>
                        <Col span={22}>
                          <Text className={classes.customerInfo}>
                            {`${
                              factorRequest?.requester?.firstName
                                ? factorRequest?.requester?.firstName
                                : factorRequest?.requester.email
                            } ${factorRequest?.requester?.lastName ?? ''} `}
                            by {factorRequest?.organization.name}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={classes.propertyWrapper}>
                    <Col>
                      <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                        {t('onboarding.suppliers.steps.request.fields.message')}
                      </Text>
                    </Col>
                    <Col offset={1} span={20}>
                      <Text>{factorRequest?.note}</Text>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row justify="end" align="middle">
                <Col>
                  <Button
                    category="supplier"
                    action="supplierToPcf"
                    type={!endedOnboarding && step !== 'STEP_1' ? 'secondary' : 'primary'}
                    key="createPcf"
                    disabled={endedOnboarding}
                    onClick={() => {
                      goToCreatePage()
                    }}
                    className={classes.calculateBtn}
                  >
                    {t('onboarding.suppliers.steps.request.button')}
                  </Button>
                </Col>
              </Row>
            </>
          </ActionCard>
        </Col>
      </Row>
    </Col>
  )
}

export default StepRequest
