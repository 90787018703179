import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiDotsHorizontal, HiOutlineLogout } from 'react-icons/hi'
import { useNavigate } from 'react-router'
import { useIntercom } from 'react-use-intercom'

import { Col, Dropdown, Menu, Row } from 'antd/es'

import { routes } from '@cozero/utils'

import Avatar from '@/atoms/Avatar'
import MenuItem from '@/atoms/Menu/MenuItem'
import Text from '@/atoms/Text'

import useSWRClearCache from '@/hooks/useSWRClearCache'
import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin, selectUser } from '@/redux/auth'

import classes from './NavUserMenu.module.less'

const UserMenu = ({
  isManagerOrAdmin,
  onLogoutHandler,
}: {
  isManagerOrAdmin: boolean
  onLogoutHandler: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { show } = useIntercom()
  return (
    <Menu className={classes.menu}>
      <MenuItem
        category="navigation-sidebar"
        action="profile"
        key="profile"
        className={classes.menuItem}
        onClick={() => navigate(routes.settings.profile)}
      >
        {t('nav.profile')}
      </MenuItem>
      {isManagerOrAdmin && (
        <MenuItem
          category="navigation-sidebar"
          action="organization"
          key="admin"
          className={classes.menuItem}
          onClick={() => navigate(routes.settings.organization)}
          data-cy="select-settings"
        >
          {t('nav.settings')}
        </MenuItem>
      )}

      <MenuItem
        key="wiki"
        className={classes.menuItem}
        onClick={show}
        category="navigation-sidebar"
        action="wiki"
      >
        {t('nav.wiki')}
      </MenuItem>
      <Menu.Divider />
      <MenuItem
        category="navigation-sidebar"
        action="logout"
        key="logout"
        icon={<HiOutlineLogout />}
        className={`${classes.menuItem} ${classes.danger}`}
        onClick={onLogoutHandler}
      >
        {t('nav.sign-out')}
      </MenuItem>
    </Menu>
  )
}

const NavUserMenu = (): JSX.Element => {
  const navigate = useNavigate()
  const clearCache = useSWRClearCache()
  const user = useAppSelector(selectUser)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)

  const _handleLogout = (): void => {
    clearCache()
    navigate(routes.logout)
  }

  return (
    <Row align="middle" gutter={16} data-cy="user-name-display">
      <Col>
        <Avatar
          firstName={user?.firstName ?? ''}
          lastName={user?.lastName ?? ''}
          image={user?.photo}
          showTooltip={false}
          outlined
        />
      </Col>
      <Col span={13}>
        <div className={classes.text}>
          <Text color="white" size="xl" fontWeight="medium" ellipsis>
            {user?.firstName ?? ''} {user?.lastName ?? ''}
          </Text>
          <Text color="secondary" ellipsis>
            {user?.email ?? ''}
          </Text>
        </div>
      </Col>
      <Col span={6}>
        <div className={classes.menuContainer} data-cy="3dot-user-menu">
          <Dropdown
            overlay={
              <UserMenu isManagerOrAdmin={isManagerOrAdmin} onLogoutHandler={_handleLogout} />
            }
            placement="topRight"
          >
            <span className={classes.iconWrapper}>
              <HiDotsHorizontal className={classes.icon} />
            </span>
          </Dropdown>
        </div>
      </Col>
    </Row>
  )
}

export default NavUserMenu
