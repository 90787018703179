import React, { MutableRefObject, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FacetOptions, Options, Plot } from '@antv/g2plot'
import { ChartPivotRow, SeriesNamesColumn } from '@cubejs-client/core'
import { FacetChart } from '@opd/g2plot-react'
import truncate from 'lodash/truncate'
import moment from 'moment'

import { ReportType } from '@cozero/models'

import useGraphs from '@/hooks/useGraphs'
import i18n from '@/i18n'
import {
  axisTextStyle,
  columnStyles,
  legendStyles,
  xAxisStyles,
  yAxisStyles,
} from '@/styles/graphs'
import { formatNumber } from '@/utils/number'

const FacetStackedBar = React.memo(
  ({
    height,
    width,
    chartData,
    keys,
    visible,
    switchAxis,
    dimensionType,
    showLegend,
  }: {
    height: number | null
    width: number | null
    chartData: ChartPivotRow[]
    keys: SeriesNamesColumn[]
    visible: boolean
    switchAxis: boolean
    dimensionType?: string
    showLegend: boolean
  }): ReactElement | null => {
    const { graphStyles, customTooltip } = useGraphs({
      graphType: ReportType.FACET_STACKED_BAR,
      dimensionType,
    })
    const [facetProps, setFacetProps] = useState<FacetOptions>()
    const { t } = useTranslation('common')

    useEffect(() => {
      const props: FacetOptions = {
        eachView: (innerView, facet) => {
          return {
            type: 'column',
            options: {
              yField: 'value',
              autoFit: true,
              ...graphStyles,
              data: facet?.data ?? [],
              xField: switchAxis ? 'type' : 'x',
              seriesField: switchAxis ? 'x' : 'type',
              isStack: true,
              columnStyle: columnStyles,
              xAxis: {
                label: {
                  formatter: (item) =>
                    moment(item).isValid() && item.match(/^\d/)
                      ? moment(item).format('YYYY')
                      : truncate(item, { length: 24 }),
                  autoHide: true,
                  autoRotate: false,
                  style: {
                    ...xAxisStyles.label?.style,
                  },
                },
              },
              yAxis: {
                ...yAxisStyles,
                title: {
                  style: yAxisStyles.title?.style,
                  text: i18n.exists(`common:reports.legend.${keys?.[0].key.split(',')?.at(-1)}`)
                    ? t(`reports.legend.${keys?.[0].key.split(',')?.at(-1)}`)
                    : i18n.exists(`common:reports.legend.${keys?.[0].key}`)
                    ? t(`reports.legend.${keys?.[0].key}`)
                    : t('tonnes-emitted'),
                },
              },
            },
          }
        },
        appendPadding: [20, 20, 10, 60],
        scrollbar: true,
        slider: true,
        meta: {
          x: {
            sync: true,
          },
          type: {
            sync: true,
          },
          value: {
            sync: true,
          },
        },
        data: chartData
          .map((datum) =>
            keys
              .filter(
                (x) =>
                  x.key.split(',').includes(`${datum.xValues[1]}`) &&
                  x.key.split(',').includes(`${datum.xValues[2]}`),
              )
              .map((key) => ({
                split: key.yValues[0], // REgion
                x: datum.xValues.at(0), // DAte
                value: datum[key.yValues.at(-1) ?? ''], // number
                type: datum.xValues.at(-1), // SCOPE
              })),
          )
          .flat(),
        legend: showLegend
          ? {
              ...legendStyles,
              slidable: true,
              label: {
                formatter: (item: string | number | null) => {
                  return `${
                    moment(item, moment.ISO_8601, true).isValid() ? moment(item).year() : item
                  }`
                },
              },
              itemName: {
                style: legendStyles.itemName?.style,
                formatter: (item: string | number | null) => {
                  return `${
                    moment(item, moment.ISO_8601, true).isValid() ? moment(item).year() : item
                  }`
                },
              },
            }
          : false,
        columnTitle: {
          style: axisTextStyle,
          formatter: (title) => truncate(title, { length: 15 }),
        },
        tooltip: {
          domStyles: customTooltip.domStyles,
          title: (title) => {
            return `${
              moment(title, moment.ISO_8601, true).isValid() ? moment(title).year() : title
            }`
          },
          customItems: (items) => {
            return items.map(({ data, ...rest }) => {
              let name = data.type || data.x
              if (switchAxis) {
                name = data.x
              }
              return {
                ...rest,
                data,
                name: `${
                  moment(name, moment.ISO_8601, true).isValid() ? moment(name).year() : name
                }`,
                value: `${formatNumber(data.value)} ${
                  i18n.exists(`common:reports.legend.${keys[0].key}`)
                    ? t(`reports.legend.${keys[0].key}`)
                    : t('tonnes-emitted')
                }`,
              }
            })
          },
          customContent: customTooltip.customContent,
          formatter: (item) => {
            let x = item.x
            let name = item.type || item.x
            if (switchAxis) {
              x = item.type
              name = item.x
            }
            return {
              name: `${moment(name, moment.ISO_8601, true).isValid() ? moment(name).year() : name}`,
              value: `${formatNumber(item.value)} ${
                i18n.exists(`common:reports.legend.${keys[0].key}`)
                  ? t(`reports.legend.${keys[0].key}`)
                  : t('tonnes-emitted')
              }`,
              title: `${moment(x, moment.ISO_8601, true).isValid() ? moment(x).year() : x}`,
            }
          },
        },
        type: 'rect',
        fields: ['split'],
      }
      setFacetProps(props)
    }, [chartData, switchAxis, dimensionType, showLegend, graphStyles])

    if (!height) {
      return null
    }
    if (!facetProps) {
      return <div />
    }

    const minWidth = Array.from(new Set(keys.flatMap((key) => key.yValues[0]))).length * 200

    return (
      <div style={{ width: '100%', overflow: 'scroll' }}>
        <div style={{ width: minWidth > (width ?? 0) ? minWidth : width ?? '100%' }}>
          <FacetChart
            height={height}
            {...(facetProps as FacetOptions)}
            style={{ display: visible ? 'block' : 'none' }}
            renderer={'canvas'}
          />
          <FacetChart
            height={height}
            {...(facetProps as FacetOptions)}
            style={{ display: 'none' }}
            renderer={'svg'}
            width={1000}
          />
        </div>
      </div>
    )
  },
)

FacetStackedBar.displayName = 'FacetStackedBar'

export default FacetStackedBar
