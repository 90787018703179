import { UpsertClimateActionDto } from '@cozero/dtos'
import { BusinessUnit, ClimateAction, PageFilter } from '@cozero/models'
import { actApiGatewayClient } from '@cozero/uris'

import { FilterKey } from '@/molecules/FiltersDrawer'

import { LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import { TAG_CONSOLIDATED_GRAPH, TAG_COST_GRAPH, TAG_WATERFALL_GRAPH } from '../actGraphs'
import apiSlice from '../api'
import { TAG_GET_STRATEGY_BUILDER_STATE } from '../strategyBuilder'

import { TAG_CLIMATE_ACTIONS } from './constants'

export const GET_CLIMATE_ACTION = 'getClimateAction'
export const GET_CLIMATE_ACTIONS = 'getClimateActions'
export const CREATE_CLIMATE_ACTION = 'createClimateAction'
export const EDIT_CLIMATE_ACTION = 'editClimateAction'
export const DELETE_CLIMATE_ACTION = 'deleteClimateAction'

const climateActionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_CLIMATE_ACTIONS]: builder.query<
      ClimateAction[] | undefined,
      {
        userBusinessUnitId?: BusinessUnit['id'] | null
        filters?: PageFilter[]
      }
    >({
      query: ({ filters, userBusinessUnitId }) => ({
        url: actApiGatewayClient.climateActions.GET_MANY,
        method: 'GET',
        params: {
          userBusinessUnitId,
          query: {
            locations: filters?.filter((filter) => filter.key === FilterKey.LOCATION)[0]?.value,
            businessUnit: filters?.filter((filter) => filter.key === FilterKey.BUSINESS_UNIT)[0]
              ?.value,
            category: filters?.filter((filter) => filter.key === FilterKey.CATEGORY)[0]?.value,
            actionArea: filters?.filter((filter) => filter.key === FilterKey.ACTION_AREA)[0]?.value,
            responsible: filters?.filter((filter) => filter.key === FilterKey.RESPONSIBLE)[0]
              ?.value,
            scope: filters?.filter((filter) => filter.key === FilterKey.SCOPE)[0]?.value,
          },
        },
      }),
      providesTags: (result) =>
        providesList<ClimateAction[], typeof TAG_CLIMATE_ACTIONS>(result, TAG_CLIMATE_ACTIONS),
    }),
    [GET_CLIMATE_ACTION]: builder.query<ClimateAction, ClimateAction['id']>({
      query: (id) => ({
        url: actApiGatewayClient.climateActions.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_CLIMATE_ACTIONS, id: result?.id }],
    }),
    [CREATE_CLIMATE_ACTION]: builder.mutation<ClimateAction, UpsertClimateActionDto>({
      query: (climateActions) => ({
        url: actApiGatewayClient.climateActions.CREATE,
        method: 'POST',
        data: climateActions,
      }),
      invalidatesTags: [{ type: TAG_CLIMATE_ACTIONS, id: LIST }, TAG_GET_STRATEGY_BUILDER_STATE],
      extraOptions: { maxRetries: 0 },
    }),
    [EDIT_CLIMATE_ACTION]: builder.mutation<
      ClimateAction,
      { id: ClimateAction['id']; climateAction: UpsertClimateActionDto }
    >({
      query: ({ id, climateAction }) => ({
        url: actApiGatewayClient.climateActions.UPDATE_ONE.replace(':id', id?.toString() ?? ''),
        method: 'PUT',
        data: climateAction,
      }),
      invalidatesTags: (result) => [
        { type: TAG_CLIMATE_ACTIONS, id: result?.id },
        TAG_WATERFALL_GRAPH,
        TAG_COST_GRAPH,
        TAG_CONSOLIDATED_GRAPH,
      ],
      extraOptions: { maxRetries: 0 },
    }),
    [DELETE_CLIMATE_ACTION]: builder.mutation<ClimateAction, ClimateAction['id']>({
      query: (id) => ({
        url: actApiGatewayClient.climateActions.DELETE_ONE.replace(':id', id?.toString() ?? ''),
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [
        { type: TAG_CLIMATE_ACTIONS, id: result?.id },
        TAG_WATERFALL_GRAPH,
        TAG_COST_GRAPH,
        TAG_CONSOLIDATED_GRAPH,
        TAG_GET_STRATEGY_BUILDER_STATE,
      ],
    }),
  }),
})

export const {
  useGetClimateActionQuery,
  useGetClimateActionsQuery,
  useCreateClimateActionMutation,
  useEditClimateActionMutation,
  useDeleteClimateActionMutation,
  usePrefetch,
} = climateActionsApiSlice

export default climateActionsApiSlice
