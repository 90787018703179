import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { SLICE_NAME_STRATEGY_BUILDER } from './constants'

export type StrategyBuilderState = {
  forecastDrawerOpen: boolean
}

const initialState: StrategyBuilderState = {
  forecastDrawerOpen: false,
}

const strategyBuilderSlice = createSlice({
  name: SLICE_NAME_STRATEGY_BUILDER,
  initialState,
  reducers: {
    setForecastDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.forecastDrawerOpen = payload
    },
  },
})

export const { setForecastDrawerOpen } = strategyBuilderSlice.actions

export default strategyBuilderSlice
