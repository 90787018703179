import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiInformationCircle, HiOutlineExclamation } from 'react-icons/hi'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useParams } from 'react-router'

import { Col, Row } from 'antd'

import { skipToken } from '@reduxjs/toolkit/query/react'

import { PageFilter, ProductTag, ProductType, Report, Unit } from '@cozero/models'

import OverviewRow from '@/organisms/OverviewRow'
import ReportContainer from '@/organisms/ReportContainer/ReportContainer'

import { OverviewCardProps } from '@/molecules/OverviewCard'

import HighlightValue from '@/atoms/HighlightValue'
import LazyLoadImage from '@/atoms/LazyLoadImage'
import Tag from '@/atoms/Tag'

import placeholderImage from '@/assets/placeholder-image.png'
import useBoards from '@/hooks/useBoards'
import { useGetOrganizationTotalProductEmissionsQuery, useGetProductQuery } from '@/redux/products'
import { COZERO_BLUE_80 } from '@/styles/variables'
import { convertTonnesToGrams, formatNumber } from '@/utils/number'

import * as Skeleton from '../../../../atoms/Skeleton'

import classes from './ProductOverview.module.less'

const ProductOverview = (): ReactElement => {
  const { productId } = useParams()
  const { t } = useTranslation('common')
  const { getReportWithData } = useBoards()
  const { data: product, isFetching: loadingProduct } = useGetProductQuery(productId ?? skipToken)
  const { data: productsTotalEmissions, isFetching: loadingProductsTotalEmissions } =
    useGetOrganizationTotalProductEmissionsQuery()
  const [graphData, setGraphData] = useState<Report | undefined>()
  const productGraphFilters: PageFilter[] = [
    {
      value: [productId],
      selectedCondition: { key: 'in', label: 'in' },
      type: 'relationship',
      label: '',
      conditions: [],
      key: 'product',
    },
  ]
  const overviewData: OverviewCardProps[] = useMemo(() => {
    const emissions = product?.productEmissions.reduce((acc, x) => acc + x.value, 0) ?? 0
    const totalQuantities = product?.quantities?.reduce((acc, x) => acc + x.volume, 0) ?? 0
    const totalProductsOrganizationEmissions = productsTotalEmissions?.productsEmissions ?? 0
    const failedComponent = (
      <div className={classes.issuesWrapper}>
        <span className={classes.failedValue}>— — —</span>
        <Tag size="sm">
          <Row align={'middle'} className={classes.warning}>
            <HiOutlineExclamation />
            {t('log.entry.hasIssues')}
          </Row>
        </Tag>
      </div>
    )
    const emissionsValue = product?.hasIssues ? (
      failedComponent
    ) : (
      <HighlightValue value={formatNumber(convertTonnesToGrams(emissions))} unit="gCO₂e" />
    )
    const productEmissionsPercentage = product?.hasIssues ? (
      failedComponent
    ) : (
      <HighlightValue
        value={
          formatNumber((emissions * totalQuantities * 100) / totalProductsOrganizationEmissions) +
          ' %'
        }
      />
    )
    return [
      {
        content: (
          <Skeleton.Paragraph size="fill" loading={loadingProduct}>
            {emissionsValue}
          </Skeleton.Paragraph>
        ),
        headerTitle: t('log.overview-stats.emissions-per-unit'),
      },
      {
        content: (
          <Skeleton.Paragraph size="fill" loading={loadingProductsTotalEmissions}>
            {productEmissionsPercentage}
          </Skeleton.Paragraph>
        ),
        headerTitle: t('log.overview-stats.share-of-emissions'),
        tooltip: {
          triggerElement: <HiInformationCircle color={COZERO_BLUE_80} />,
          subtitle: t('log.overview-stats.share-of-emissions-info'),
        },
      },
    ]
  }, [product, productsTotalEmissions])

  useEffect(() => {
    if (!graphData) {
      fetchGraphData()
    }
  }, [])

  const fetchGraphData = async (): Promise<void> => {
    if (productId) {
      const reportKey = 'emissions-product-lifecycle'
      const report = await getReportWithData(reportKey, productGraphFilters)
      setGraphData(report)
    }
  }

  return (
    <>
      <Row>
        <Row>
          <Skeleton.Image loading={loadingProduct} active={loadingProduct}>
            <LazyLoadImage
              src={product?.image?.url ?? placeholderImage}
              className={classes.image}
            />
          </Skeleton.Image>
        </Row>
        <Row gutter={[12, 8]} className={classes.generalInfo}>
          <Col span={4}>
            <Skeleton.Paragraph size="fill" loading={loadingProduct}>
              {t('products.footprint.product-type')}
            </Skeleton.Paragraph>
          </Col>
          <Col span={20}>
            <Skeleton.Paragraph size="lg" loading={loadingProduct}>
              {
                <Tag truncateText className={classes.productType} size={'sm'}>
                  {((product?.productType as ProductType)?.name as string) ?? '--'}
                </Tag>
              }
            </Skeleton.Paragraph>
          </Col>
          <Col span={4}>
            <Skeleton.Paragraph size="fill" loading={loadingProduct}>
              {t('products.footprint.tags')}
            </Skeleton.Paragraph>
          </Col>
          <Col span={20} className={classes.tags}>
            <Skeleton.Paragraph size="lg" loading={loadingProduct}>
              {product?.tags?.length ? (
                product?.tags?.map((tag: ProductTag, i: number) => (
                  <Tag key={i} size="sm" className={classes.tag}>
                    {tag.name}
                  </Tag>
                ))
              ) : (
                <Tag className={classes.productType} size={'sm'}>
                  --
                </Tag>
              )}
            </Skeleton.Paragraph>
          </Col>
          <Col span={4}>
            <Skeleton.Paragraph size="fill" loading={loadingProduct}>
              {t('products.footprint.product-mass')}
            </Skeleton.Paragraph>
          </Col>
          <Col span={20}>
            <Skeleton.Paragraph size="lg" loading={loadingProduct}>
              <Tag className={classes.productType} size={'sm'}>
                <span>
                  {product?.mass} {(product?.massUnit as Unit)?.name ?? '--'}
                </span>
              </Tag>
            </Skeleton.Paragraph>
          </Col>
          <Col span={4}>
            <Skeleton.Paragraph size="fill" loading={loadingProduct}>
              {t('products.footprint.business-unit')}
            </Skeleton.Paragraph>
          </Col>
          <Col span={20}>
            <Skeleton.Paragraph size="lg" loading={loadingProduct}>
              <Tag className={classes.productType} size={'sm'}>
                {product?.businessUnit?.title ?? '--'}
              </Tag>
            </Skeleton.Paragraph>
          </Col>
          <Col span={4}>
            <Skeleton.Paragraph size="fill" loading={loadingProduct}>
              {t('product.responsible')}
            </Skeleton.Paragraph>
          </Col>
          <Col span={20}>
            <Skeleton.Paragraph size="lg" loading={loadingProduct}>
              <Tag className={classes.productType} size="sm">
                {product?.responsible?.firstName
                  ? `${product?.responsible?.firstName} ${product?.responsible?.lastName || ''}`
                  : product?.responsible?.username ?? '--'}
              </Tag>
            </Skeleton.Paragraph>
          </Col>
        </Row>
      </Row>
      <Row>
        <Col span={24}>
          <OverviewRow
            loadingOverview={false}
            marginBetween={14}
            overviewData={overviewData}
            hideTitle={true}
          />
        </Col>
      </Row>
      {graphData && (
        <div className={classes.graphWrapper}>
          <ReportContainer
            persistedBoardSettings={{ filters: productGraphFilters }}
            disableDelete={true}
            draggingGraphId={null}
            report={graphData}
            editable={false}
            loading={!graphData}
          />
        </div>
      )}
    </>
  )
}

export default ProductOverview
