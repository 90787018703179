import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowRight } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Col, ConfigProvider, Empty, Row } from 'antd/es'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'

import moment from 'moment'

import { AffectedLogEntry, GroupedMethodologyChangelog, Page } from '@cozero/models'
import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'

import Button from '@/atoms/Button'
import Pill from '@/atoms/Pill'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'

import emptyTable from '@/assets/empty-table.svg'
import { setSelectedCalculationChangelogRecord } from '@/redux/methodologyChangelogs/slice'
import { formatNumber } from '@/utils/number'

import classes from './classes.module.less'

interface Props {
  methodologyChangelogs: Page<GroupedMethodologyChangelog>
  page: number
  pageSize: number
  setPageSize: (size: number) => void
  setPage: (page: number) => void
}

const calculateChangelogEmissionsChange = (
  record: GroupedMethodologyChangelog,
  type: 'organization' | 'product' = 'organization',
): {
  oldValue: number
  newValue: number
  changePercentage: number
} => {
  const affectedLogEntries = (
    type === 'organization' ? record.affectedLogEntries : record.affectedProductLogEntries || []
  ) as AffectedLogEntry[]
  const oldValue = affectedLogEntries.reduce((acc, curr) => {
    return acc + curr.oldValue
  }, 0)
  const newValue = affectedLogEntries.reduce((acc, curr) => {
    return acc + curr.newValue
  }, 0)
  let changePercentage = ((newValue - oldValue) / oldValue) * 100
  changePercentage = !isNaN(changePercentage) ? changePercentage : 0

  return { changePercentage, oldValue, newValue }
}

function ChangelogsTable({
  methodologyChangelogs,
  page,
  pageSize,
  setPageSize,
  setPage,
}: Props): JSX.Element {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const columns: ColumnsType<GroupedMethodologyChangelog> = [
    {
      key: 'count',
      title: (
        <Tag size="sm" type="info">
          {methodologyChangelogs.total}
        </Tag>
      ),
      render() {
        return <span></span>
      },
    },
    {
      key: 'category',
      title: t('calculation-changes.columns.category'),
      render(text: string, record: GroupedMethodologyChangelog) {
        return (
          <Row align="middle">
            <Col span={5}>
              <img
                src={record.subcategory?.category?.image?.url || ''}
                alt={record.subcategory.category.name?.toString() || ''}
              />
            </Col>
            <Col span={19}>{record.subcategory.category.name?.toString() || ''}</Col>
          </Row>
        )
      },
      width: 250,
    },
    {
      key: 'subcategory',
      title: t('calculation-changes.columns.subcategory'),
      render(text: string, record: GroupedMethodologyChangelog) {
        return (
          <span>
            <Text size="xl">{record.subcategory?.name?.toString()}</Text>
          </span>
        )
      },
    },
    {
      width: 200,
      key: 'reason',
      title: t('calculation-changes.columns.reason'),
      render(text: string, record: GroupedMethodologyChangelog) {
        return (
          <Row>
            <Text size="xl">
              {t(`calculation-changes.reasons.${record.methodologyChangelogGlobal.changeReason}`)}
            </Text>
          </Row>
        )
      },
    },
    {
      width: 200,
      key: 'source',
      title: t('calculation-changes.columns.change'),
      render(text: string, record: GroupedMethodologyChangelog) {
        return (
          <span>
            <Text size="xl">
              {t(`calculation-changes.changes.${record.methodologyChangelogGlobal.changeSource}`)}
            </Text>
          </span>
        )
      },
    },
    {
      title: t('calculation-changes.columns.year'),
      render(text: string, record: GroupedMethodologyChangelog) {
        const year = moment(record.createdAt).format('YYYY')
        return (
          <Text size="xl">
            {record.affectedYears.reduce((acc, value) => `${acc}, ${value}`, '').slice(1)}
          </Text>
        )
      },
    },
    {
      key: 'organization-impacted-logs',
      title: t('calculation-changes.columns.organization-emissions'),
      render(text: string, record: GroupedMethodologyChangelog) {
        const { changePercentage, oldValue, newValue } = calculateChangelogEmissionsChange(
          record,
          'organization',
        )
        return (
          <Row align="middle" gutter={64}>
            <Col>
              <Text>
                <span className={classes.striked}>{formatNumber(oldValue)}</span> <HiArrowRight />
                {formatNumber(newValue)}
              </Text>
            </Col>
            <Col>
              <Pill size="xs" color={changePercentage <= 0 ? 'green' : 'orange'}>
                <Text fontWeight="medium">
                  {changePercentage < 0 ? '-' : changePercentage > 0 ? '+' : ''}
                  {changePercentage === 0 ? '---' : `${formatNumber(Math.abs(changePercentage))}%`}
                </Text>
              </Pill>
            </Col>
          </Row>
        )
      },
    },
    {
      key: 'product-impacted-logs',
      title: t('calculation-changes.columns.product-emissions'),
      render(text: string, record: GroupedMethodologyChangelog) {
        const { changePercentage, oldValue, newValue } = calculateChangelogEmissionsChange(
          record,
          'product',
        )
        return (
          <Row align="middle" gutter={64}>
            <Col>
              <Text>
                <s>{formatNumber(oldValue)}</s> <HiArrowRight /> {formatNumber(newValue)}
              </Text>
            </Col>
            <Col>
              <Row align="middle" justify="center">
                <Pill size="xs" color={changePercentage <= 0 ? 'green' : 'orange'}>
                  <Text fontWeight="medium">
                    {changePercentage < 0 ? '-' : changePercentage > 0 ? '+' : ''}
                    {changePercentage === 0
                      ? '---'
                      : `${formatNumber(Math.abs(changePercentage))}%`}
                  </Text>
                </Pill>
              </Row>
            </Col>
          </Row>
        )
      },
    },
    {
      fixed: 'right',
      key: 'implemented-dates',
      title: (
        <Row gutter={8}>
          <Col>{t('calculation-changes.columns.implementation')}</Col>
          <Col>→</Col>
          <Col>{t('calculation-changes.columns.recalculation')}</Col>
        </Row>
      ),
      render(text: string, record: GroupedMethodologyChangelog) {
        const format = `DD MMM YYYY`
        const implementation = moment(record.createdAt).format(format)
        const recalculation = record.recalculationStartDate
          ? moment(record.recalculationStartDate).format(format)
          : '-'
        return (
          <Row align="middle" gutter={8}>
            <Col>{implementation}</Col>
            <Col> → </Col>
            <Col>{recalculation}</Col>
          </Row>
        )
      },
    },
    {
      fixed: 'right',
      key: 'view-details',
      title: t('calculation-changes.columns.action'),
      render() {
        return (
          <Button
            action="view-calculation-details"
            category="calculations-changes"
            type="secondary"
          >
            {t('calculation-changes.columns.view-details')}
          </Button>
        )
      },
    },
  ]

  const goToChangelog = useCallback((record: GroupedMethodologyChangelog): void => {
    dispatch(setSelectedCalculationChangelogRecord(record))
    navigate(
      routes.log.factors.calculations.viewCalculation.replace(
        ':id',
        record.methodologyChangelogGlobalId.toString(),
      ),
    )
  }, [])

  const changeTable = (pagination: TablePaginationConfig): void => {
    if (pagination.current && pagination.current !== page) {
      setPage(pagination.current)
    }
  }
  const CustomEmpty = (): ReactElement => (
    <Empty
      image={emptyTable}
      imageStyle={{ height: 108 }}
      description={
        <div>
          <Row justify={'center'}>
            <Text size="xl" mode="text" fontWeight="semibold" color="secondary">
              {t('calculation-changes.impact-modal.no-changes')}
            </Text>
          </Row>
          <Row justify={'center'}>
            <Text size="md" color="secondary">
              {t('calculation-changes.impact-modal.no-action')}
            </Text>
          </Row>
        </div>
      }
    ></Empty>
  )

  return (
    <ConfigProvider renderEmpty={CustomEmpty}>
      <Table
        dataSource={methodologyChangelogs.data}
        rowKey="id"
        columns={columns}
        scroll={{ x: 'max-content' }}
        onRow={(record) => ({
          onClick: () => goToChangelog(record),
        })}
        rowClassName={classes.tableRow}
        showWrapper={false}
        onChange={changeTable}
        pagination={{
          current: page,
          defaultPageSize: pageSize,
          pageSize,
          total: methodologyChangelogs.total,
          hideOnSinglePage: true,
          onShowSizeChange: (_current, size) => {
            setPageSize(size)
          },
        }}
      />
    </ConfigProvider>
  )
}

export default ChangelogsTable
