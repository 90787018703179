import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { FormInstance } from 'antd'
import { message } from 'antd/es'
import { useForm } from 'antd/es/form/Form'
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload/interface'

import _ from 'lodash'

import { FactorRequest, FactorRequestStatus, Product, ProductEmission } from '@cozero/models'
import { routes } from '@cozero/utils'

import { useFactorContext } from '@/contexts/factor'
import { useSubscriptionContext } from '@/contexts/subscription'
import useAppContext from '@/hooks/useApp'
import useLog from '@/hooks/useLog'
import useProducts from '@/hooks/useProducts'
import { useAppSelector } from '@/redux'
import { AuthState } from '@/redux/auth/slice'
import { selectSelectedBusinessUnitKey } from '@/redux/businessUnits'
import { useFindFactorRequestSupplierMutation } from '@/redux/factors-requests/api'
import { setFactorRequest as setFactor } from '@/redux/factors-requests/slice'
import { useGetSignedUrlMutation, useUploadFileToSignedUrlMutation } from '@/redux/files'
import { useGetProductCountQuery, useGetProductsQuery } from '@/redux/products'

import { selectUser, selectUserOrganization } from '../../../../redux/auth'
import { LifecycleStepsKey } from '../../../Organization/NewProduct/steps'

export interface IProductLifecycleEmission {
  key: string
  value: undefined | number
  id: number
}

interface ISignedFilesUrl {
  name: string
  fileObj?: RcFile
  signedUrl: string
  path: string
}

interface ISupplierOnboarding {
  welcomeTitle: (firstName?: string | null) => string
  user: AuthState['user']
  factorRequest: FactorRequest | undefined
  whyShareTexts: { title: string; subtitle: string }[]
  endedOnboarding: boolean
  goToCreatePage: () => Promise<void>
  form: FormInstance
  onSubmit: () => Promise<void>
  showShareModal: boolean
  showProductCreatedModal: boolean
  setShowProductCreatedModal: (value: boolean) => void
  shareProductLifecycleSteps: boolean
  totalProductEmissions: number | undefined
  setShareProductLifecycleSteps: (value: boolean) => void
  selectedProduct: Product | undefined
  productLifecycleEmissions: IProductLifecycleEmission[] | undefined
  comment: string
  setComment: (value: string) => void
  onUpload: ({ fileList }: UploadChangeParam<UploadFile<unknown>>) => void
  fileList: UploadFile[]
  isSubmitting: boolean
  setShowShareModal: (value: boolean) => void
}

const useSupplierOnboarding = (): ISupplierOnboarding => {
  const { t } = useTranslation('common')
  const [form] = useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [shareProductLifecycleSteps, setShareProductLifecycleSteps] = useState<boolean>(true)
  const [comment, setComment] = useState<string>('')
  const [totalProductEmissions, setTotalProductEmissions] = useState<number | undefined>()
  const [factorRequest, setFactorRequest] = useState<FactorRequest>()
  const [endedOnboarding, setEndedOnboarding] = useState<boolean>(false)
  const [showShareModal, setShowShareModal] = useState<boolean>(false)
  const [showProductCreatedModal, setShowProductCreatedModal] = useState(false)
  const [findSupplierFactorRequest] = useFindFactorRequestSupplierMutation()
  const businessUnitKey = useAppSelector(selectSelectedBusinessUnitKey)
  const [getSignedUrl] = useGetSignedUrlMutation()
  const [uploadFileToSignedUrl] = useUploadFileToSignedUrlMutation()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const organization = useAppSelector(selectUserOrganization)
  const { getProductEmissions } = useLog()
  const { getProductLogEntry, getProduct } = useProducts()
  const { data: products = [] } = useGetProductsQuery({
    businessUnitKey: businessUnitKey?.toString() as string,
  })
  const [productLifecycleEmissions, setProductLifecycleEmissions] = useState<
    IProductLifecycleEmission[] | undefined
  >()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>()

  const user = useSelector(selectUser)
  const location = useLocation()
  const { setSubscribeModalSettings, getLimit } = useSubscriptionContext()
  const { getLifecycleSteps } = useAppContext()
  const { data: productsCount } = useGetProductCountQuery()

  const { saveFactorRequest, createFactorRequestResponse } = useFactorContext()

  useEffect(() => {
    if (products?.length === 0) {
      return
    }
    const fetchProduct: () => void = async () => {
      const product = await getProduct(products?.[0]?.id.toString() as string)
      setSelectedProduct(product)
    }
    fetchProduct()
  }, [products])

  const onUpload = ({ fileList: newFileList }: UploadChangeParam<UploadFile<unknown>>): void => {
    setFileList(newFileList)
  }

  const onSubmit = async (): Promise<void> => {
    setIsSubmitting(true)
    const value = totalProductEmissions
    let attachments: ISignedFilesUrl[] = []
    try {
      if (factorRequest && value) {
        if (fileList.length > 0) {
          attachments = await getFilesSignedUrl(fileList)
          for (const file of attachments) {
            await uploadFileToSignedUrl(file)
          }
        }
        const data = await saveFactorRequest(factorRequest.id, {
          ...factorRequest,
          value,
          status: 'SUBMITTED' as FactorRequestStatus,
        })

        const sharedLifecycleDetails: { id: number; value: number }[] = shareProductLifecycleSteps
          ? productLifecycleEmissions
              ?.filter((lifecycleStep) => lifecycleStep?.value !== undefined)
              ?.map((lifecycleStep) => ({
                id: lifecycleStep.id,
                value: lifecycleStep.value as number,
              })) || []
          : []
        if (factorRequest?.id && selectedProduct?.id) {
          const result = await createFactorRequestResponse({
            factorRequestId: factorRequest.id,
            productId: selectedProduct?.id,
            value: totalProductEmissions,
            message: comment,
            productLifecycleStepDetails: sharedLifecycleDetails,
            attachments: attachments.map(({ path }) => path || ''),
          })
          if (result?.id) {
            setEndedOnboarding(true)
            setShowShareModal(true)
          }
        }
      }
      setIsSubmitting(false)
    } catch (error) {
      message.error(t('onboarding.suppliers.error'))
      setIsSubmitting(false)
    }
  }

  const getFilesSignedUrl = async (
    newFileList: UploadFile<unknown>[],
  ): Promise<ISignedFilesUrl[]> => {
    const urls = await getSignedUrl(newFileList.map(({ name }) => name)).unwrap()
    return [
      ...newFileList.map((x, index) => ({
        name: x.name,
        fileObj: x.originFileObj,
        signedUrl: urls[index].signedUrl,
        path: urls[index].path,
      })),
    ]
  }

  useEffect(() => {
    const fetchFactorRequest = async (): Promise<void> => {
      if (user?.email) {
        const request = (await findSupplierFactorRequest(user?.email)) as {
          data: FactorRequest[]
        }
        dispatch(setFactor(request.data))
        form.setFieldValue('unit', request.data?.at(0)?.unit?.name?.toString())
        setFactorRequest(request.data?.at(0))
      }
    }
    fetchFactorRequest()
  }, [user])

  useEffect(() => {
    if (location.state && (location.state as { showCompleteModal: boolean }).showCompleteModal) {
      window.history.replaceState({}, document.title)
      setShowProductCreatedModal(
        (location.state as { showCompleteModal: boolean }).showCompleteModal,
      )
    }
  }, [location.state])

  useEffect(() => {
    const fetchProductEmissions = async (): Promise<void> => {
      if (selectedProduct) {
        const productEmissions = await getProductEmissions(products[0].id)
        setTotalProductEmissions(productEmissions?._sum?.value)
        const productLogEntres = await getProductLogEntry(products[0].id.toString())
        const lifecycleSteps = await getLifecycleSteps([
          LifecycleStepsKey.PRODUCT_CONFIGURATION,
          LifecycleStepsKey.PRODUCT_COMPOSITION,
          LifecycleStepsKey.PRODUCTION,
          LifecycleStepsKey.PACKAGING,
          LifecycleStepsKey.DISTRIBUTION_AND_STORAGE,
          LifecycleStepsKey.USAGE,
          LifecycleStepsKey.QUANTITIES,
          LifecycleStepsKey.END_OF_LIFE,
        ])
        if (lifecycleSteps) {
          // Lifecycyle steps from factor request
          const lifecycleStepsToShow = (factorRequest?.productLifecycleSteps?.map((step) => ({
            key: step.key,
            value: undefined,
            id: step.id,
          })) || []) as IProductLifecycleEmission[]

          // Lifecycyle steps from product lifecycle emissions
          const newProductLifecycleEmissions = productLogEntres.map((logEntry) => ({
            key: (lifecycleSteps.find((step) => step.id == logEntry.productlifecycleStepId)?.key ||
              '') as string,
            value: (logEntry.productEmissions as ProductEmission[]).reduce(
              (acc: number, curr: ProductEmission) => acc + curr.value,
              0,
            ),
            id: logEntry.productlifecycleStepId,
          })) as IProductLifecycleEmission[]

          // merge the two arrays and remove lifecycle steps from factor request that are already in product lifecycle emissions
          // The order of the merging is important
          let finalProductLifecycleEmissions = _.uniqBy(
            [...newProductLifecycleEmissions, ...lifecycleStepsToShow],
            'key',
          )
          // Sort final results by id
          finalProductLifecycleEmissions = _.orderBy(finalProductLifecycleEmissions, 'id', 'asc')
          setProductLifecycleEmissions(finalProductLifecycleEmissions)
        }
      }
    }
    fetchProductEmissions()
  }, [selectedProduct])

  useEffect(() => {
    // Set lifecycle steps based on the factor request if not set already
    if (factorRequest && !productLifecycleEmissions) {
      const lifecycleSteps = factorRequest?.productLifecycleSteps?.map((step) => ({
        key: step.key,
        value: undefined,
        id: step.id,
      }))
      setProductLifecycleEmissions(lifecycleSteps)
    }
  }, [factorRequest])

  const whyShareTexts = [
    {
      title: t('onboarding.suppliers.reasons.0.title'),
      subtitle: t('onboarding.suppliers.reasons.0.subtitle'),
    },
    {
      title: t('onboarding.suppliers.reasons.1.title'),
      subtitle: t('onboarding.suppliers.reasons.1.subtitle'),
    },
    {
      title: t('onboarding.suppliers.reasons.2.title'),
      subtitle: t('onboarding.suppliers.reasons.2.subtitle'),
    },
  ]

  const welcomeTitle = (firstName?: string | null): string => {
    if (firstName) {
      return `${firstName}, ${t('onboarding.suppliers.welcome-message')}`
    }
    return _.capitalize(t('onboarding.suppliers.welcome-message'))
  }

  const goToCreatePage = async (): Promise<void> => {
    const navigationState = {
      from: `${location.pathname}${location.search}`,
      selectedSteps: factorRequest?.productLifecycleSteps?.map(
        (productLifecycleStep) => productLifecycleStep.key,
      ),
      unitId: factorRequest?.unit.id,
      productName: factorRequest?.activityDataSource?.name,
    }
    if (products?.length) {
      navigate(
        routes.log.carbonFootprint.productsUpsert.stepsEdit.replace(
          ':id',
          products.at(0)?.id?.toString() ?? '',
        ),
        {
          state: { ...navigationState, from: `${location.pathname}${location.search}` },
        },
      )
    } else {
      const limit = getLimit(organization, 'products')
      if (limit && productsCount && productsCount >= limit.max) {
        setSubscribeModalSettings({
          closable: true,
          title: t('subscription.upgrade-modal.title-limit', {
            limit: limit.max,
            item: t('products.title'),
          }),
          visible: true,
        })
        return
      }
      navigate(routes.log.carbonFootprint.productsUpsert.stepsCreate, {
        state: navigationState,
      })
    }
  }
  return {
    welcomeTitle,
    user,
    factorRequest,
    whyShareTexts,
    endedOnboarding,
    goToCreatePage,
    form,
    onSubmit,
    onUpload,
    showShareModal,
    showProductCreatedModal,
    setShowProductCreatedModal,
    setShareProductLifecycleSteps,
    shareProductLifecycleSteps,
    totalProductEmissions,
    selectedProduct,
    productLifecycleEmissions,
    comment,
    setComment,
    fileList,
    isSubmitting,
    setShowShareModal,
  }
}

export default useSupplierOnboarding
