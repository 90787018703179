import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import GridLayout, { Layout } from 'react-grid-layout'

import { Empty } from 'antd/es'

import { Board, Report, ReportToBoard, ReportType } from '@cozero/models'

import 'react-grid-layout/css/styles.css'

import { useTranslation } from 'react-i18next'

import 'react-resizable/css/styles.css'

import { SizeMe } from 'react-sizeme'

import LoadingSpinner from '@/atoms/LoadingSpinner'

import { useBoardsContext } from '@/contexts/boards'
import { useAppSelector } from '@/redux'
import { getIsUserReadOnly } from '@/redux/auth'
import { PersistedBoardSettings } from '@/types/general'

import ReportContainer from '../ReportContainer/ReportContainer'

import classes from './CustomBoard.module.less'

interface Props {
  canUpdateWidth?: boolean
  includeChildrenBUsData?: boolean
  updateBoard?: (board: Board) => void
  updateReportToBoard?: (board: Board, id: Report['id'], newValues: Partial<ReportToBoard>) => void
  editable?: boolean
  sharePageId?: number
  persistedBoardSettings?: PersistedBoardSettings
}

const CustomBoard = ({
  updateBoard,
  updateReportToBoard,
  includeChildrenBUsData,
  editable = false,
  canUpdateWidth = true,
  sharePageId,
  persistedBoardSettings,
}: Props): ReactElement => {
  const isUserReadOnly = useAppSelector(getIsUserReadOnly)
  const { updateBoardWidth, updateBoardLayout, selectedBoard, loadingBoards } = useBoardsContext()
  const { t } = useTranslation('common')
  const [lastUpdatedGraphId, setLastUpdatedGraphId] = useState<number | null>(null)
  const [draggingGraphId, setDraggingGraphId] = useState<number | null>(null)

  useEffect(() => {
    if (selectedBoard && lastUpdatedGraphId) {
      updateBoardWidth(selectedBoard, lastUpdatedGraphId)
    }
  }, [selectedBoard])

  const updateLayout = (layout: Layout[], boardId?: number): void => {
    if (selectedBoard) {
      boardId && layout.length === 0
        ? updateBoardWidth(selectedBoard, boardId)
        : updateBoardLayout(selectedBoard, layout, sharePageId)
    }
  }

  const handleDrag: GridLayout.ItemCallback = (
    layout: GridLayout.Layout[],
    oldItem: GridLayout.Layout,
    newItem: GridLayout.Layout,
    placeholder: GridLayout.Layout,
    event: MouseEvent,
  ) => {
    switch (event.type) {
      case 'mouseup':
        setDraggingGraphId(null)
        break
      case 'mousedown':
        setDraggingGraphId(parseInt(newItem.i))
        break
    }
  }

  const updateGraphWidth = (
    report: ReportToBoard & {
      report: Report | null
    },
  ): void => {
    if (selectedBoard && updateBoard) {
      updateBoard({
        ...selectedBoard,
        reportToBoards: selectedBoard.reportToBoards.map((board) => {
          if (board.id === report.id) {
            setLastUpdatedGraphId(board.id)
            return { ...board, width: board.width === 2 ? 1 : 2 }
          }
          return board
        }),
      })
    }
  }

  const layout = useMemo(
    () =>
      (selectedBoard?.reportToBoards?.map((reportToBoard) => ({
        x: reportToBoard.xCoord,
        y: reportToBoard.yCoord,
        w: reportToBoard.width,
        h: reportToBoard.height,
        minW: 1,
        maxW: 2,
        minH: 4,
        maxH: 4,
      })) ?? []) as Layout[],
    [selectedBoard],
  )

  const renderReports = useMemo((): ReactNode | null => {
    if (selectedBoard) {
      return selectedBoard?.reportToBoards
        ?.filter(
          (reportToBoard) => (reportToBoard.report?.type as ReportType) !== ReportType.STATISTIC,
        )
        .map((reportToBoard) => {
          if (reportToBoard.report) {
            return (
              <div
                key={reportToBoard.id}
                className={`${classes.dragContainer} ${
                  updateReportToBoard && editable && !isUserReadOnly
                    ? classes.dragContainerIsEditing
                    : ''
                }`}
                data-grid={{
                  i: `${(reportToBoard.report as Report).id}`,
                  x: reportToBoard.xCoord ?? 0,
                  y: reportToBoard.yCoord ?? 0,
                  w: reportToBoard.width,
                  h: reportToBoard.height,
                }}
              >
                <ReportContainer
                  includeChildrenBUsData={includeChildrenBUsData}
                  persistedBoardSettings={persistedBoardSettings}
                  updateGraphWidth={
                    editable && canUpdateWidth && !isUserReadOnly ? updateGraphWidth : undefined
                  }
                  reportToBoard={
                    reportToBoard as ReportToBoard & {
                      report: Report | null
                    }
                  }
                  report={reportToBoard.report as Report}
                  key={reportToBoard.id}
                  showFilters={reportToBoard.filters}
                  showColumns={reportToBoard.columns}
                  editable={editable && !isUserReadOnly}
                  hasDatePicker={selectedBoard.level === 'organization'}
                  draggingGraphId={draggingGraphId}
                />
              </div>
            )
          }
        })
    }
  }, [selectedBoard, draggingGraphId])

  if (
    selectedBoard?.level === 'share' &&
    !selectedBoard.reportToBoards
      .flatMap((gridData) => gridData.report as Report)
      .some((report) => report.data !== undefined)
  ) {
    return (
      <Empty
        className={classes.empty}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>{t('reports.no-reports')}</span>}
      />
    )
  }

  if (loadingBoards) {
    return <LoadingSpinner className={classes.spinner} title={t('share.reports.loading-plural')} />
  }

  return (
    <>
      <div key={selectedBoard?.id} className={classes.boardWrapper}>
        <SizeMe key={selectedBoard?.id} monitorWidth refreshMode={'debounce'} refreshRate={32}>
          {({ size }) => {
            return (
              <GridLayout
                className={`layout`}
                width={size.width ?? 1000}
                cols={2}
                rowHeight={100}
                isBounded={true}
                containerPadding={[0, 0]}
                margin={[1, 1]}
                layout={layout}
                autoSize={true}
                isDraggable={
                  updateReportToBoard &&
                  editable &&
                  ((selectedBoard && selectedBoard.level === 'user') || !isUserReadOnly)
                }
                draggableHandle=".drag"
                onDragStart={handleDrag}
                onDragStop={handleDrag}
                isDroppable={false}
                isResizable={false}
                compactType="vertical"
                preventCollision={false}
                useCSSTransforms={false}
                onLayoutChange={updateLayout}
              >
                {renderReports}
              </GridLayout>
            )
          }}
        </SizeMe>
      </div>
    </>
  )
}

export default CustomBoard
