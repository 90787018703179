import { BusinessUnit, Organigram, PageFilter } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_ACTIVE_BUSINESS_UNITS, TAG_BUSINESS_UNITS, TAG_ORGANIGRAM } from './constants'

export const GET_BUSINESS_UNIT = 'getBusinessUnit'
export const GET_BUSINESS_UNITS = 'getBusinessUnits'
export const GET_PAGINATED_BUSINESS_UNITS = 'getPaginatedBusinessUnits'
export const GET_BUSINESS_UNIT_SCOPE = 'getBusinessUnitScope'
export const GET_BUSINESS_UNITS_COUNT = 'getBusinessUnitsCount'
export const GET_ACTIVE_BUSINESS_UNITS = 'getActiveBusinessUnits'
export const CREATE_BUSINESS_UNIT = 'createBusinessUnit'
export const EDIT_BUSINESS_UNIT = 'editBusinessUnit'
export const DELETE_BUSINESS_UNIT = 'deleteBusinessUnit'
export const TRANSFER_BUSINESS_UNIT = 'transferBusinessUnit'
export const GET_ORGANIGRAM = 'getOrganigram'

const businessUnitsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_BUSINESS_UNIT]: builder.query<BusinessUnit, { id: string; active?: boolean }>({
      query: ({ id, active }) => ({
        url: centralApiGatewayClient.businessUnits.GET_ONE.replace(':id', id),
        method: 'GET',
        params: {
          active,
        },
      }),
      providesTags: (result) => [{ type: TAG_BUSINESS_UNITS, id: result?.id }],
    }),
    [GET_BUSINESS_UNITS]: builder.query<
      BusinessUnit[],
      {
        businessUnitKey: BusinessUnit['key']
        root?: boolean
        key?: string
        search?: string
        active?: boolean
        populate?: boolean
        filters?: PageFilter[]
      }
    >({
      query: (data) => ({
        url: centralApiGatewayClient.businessUnits.SEARCH,
        method: 'POST',
        data,
        params: {
          active: data.active,
        },
      }),
      providesTags: (result) =>
        providesList<BusinessUnit[], typeof TAG_BUSINESS_UNITS>(result, TAG_BUSINESS_UNITS),
    }),
    [GET_ACTIVE_BUSINESS_UNITS]: builder.query<
      BusinessUnit[],
      {
        businessUnitKey: BusinessUnit['key']
        root?: boolean
        key?: string
        search?: string
        populate?: boolean
      }
    >({
      query: (data) => ({
        url: centralApiGatewayClient.businessUnits.SEARCH,
        method: 'POST',
        data: {
          ...data,
        },
        params: {
          active: true,
        },
      }),
      providesTags: (result) =>
        providesList<BusinessUnit[], typeof TAG_ACTIVE_BUSINESS_UNITS>(
          result,
          TAG_ACTIVE_BUSINESS_UNITS,
        ),
    }),
    [GET_BUSINESS_UNIT_SCOPE]: builder.query<
      { selectedBusinessUnit: BusinessUnit; businessUnitTree: BusinessUnit[] },
      BusinessUnit['id']
    >({
      query: (id) => ({
        url: centralApiGatewayClient.businessUnits.GET_SCOPE,
        method: 'POST',
        params: {
          id,
        },
      }),
    }),
    [CREATE_BUSINESS_UNIT]: builder.mutation<BusinessUnit, Partial<BusinessUnit>>({
      query: (data) => ({
        url: centralApiGatewayClient.businessUnits.CREATE,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        { type: TAG_BUSINESS_UNITS, id: LIST },
        { type: TAG_ACTIVE_BUSINESS_UNITS, id: LIST },
      ],
      extraOptions: { maxRetries: 0 },
    }),

    [EDIT_BUSINESS_UNIT]: builder.mutation<
      BusinessUnit,
      { id: string; data: Partial<BusinessUnit> }
    >({
      query: ({ id, data }) => ({
        url: centralApiGatewayClient.businessUnits.UPDATE_ONE.replace(':id', id ?? ''),
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result) => [
        { type: TAG_BUSINESS_UNITS, id: result?.id },
        { type: TAG_ACTIVE_BUSINESS_UNITS, id: result?.id },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    [TRANSFER_BUSINESS_UNIT]: builder.mutation<
      BusinessUnit,
      { businessUnitId: BusinessUnit['id']; newParentId: BusinessUnit['id'] }
    >({
      query: ({ businessUnitId, newParentId }) => ({
        url: centralApiGatewayClient.businessUnits.TRANSFER.replace(
          ':id',
          `${businessUnitId}`,
        ).replace(':parentId', `${newParentId}`),
        method: 'POST',
      }),
      invalidatesTags: [
        { type: TAG_BUSINESS_UNITS, id: LIST },
        { type: TAG_ACTIVE_BUSINESS_UNITS, id: LIST },
      ],
    }),
    [DELETE_BUSINESS_UNIT]: builder.mutation<BusinessUnit, string>({
      query: (id) => ({
        url: centralApiGatewayClient.businessUnits.DELETE_ONE.replace(':id', id ?? ''),
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: TAG_BUSINESS_UNITS, id: LIST },
        { type: TAG_ACTIVE_BUSINESS_UNITS, id: LIST },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    [GET_ORGANIGRAM]: builder.query<
      Organigram,
      {
        id: string
        datesFilter?: { startDate: string; endDate: string }
      }
    >({
      query: ({ id, datesFilter }) => ({
        url: centralApiGatewayClient.businessUnits.GET_ORGANIGRAM.replace(':id', `${id}`),
        params: datesFilter,
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_ORGANIGRAM, id: result?.businessUnit?.id }],
    }),
  }),
})

export const {
  useGetBusinessUnitQuery,
  useLazyGetBusinessUnitQuery,
  useGetBusinessUnitsQuery,
  useLazyGetBusinessUnitsQuery,
  useGetBusinessUnitScopeQuery,
  useLazyGetBusinessUnitScopeQuery,
  useGetActiveBusinessUnitsQuery,
  useLazyGetActiveBusinessUnitsQuery,
  useCreateBusinessUnitMutation,
  useEditBusinessUnitMutation,
  useDeleteBusinessUnitMutation,
  useTransferBusinessUnitMutation,
  usePrefetch,
  useGetOrganigramQuery,
  useLazyGetOrganigramQuery,
} = businessUnitsApiSlice

export default businessUnitsApiSlice
