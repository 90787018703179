import { useTranslation } from 'react-i18next'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetStrategyBuilderStateQuery } from '@/redux/strategyBuilder'

import { MenuItem } from './NavSidebar'
import { useMenuFeature } from './NavSidebar.useMenuFeature'

export const useMenuItemChildren = (): {
  getMappedActItemChildren: (menuItems: MenuItem) => MenuItem[] | undefined
} => {
  const { t } = useTranslation()
  const { canUseMenuFeature } = useMenuFeature()

  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const hasNoSelectedBusinessUnit = typeof selectedBusinessUnit?.id !== 'number'

  const { data: strategyBuilderState } = useGetStrategyBuilderStateQuery(
    { businessUnitId: selectedBusinessUnit?.id || -1 },
    { skip: hasNoSelectedBusinessUnit || !canUseMenuFeature({ feature: 'act' }) },
  )

  const getMappedActItemChildren = (item: MenuItem): MenuItem[] | undefined => {
    if (!item.children) {
      return undefined
    }

    if (strategyBuilderState?.forecastConfig) {
      return item.children
    }

    return item.children.map((item) => {
      if (item.key !== 'targets') {
        return item
      }

      return {
        ...item,
        isDisabled: true,
        disabledTooltip: t('act.targets.onboarding.disabledTooltip'),
      }
    })
  }

  return { getMappedActItemChildren }
}
