import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowRight, HiPuzzle } from 'react-icons/hi'
import { useNavigate } from 'react-router'

import { Typography } from 'antd/es'

import { routes } from '@cozero/utils'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'

import { CINDER_BLUE_50, SPACING_XS } from '@/styles/variables'

import classes from './EmptyGraphView.module.less'

const EmptyGraphView = (): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')

  const goToWiki = (): void => {
    window.open(t('intercom.log.introduction'), '_blank')
  }

  return (
    <div className={classes.emptyStateContainer}>
      <div className={classes.header}>
        <HiPuzzle size={20} color={CINDER_BLUE_50} className={classes.icon} />
        <Text fontWeight="semibold" mode="title" size="xs" color="secondary">
          {t('share.reports.empty.by-data.title')}
        </Text>
      </div>
      <Text fontWeight="medium" size="lg" color="disabled">
        {t('share.reports.empty.by-data.description')}
      </Text>
      <Typography.Link onClick={goToWiki} className={classes.link}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {t('share.reports.empty.by-data.link')}
          <HiArrowRight size={12} style={{ marginLeft: SPACING_XS }} />
        </div>
      </Typography.Link>
      <Button
        onClick={() => navigate(routes.log.carbonFootprint.organization.base)}
        category="reports"
        action="go to log emissions from empty graph"
        type="primary"
      >
        {t('share.reports.empty.by-data.button')}
      </Button>
    </div>
  )
}

export default EmptyGraphView
