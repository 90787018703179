import { config } from './config'

export function getUrl(path: string): URL {
  return new URL(`${config.API_URL}${path}`)
}

export function appendSearchParam(route: string, key: string, value: string): string {
  const keyValuePair = `${key}=${value}`,
    delimiter = route.includes('?') ? '&' : '?'

  return `${route}${delimiter}${keyValuePair}`
}
