import { useCallback, useEffect } from 'react'

import Mixpanel, { Dict } from 'mixpanel-browser'

import { User } from '@cozero/models'

import { useAppSelector } from '@/redux'
import {
  getBelongsToLiveOrganisation,
  getHasCozeroEmail,
  getIsCozeroAdmin,
  selectUser,
} from '@/redux/auth'
import { config } from '@/utils/config'

const isProd = ['production'].includes(config.NODE_ENV)

Mixpanel.init(config.MIXPANEL_ID ?? 'dev', {
  ip: false,
  api_host: 'https://api-eu.mixpanel.com',
  debug: !isProd,
})

export const useInitMixpanel = (): void => {
  const user = useAppSelector(selectUser)

  const isCozeroAdmin = useAppSelector(getIsCozeroAdmin)
  const hasCozeroEmail = useAppSelector(getHasCozeroEmail)
  const belongToLiveOrganisation = useAppSelector(getBelongsToLiveOrganisation)

  useEffect(() => {
    if (user) {
      const debug = !isProd || isCozeroAdmin || hasCozeroEmail || !belongToLiveOrganisation

      Mixpanel.set_config({ debug })

      const analyticsEnabled = user.organization?.telemetry
      if (isProd && analyticsEnabled) {
        const data = {
          user_id: user.id,
          created_at: user.createdAt,
          organization_id: user.organization?.id,
          organization_name: user.organization?.name,
          business_unit_id: user.businessUnit?.id,
          business_unit_title: user.businessUnit?.title,
          pricing: user.organization?.pricing?.name,
          industry: user.organization?.industry?.name,
          size: user.organization?.totalEmployees,
          role: user.role?.name,
          locale: user.locale,
          test: debug,
        }

        Mixpanel.identify(`${user.id}`)
        Mixpanel.register(data)
        Mixpanel.people.set(data)
        Mixpanel.track('Session start')
      }
    } else {
      Mixpanel.reset()
    }
  }, [user?.id])
}

export const useMixpanel = (): {
  identify: (user: User) => void
  trackPageView: (properties: Dict) => void
  trackAction: (category: string, action: string, properties?: Dict) => void
} => {
  const track: typeof Mixpanel.track = useCallback((...args) => {
    if (Mixpanel.track) {
      Mixpanel.track(...args)
    }
  }, [])

  return {
    identify(user: User) {
      if (user.organization?.telemetry) {
        Mixpanel.identify(`${user.id}`)
      }
    },
    trackPageView(properties: Dict) {
      track('page-view', properties)
    },
    trackAction(category: string, action: string, properties?: Dict) {
      track(`${category} - ${action}`, properties)
    },
  }
}
