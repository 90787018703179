import axios from 'axios'

import { authApiGatewayClient } from '@cozero/uris'
import { routes } from '@cozero/utils'

import { config } from './config'

const instance = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
  headers: {
    'api-client': config.API_URL,
  },
})

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      window.localStorage.setItem('FORCED_LOGOUT', 'true')
      if (error.request.responseURL.endsWith(authApiGatewayClient.auth.LOGIN_JWT_TOKEN)) {
        window.localStorage.setItem('EXPIRED_TOKEN', 'true')
        window.location.replace(routes.expiredToken)
      } else {
        window.location.replace(routes.logout)
      }
    }
    return Promise.reject(error)
  },
)

export default instance
