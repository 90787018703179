import React, { HTMLAttributes, ReactElement, ReactNode, memo, useMemo } from 'react'
import {
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
  HiOutlineXCircle,
} from 'react-icons/hi'

import Title from '../Title'

import classes from './Alert.module.less'

export interface AlertProps {
  type?: 'default' | 'info' | 'danger' | 'error' | 'warning' | 'success'
  children?: ReactNode
  title?: string
  message?: string
  textColor?: string
  icon?: 'info' | 'exclamation' | 'times'
  className?: string
}

const Icon = memo(
  ({
    className,
    type,
    icon,
  }: {
    className: string
    icon: AlertProps['icon']
    type: AlertProps['type']
  }): JSX.Element => {
    switch (type) {
      case 'info':
        return <HiOutlineInformationCircle className={className} />
      case 'danger':
        return icon === 'times' ? (
          <HiOutlineXCircle className={className} />
        ) : (
          <HiOutlineExclamationCircle className={className} />
        )
      default:
        return <HiOutlineInformationCircle className={className} />
    }
  },
)

Icon.displayName = 'AlertIcon'

const Alert = ({
  type = 'default',
  children,
  message,
  textColor,
  icon,
  title,
  className,
  ...rest
}: AlertProps & HTMLAttributes<HTMLDivElement>): ReactElement | null => {
  const classNames = useMemo(
    () => [classes.alert, classes[type], className].join(' '),
    [type, className],
  )
  if (title && message) {
    return (
      <div className={classNames} role="alert" {...rest}>
        <div className={classes.iconWrapper}>
          <Icon className={classes.icon} type={type} icon={icon} />
        </div>
        <div>
          <Title className={classes.title} size="sm">
            {title}
          </Title>
          <div style={{ color: textColor }}>{message}</div>
        </div>
      </div>
    )
  }
  if (!title && (message || children)) {
    return (
      <div className={classNames} role="alert" {...rest}>
        <div className={classes.iconWrapper}>
          <Icon className={classes.icon} type={type} icon={icon} />
        </div>
        <div style={{ color: textColor }}>{children || message}</div>
      </div>
    )
  }
  throw new Error('Set an alert message, please')
}

export default memo(Alert)
