import React, { ReactElement, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiStatusOffline } from 'react-icons/hi'

import { Col, Layout, Row } from 'antd/es'

import Title from '@/atoms/Title'

import classes from './styles.module.less'

const PageErrorTemplate = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Layout.Content hasSider style={{ height: '100vh', background: 'white' }}>
      <Row align="middle" justify="center" style={{ height: '100vh' }}>
        <Col>
          <div className={classes.errorWrapper}>
            <HiStatusOffline size="45" className={classes.errorIcon} />

            <Title size="sm" className={classes.errorText}>
              {t('general-errors.page')}
            </Title>

            <Title size="sm" className={classes.errorText}>
              {t('general-errors.try-again')}
            </Title>
          </div>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default memo(PageErrorTemplate)
