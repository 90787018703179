import { GroupedMethodologyChangelog, Log, Page, TableViewProductLog } from '@cozero/models'
import { calculationsApiGatewayClient } from '@cozero/uris'

import { TAG_CHANGELOG_LOGS, TAG_CHANGELOG_PRODUCTS } from '@/redux/logs/tags'
import { PARTIAL_LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import {
  FIND_CHANGELOG_LOGS,
  FIND_CHANGELOG_PRODUCTS,
  FIND_METHODOLOGY_CHANGELOG,
} from './constants'

const methodologyChangelogApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [FIND_METHODOLOGY_CHANGELOG]: builder.query<
      Page<GroupedMethodologyChangelog>,
      {
        page: number
        pageSize: number
      }
    >({
      query: ({ pageSize, page }) => ({
        url: calculationsApiGatewayClient.methodologyChangelog.GET_MANY,
        method: 'GET',
        params: {
          pageSize: pageSize,
          page: page,
        },
      }),
    }),
    [FIND_CHANGELOG_LOGS]: builder.query<
      Page<Log>,
      {
        businessUnitId: number | undefined
        changelogIds: number[]
        globalChangelogId: number | undefined
        page: number
        pageSize: number
        isClosedPeriod?: boolean
      }
    >({
      query: (log) => ({
        url: calculationsApiGatewayClient.methodologyChangelog.GET_CHANGELOG_LOGS,
        method: 'GET',
        params: { ...log },
      }),
      providesTags: (result) =>
        providesList<Log[], typeof TAG_CHANGELOG_LOGS>(
          result?.data,
          TAG_CHANGELOG_LOGS,
          PARTIAL_LIST,
        ),
    }),
    [FIND_CHANGELOG_PRODUCTS]: builder.query<
      Page<TableViewProductLog>,
      {
        changelogIds: number[]
        globalChangelogId: number | undefined
        page: number
        pageSize: number
      }
    >({
      query: (log) => ({
        url: calculationsApiGatewayClient.methodologyChangelog.GET_CHANGELOG_PRODUCTS,
        method: 'GET',
        params: { ...log },
      }),
      providesTags: (result) =>
        providesList<TableViewProductLog[], typeof TAG_CHANGELOG_PRODUCTS>(
          result?.data,
          TAG_CHANGELOG_PRODUCTS,
          PARTIAL_LIST,
        ),
    }),
  }),
})

export const {
  useFindMethodologyChangelogsQuery,
  useFindChangelogLogsQuery,
  useFindChangelogProductsQuery,
  usePrefetch,
} = methodologyChangelogApiSlice

export default methodologyChangelogApiSlice
