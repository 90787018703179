import { useMemo } from 'react'

import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import useSWR from 'swr'

import { PageFilter } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { SWRProduct } from '../types/SWRProduct'
import axios from '../utils/axios'

import useMemoCompare from './useMemoCompare'

/**
 * Hook to get overview stats.
 * @param {Object} params
 * @param {("location" | "product")} params.type
 * @param {PageFilter[]} params.filters
 * @param {String} params.businessUnitId
 */
const useOverviewStats = ({
  type,
  filters,
  businessUnitId,
}: {
  type: 'location' | 'product'
  filters?: Omit<PageFilter, 'options'>[]
  businessUnitId?: number
}): SWRProduct<{
  numberOfQuantities: number
  emissionsDataValue: number
  emissionsDataCount: number
  totalLogs: number
  percentageOfLogs: number
}> => {
  /**
   * To stop possible infinite loops
   * We have to make sure that the filters actually change every time
   */
  const memoedFilters = useMemoCompare<Omit<PageFilter, 'options'>[] | undefined>(
    filters,
    (
      prevFilters: Omit<PageFilter, 'options'>[] | undefined,
      nextFilters: Omit<PageFilter, 'options'>[] | undefined,
    ) => isEqual(prevFilters, nextFilters),
  )

  const { data, mutate, error, isValidating } = useSWR(
    [
      `${
        type === 'location'
          ? logApiGatewayClient.logs.GET_BUSINESS_UNIT_OVERVIEW
          : logApiGatewayClient.logs.GET_PRODUCT_OVERVIEW
      }`.replace(':businessUnitId', `${businessUnitId?.toString()}`),
      useMemo(
        (): {
          filters?: Omit<PageFilter, 'options'>[]
        } => ({
          filters:
            memoedFilters?.map((obj) => {
              const copyObj = cloneDeep(obj)
              if (obj.key === 'categoryId') {
                copyObj.key = copyObj?.key?.replace('categoryId', 'log.categoryId')
                return copyObj
              } else {
                return copyObj
              }
            }) ?? [],
        }),
        [memoedFilters, businessUnitId, type],
      ),
    ],
    async (url, { filters }) => {
      const { data } = await axios.post<{
        numberOfQuantities: number
        emissionsDataValue: number
        emissionsDataCount: number
        totalLogs: number
        percentageOfLogs: number
      }>(url, {
        query: filters,
      })
      return data
    },
    { revalidateOnFocus: false },
  )

  return {
    data,
    mutate,
    error,
    loading: !error && isValidating,
  }
}

export default useOverviewStats
