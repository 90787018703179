import React, { ReactElement, ReactEventHandler, ReactNode } from 'react'

import { Form as AntDesignForm, FormProps } from 'antd/es'
import { Store } from 'antd/es/form/interface'

import { useMixpanel } from '@/hooks/useMixpanel'
import { AnalyticsEvent } from '@/types/analytics'

interface Props extends FormProps {
  category: AnalyticsEvent
  children: ReactNode
  action?: string
}

const Form = ({ children, category, action, ...rest }: Props): ReactElement => {
  const { trackAction } = useMixpanel()

  const onError: ReactEventHandler<HTMLFormElement> = (event) => {
    if (action) {
      trackAction(category, action)
    }
    rest.onError?.(event)
  }

  const onFinish = (values: Store): void => {
    if (action) {
      trackAction(category, action)
    }
    rest.onFinish?.(values)
  }
  return (
    <AntDesignForm onFinish={onFinish} onError={onError} {...rest}>
      {children}
    </AntDesignForm>
  )
}

Form.Item = AntDesignForm.Item
Form.List = AntDesignForm.List
Form.useForm = AntDesignForm.useForm
Form.Provider = AntDesignForm.Provider
Form.ErrorList = AntDesignForm.ErrorList

export default Form
