import React, { ReactElement } from 'react'

import { Table as AntdTable, TablePaginationConfig } from 'antd/es'
import {
  ColumnsType,
  ExpandableConfig,
  FilterValue,
  GetRowKey,
  SorterResult,
  TableRowSelection,
} from 'antd/es/table/interface'

import { GetComponentProps, RowClassName } from 'rc-table/lib/interface'

import classes from './Table.module.less'

interface Props<RecordType extends object> {
  /**
   *  Object that describes the columns to display, must include a `dataSource` and `name` field.
   */
  columns: ColumnsType<RecordType>
  /**
   *  The rows of type `T` to display.
   */
  dataSource: readonly RecordType[]
  /**
   *  An optional field name or function to return a unique key for the row.
   */
  rowKey: string | GetRowKey<RecordType>
  /**
   *  An optional class name to style the table.
   */
  className: string
  /**
   *  An optional class name or callback to style rows.
   */
  rowClassName: string | RowClassName<RecordType>
  /**
   *  An optional callback to perform when a row is selected.
   */
  rowSelection: TableRowSelection<RecordType>
  /**
   *  An optional configuration object to describe how rows expand.
   */
  expandable: ExpandableConfig<RecordType>
  /**
   *  Optional text to display when the table has no data.
   */
  emptyText: string
  /**
   *  An optional object to describe the pagination of the table, can also be set to `false` to disable.
   */
  pagination: TablePaginationConfig | false
  /**
   *  An optional object to describe the scroll behavior of the table. `x` sets horizonal scroll area, `y` sets vertical.
   */
  scroll: {
    x?: number | true | string
    y?: number | string
  }
  /**
   *  An optional object to make callbacks row dependent.
   */
  onRow: GetComponentProps<RecordType>
  /**
   *  Optional change callbacks.
   */
  onChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
  ) => void
  /**
   *  An optional renderer for row summaries.
   */
  summary: (data: readonly RecordType[]) => React.ReactNode
  /**
   *  Toggle the header.
   */
  showHeader: boolean
  /**
   * Toggle the wrapper
   **/
  showWrapper?: boolean
  /**
   * Show loading spinner
   **/
  loading?: boolean
}

const Table = <T extends object>({
  columns,
  dataSource,
  rowKey,
  className,
  rowClassName,
  rowSelection,
  expandable,
  emptyText,
  pagination,
  scroll,
  onRow,
  onChange,
  summary,
  showHeader,
  showWrapper = true,
  loading,
}: Partial<Props<T>>): ReactElement => (
  <div className={showWrapper ? classes.tableWrapper : ''}>
    <AntdTable
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowKey={rowKey}
      className={className ?? classes.table}
      rowClassName={rowClassName ?? classes.row}
      rowSelection={rowSelection}
      expandable={expandable}
      locale={{ emptyText }}
      pagination={pagination}
      scroll={scroll}
      onRow={onRow}
      onChange={onChange}
      summary={summary}
      showHeader={showHeader}
    />
  </div>
)

Table.Summary = AntdTable.Summary

export default Table
