import React, { ReactElement, ReactNode, useMemo } from 'react'

import { Tooltip as AntTooltip, Col, Row } from 'antd/es'
import { TooltipPlacement, TooltipPropsWithTitle } from 'antd/es/tooltip'

import { CINDER_BLUE_90 } from '@/styles/variables'

import classes from './Tooltip.module.less'

export interface TooltipProps extends Omit<TooltipPropsWithTitle, 'title'> {
  /**
   * Content of the label
   */
  title?: string | ReactNode
  /**
   * Content of the subtitle
   */
  subTitle?: string | ReactNode
  /**
   * Tooltip text content
   */
  content?: string | ReactNode
  /**
   * Display an arrow pointing to the target
   */
  showArrow?: boolean
  /**
   * Placement of the arrow
   */
  placement?: TooltipPlacement
  /**
   * Target element
   */
  children: ReactElement | string | React.ReactNode
  /**
   * Color of the Tooltip '
   */
  color?: string
  showTooltip?: boolean
}

/**
 * Tooltip is a component that displays a small popup when hovering over a target.
 */
const Tooltip = ({
  title,
  children,
  showArrow = false,
  placement = 'bottom',
  color = CINDER_BLUE_90,
  subTitle,
  content,
  showTooltip = false,
  ...rest
}: TooltipProps): ReactElement => {
  const tooltipTitle = useMemo(
    () => (
      <Row>
        {title && (
          <Col span={24} className={classes.tooltipTitle}>
            {title}
          </Col>
        )}

        {subTitle && (
          <Col span={24} className={classes.tooltipSubtitle}>
            {subTitle}
          </Col>
        )}

        {content && (
          <Col span={24} className={classes.tooltipContent}>
            {content}
          </Col>
        )}
      </Row>
    ),
    [title, subTitle, content],
  )

  if (!showTooltip) {
    return <>{children}</>
  }
  return (
    <AntTooltip
      title={tooltipTitle}
      placement={placement}
      showArrow={showArrow}
      color={color}
      overlayClassName={classes.overlay}
      {...rest}
    >
      <span>{children}</span>
    </AntTooltip>
  )
}

export default Tooltip
