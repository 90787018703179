import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd/es'

import OverviewCard, { OverviewCardProps } from '@/molecules/OverviewCard'

import classes from './OverviewRow.module.less'

interface Props {
  loadingOverview: boolean
  marginBetween: number
  overviewData: OverviewCardProps[]
  hideTitle?: boolean
}
const OverviewRow = ({ loadingOverview, marginBetween, overviewData }: Props): JSX.Element => {
  const { t } = useTranslation('common')
  return (
    <div className={classes.overviewStatscontainer}>
      <Row className={classes.overviewTitle}>{t('log.overview-stats.title')}</Row>
      <Row gutter={[marginBetween, marginBetween]}>
        {overviewData.map((data) => (
          <Col key={data.headerTitle} className={classes.overviewCardContainer}>
            <OverviewCard
              loading={loadingOverview}
              content={data.content}
              headerTitle={data.headerTitle}
              headerColor={data.headerColor}
              tooltip={data.tooltip}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default OverviewRow
