export const GET_USER = 'getUser'
export const GET_USER_COUNT = 'getUserCount'
export const CREATE_USER = 'createUser'
export const INVITE_USER = 'inviteUser'
export const UPDATE_USER = 'updateUser'
export const DELETE_USER = 'deleteUser'
export const EXPORT_USERS = 'exportUsers'
export const CREATE_SUPPLIER_USER = 'createSupplierUser'

export const TAG_USERS = 'USERS'
export default [TAG_USERS]
