import { TFunction } from 'react-i18next'

import { sortBy } from 'lodash-es'

import {
  ActionArea,
  ActivityDataSource,
  BusinessUnit,
  Category,
  Customer,
  FactorOrigin,
  Location,
  LogEntryTag,
  LogType,
  Product,
  QuantityTag,
  Scope,
  Subcategory,
  Supplier,
  Territory,
  Unit,
  User,
} from '@cozero/models'
import { PageFilter } from '@cozero/models'

type PossibleOptions =
  | 'descriptions'
  | 'owners'
  | 'productTags'
  | 'productType'
  | 'products'
  | 'categories'
  | 'businessUnits'
  | 'locations'
  | 'logs'
  | 'customers'
  | 'description'
  | 'tags'
  | 'suppliers'
  | 'externalId'
  | 'quantityTags'
  | 'scopes'
  | 'actionAreas'
  | 'activityDataSources'
  | 'units'
  | 'factorOrigins'
  | 'territories'

export function createFilterOptions({
  type,
  categories,
  locations,
  customers,
  businessUnits,
  users,
  products,
  suppliers,
  tags,
  scopes,
  actionAreas,
  activityDataSources,
  units,
  territories,
  factorOrigins,
  t,
  featuresAllowed,
  shownOptions = [
    'descriptions',
    'owners',
    'productTags',
    'productType',
    'products',
    'categories',
    'businessUnits',
    'locations',
    'customers',
    'tags',
    'suppliers',
    'externalId',
    'quantityTags',
  ],
  page,
}: {
  type?: LogType | 'target' | 'budget' | 'carbonAction' | 'factors'
  subcategories?: Subcategory[]
  categories?: Category[]
  locations?: Location[]
  products?: Product[]
  customers?: Customer[]
  suppliers?: Supplier[]
  tags?: LogEntryTag[]
  quantityTags?: QuantityTag[]
  businessUnits?: BusinessUnit[]
  users?: User[]
  scopes?: Scope[]
  actionAreas?: ActionArea[]
  activityDataSources?: ActivityDataSource[]
  units?: Unit[]
  territories?: Territory[]
  factorOrigins?: FactorOrigin[]
  t: TFunction<'common'>
  featuresAllowed: string[]
  page: 'reports' | 'logs' | 'targets' | 'budgets' | 'act' | 'factors'
  shownOptions?: PossibleOptions[]
}): PageFilter[] {
  const filterOptions: PageFilter[] = []
  if (shownOptions.includes('categories')) {
    filterOptions.push({
      key: page === 'logs' || page === 'reports' ? 'categoryId' : 'category',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'notIn', label: t('filter.is-not') },
      ],
      options:
        categories?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.id.toString(),
          label: obj.name,
        })) || [],
      label: t('log.module'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    })
  }

  if (page === 'logs' || page === 'reports' || shownOptions.includes('description')) {
    filterOptions.push({
      key: 'description',
      type: 'string',
      conditions: [
        { key: 'contains', label: t('filter.contains') },
        { key: 'not', label: t('filter.not-contains') },
      ],
      label: t('log.description'),
      selectedCondition: { key: 'contains', label: t('filter.contains') },
      logType: type as LogType,
    })
  }
  if (shownOptions.includes('owners')) {
    filterOptions.push({
      key: page === 'logs' || page === 'reports' ? 'ownerId' : 'owner',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'notIn', label: t('filter.is-not') },
      ],
      options:
        users?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.id.toString(),
          label: obj.firstName && obj.lastName ? `${obj.firstName} ${obj.lastName}` : obj.email,
        })) || [],
      label: t('log.owner'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    })
  }
  if (featuresAllowed.includes('business-units')) {
    filterOptions.push({
      key: 'businessUnit',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'notIn', label: t('filter.is-not') },
      ],
      options:
        businessUnits?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.id.toString(),
          label: `${obj.title}${obj.metadata?.tags ? ` - ${obj.metadata?.tags.join(' - ')}` : ''}`,
        })) || [],
      label: t('log.business-unit'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    })
  }

  if (shownOptions.includes('locations')) {
    const locationFilter: PageFilter = {
      key: page === 'logs' || page === 'reports' || page === 'act' ? 'locationId' : 'location',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'notIn', label: t('filter.is-not') },
      ],
      options:
        locations?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.id.toString(),
          label: `${obj.name}${
            obj.metadata?.tags?.length ? ` - ${obj.metadata?.tags.join(' - ')}` : ''
          }`,
        })) || [],
      label: t('log.location'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(locationFilter)
  }

  if (shownOptions.includes('products')) {
    filterOptions.push({
      key: page === 'logs' || page === 'reports' ? 'productId' : 'product',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'notIn', label: t('filter.is-not') },
      ],
      options:
        products?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.id.toString(),
          label: `${obj.name}${
            obj.metadata?.tags?.length ? ` - ${obj.metadata?.tags.join(' - ')}` : ''
          }`,
        })) || [],
      label: t('log.product'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    })
  }

  if (shownOptions.includes('externalId') && products?.length) {
    filterOptions.push({
      key: 'externalId',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'notIn', label: t('filter.is-not') },
      ],
      options:
        products
          .filter((x) => x.externalId)
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.externalId === value.externalId),
          )
          ?.map((obj) => ({
            key: (obj.externalId as string).toString(),
            value: (obj.externalId as string).toString(),
            label: `${obj.externalId}`,
          })) || [],
      label: t('log.product'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    })
  }

  if (shownOptions.includes('productTags')) {
    const productTagsFilter: PageFilter = {
      key: 'tags',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'nin', label: t('filter.is-not') },
      ],
      options: [],
      label: t('product.productTag'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(productTagsFilter)
  }
  if (shownOptions.includes('quantityTags')) {
    const productTagsFilter: PageFilter = {
      key: 'quantityTags',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'nin', label: t('filter.is-not') },
      ],
      options: [],
      label: t('product.lifecycle-steps.quantity.quantity-tags'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(productTagsFilter)
  }

  if (shownOptions.includes('productType')) {
    const productTagsFilter: PageFilter = {
      key: 'productTypeId',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'nin', label: t('filter.is-not') },
      ],
      options: [],
      label: t('product.productType'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(productTagsFilter)
  }
  if (shownOptions.includes('customers')) {
    const customerFilter: PageFilter = {
      key: 'customer',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'nin', label: t('filter.is-not') },
      ],
      options:
        customers?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.id.toString(),
          label: obj.name as string,
        })) || [],
      label: t('quantity.customers'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(customerFilter)
  }

  if (shownOptions.includes('suppliers')) {
    const supplierFilter: PageFilter = {
      key: 'supplier',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'nin', label: t('filter.is-not') },
      ],
      options:
        suppliers?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.id.toString(),
          label: obj.name as string,
        })) || [],
      label: t('layout.suppliers'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(supplierFilter)
  }

  if (shownOptions.includes('tags')) {
    const tagFilter: PageFilter = {
      key: 'tag',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'nin', label: t('filter.is-not') },
      ],
      options:
        tags?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.name,
          label: obj.name as string,
        })) || [],
      label: t('layout.tags'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(tagFilter)
  }

  if (shownOptions.includes('scopes')) {
    const scopeFilter: PageFilter = {
      key: 'scope',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'nin', label: t('filter.is-not') },
      ],
      options:
        sortBy(scopes, ['categoryNumber', 'subcategoryNumber'])
          ?.filter(({ categoryNumber }) => categoryNumber !== 0)
          .map((obj) => ({
            key: obj.id.toString(),
            value: obj.id.toString(),
            label: `${obj.categoryNumber}${
              obj.subcategoryNumber ? '.' + obj.subcategoryNumber : ''
            }: ${obj.title}`,
          })) || [],
      label: t('cozero.scope.title'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(scopeFilter)
  }
  if (shownOptions.includes('actionAreas')) {
    const actionAreaFilter: PageFilter = {
      key: 'actionArea',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'nin', label: t('filter.is-not') },
      ],
      options:
        sortBy(actionAreas, ['name'])?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.id.toString(),
          label: `${obj.emoji} ${obj.name}`,
        })) || [],
      label: t('cozero.actionArea.title'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(actionAreaFilter)
  }
  if (shownOptions.includes('activityDataSources')) {
    const activityDataSourcesFilter: PageFilter = {
      key: 'activityDataSource',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'nin', label: t('filter.is-not') },
      ],
      options:
        sortBy(activityDataSources, ['name'])?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.id.toString(),
          label: obj.parent ? `${obj.parent.name} > ${obj.name}` : obj.name,
        })) || [],
      label: t('cozero.activityDataSource.title'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(activityDataSourcesFilter)
  }
  if (shownOptions.includes('units')) {
    const unitsFilter: PageFilter = {
      key: 'unit',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'nin', label: t('filter.is-not') },
      ],
      options:
        sortBy(units, ['name'])?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.id.toString(),
          label: `${obj.name}`,
        })) || [],
      label: t('cozero.unit.title'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(unitsFilter)
  }
  if (shownOptions.includes('territories')) {
    const territoriesFilter: PageFilter = {
      key: 'territory',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'nin', label: t('filter.is-not') },
      ],
      options:
        sortBy(territories, ['name'])?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.id.toString(),
          label: `${obj.name}`,
        })) || [],
      label: t('cozero.territory.title'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(territoriesFilter)
  }
  if (shownOptions.includes('factorOrigins')) {
    const factorOriginsFilter: PageFilter = {
      key: 'origin',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'nin', label: t('filter.is-not') },
      ],
      options:
        sortBy(factorOrigins, ['name'])?.map((obj) => ({
          key: obj.id.toString(),
          value: obj.id.toString(),
          label: `${obj.name}`,
        })) || [],
      label: t('cozero.factorOrigin.title'),
      selectedCondition: { key: 'in', label: t('filter.is') },
      logType: type as LogType,
    }
    filterOptions.push(factorOriginsFilter)
  }

  return filterOptions
}

export function createQueryFilter({
  value,
  type,
  t,
  options,
}: {
  value: string[]
  type: LogType
  t: TFunction<'common'>
  options: Location[] | Product[] | BusinessUnit[]
}): PageFilter[] {
  return [
    {
      logType: type as LogType,
      value,
      key: type === 'product' ? 'productId' : 'locationId',
      type: 'relationship',
      conditions: [
        { key: 'in', label: t('filter.is') },
        { key: 'notIn', label: t('filter.is-not') },
      ],
      options:
        options?.map((obj) => {
          const label = 'name' in obj ? obj.name : obj.title

          return {
            key: obj.id.toString(),
            value: obj.id.toString(),
            label: `${label}${obj.metadata?.tags ? ` - ${obj.metadata?.tags.join(' - ')}` : ''}`,
          }
        }) || [],
      label: t(`log.${type}`),
      selectedCondition: { key: 'in', label: t('filter.is') },
    },
  ]
}

export function createQuantityFilterOptions({
  products,
  t,
}: {
  products?: Product[]
  t: TFunction<'common'>
}): PageFilter[] {
  const filterOptions: PageFilter[] = []

  const productFilter: PageFilter = {
    key: 'productId',
    type: 'relationship',
    conditions: [
      { key: 'in', label: t('filter.is') },
      { key: 'notIn', label: t('filter.is-not') },
    ],
    options:
      products?.map((obj) => ({
        key: obj.id.toString(),
        value: obj.id.toString(),
        label: obj.name,
      })) || [],
    label: t('log.product'),
    selectedCondition: { key: 'in', label: t('filter.is') },
  }
  filterOptions.push(productFilter)
  return filterOptions
}
