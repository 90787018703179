import _ from 'lodash'

import { LogEntry, OrganizationEmission, ProductLogEntry } from '@cozero/models'

export function checkLogEntryIssues(
  logEntry: LogEntry | ProductLogEntry,
  emissions: OrganizationEmission[],
): boolean {
  return logEntry.calculating === false && !_.find(emissions, { logEntryId: logEntry.id })
}

export function isJson(str: string): boolean {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
