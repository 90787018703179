import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import ImgCrop from 'antd-img-crop'
import { Form, Popconfirm, Spin, Upload, UploadProps, message } from 'antd/es'
import { RcFile, UploadFile } from 'antd/es/upload/interface'

import moment from 'moment-timezone'

import { User } from '@cozero/models'
import { routes } from '@cozero/utils'

import ChangePasswordModal from '@/organisms/ChangePasswordModal'

import Button from '@/atoms/Button'
import InputField from '@/atoms/InputField'
import Select from '@/atoms/Select'
import Text from '@/atoms/Text'

import useSWRClearCache from '@/hooks/useSWRClearCache'
import { useAppSelector } from '@/redux'
import apiSlice from '@/redux/api'
import { selectUserOrganization } from '@/redux/auth'
import { useGetSignedUrlMutation, useUploadFileToSignedUrlMutation } from '@/redux/files'
import { useUpdateCurrentUserMutation } from '@/redux/userProfile'
import { useDeleteUserMutation } from '@/redux/users'
import { languages } from '@/utils/config'

import classes from './classes.module.less'
import { useFetchData } from './useFetchData'

const UserForm = (): ReactElement => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const clearCache = useSWRClearCache()

  const userOrganization = useAppSelector(selectUserOrganization)

  const { refetchData, loading, user, users } = useFetchData()

  const [deleteUser] = useDeleteUserMutation()
  const [getSignedUrl] = useGetSignedUrlMutation()
  const [updateMe] = useUpdateCurrentUserMutation()
  const [uploadFileToSignedUrl] = useUploadFileToSignedUrlMutation()

  const [form] = Form.useForm()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [isImageTouched, setIsImageTouched] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)

  const deleteUserPop = (): JSX.Element => {
    if (!user || !userOrganization) {
      return <div></div>
    }
    const free = 'free'
    const admin = 'admin'
    const { id, role } = user
    const { pricing } = userOrganization
    const isFreePricing = pricing?.key === free
    const isSoleAdmin =
      role?.type === admin &&
      users?.filter((user) => user.id !== id).every((user) => user.role?.type !== admin)
    const showCancel = !isSoleAdmin || isFreePricing
    const onConfirm = showCancel
      ? async () => {
          await deleteUser(id)
          navigate(routes.login.base)
        }
      : () => undefined
    const title = isSoleAdmin
      ? isFreePricing
        ? `${t('settings.users.delete.warning')} ${t('settings.users.delete.org')}`
        : t('settings.users.delete.contact')
      : t('settings.users.delete.warning')

    return (
      <Popconfirm
        title={title}
        onConfirm={onConfirm}
        okText={showCancel ? t('yes') : t('actions.ok')}
        cancelText={t('actions.cancel')}
        showCancel={showCancel}
      >
        <a>{t('actions.delete')}</a>
      </Popconfirm>
    )
  }

  const getImageSignedUrl = async (
    newFileList: UploadFile<unknown>[],
  ): Promise<{ name: string; fileObj?: RcFile; signedUrl: string; path: string }[]> => {
    const urls = await getSignedUrl(newFileList.map(({ name }) => name)).unwrap()
    return [
      ...newFileList.map((x, index) => ({
        name: x.name,
        fileObj: x.originFileObj,
        signedUrl: urls[index].signedUrl,
        path: urls[index].path,
      })),
    ]
  }

  const onUpload = ({ fileList: newFileList }: { fileList: UploadFile<unknown>[] }): void => {
    if (newFileList.length > 1) {
      newFileList.shift()
    }

    setFileList(newFileList)
    setIsImageTouched(true)
  }

  const onRemove = (): void => {
    setIsImageTouched(true)
  }

  const onSubmitUserInfo = async (values: User): Promise<void> => {
    try {
      const files = fileList.filter((x) => x.status === 'done')
      const filesToUpload = isImageTouched && files.length ? await getImageSignedUrl(files) : []
      if (filesToUpload.length) {
        await uploadFileToSignedUrl(filesToUpload[0]).unwrap()
      }

      const newUser = await updateMe({
        user: {
          email: values.email,
          firstName: values.firstName || '',
          lastName: values.lastName || '',
          jobDescription: `${values.jobDescription || 0}`,
          locale: `${values.locale}`,
          timezone: `${values.timezone}`,
        },
        image: filesToUpload[0]?.path,
      }).unwrap()

      if (values.locale !== user?.locale) {
        dispatch(apiSlice.util.resetApiState())
        refetchData()
        clearCache()
      }

      setIsImageTouched(false)

      if (newUser) {
        return message.success(t('profile.success'))
      }
    } catch (error) {
      return message.error(t('profile.update-failed'))
    }
  }

  function beforeUpload(file: RcFile): boolean {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(t('photo.upload-format'))
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error(t('photo.upload-size'))
    }
    return isJpgOrPng && isLt2M
  }

  const onPreview = async (file: UploadFile<unknown>): Promise<void> => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as Blob)
        reader.onload = () => resolve(reader.result as string)
      })
    } else {
      const image = new Image()
      image.src = src
      const imgWindow = window.open(src)
      imgWindow?.document.write(image.outerHTML)
    }
  }

  // To circumvent default behavior of Upload from antd
  const dummyRequest: UploadProps['customRequest'] = ({ file, onSuccess }) => {
    // Sending to the end of the stack to make sure the component state is already up to date
    setTimeout(() => {
      onSuccess && onSuccess(file)
    })
  }

  useEffect(() => {
    if (user) {
      form.resetFields()
      if (user.photo?.url) {
        setFileList([
          {
            uid: user.photo.id?.toString() || '',
            url: user.photo.url || '',
            name: user.photo.name || '',
            size: user.photo.size || 0,
            type: '',
            originFileObj: {} as RcFile,
          },
        ])
      }
    }
  }, [user])

  return (
    <>
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          initialValues={user}
          form={form}
          onFinish={onSubmitUserInfo}
          className={classes.infoForm}
        >
          <Form.Item
            label={t('user.email')}
            name="email"
            rules={[
              {
                required: true,
                message: t('email-required'),
              },
            ]}
          >
            <InputField className={classes.profileInput} />
          </Form.Item>

          {!userOrganization?.b2bLoginActive && (
            <Form.Item>
              <Button
                action="change-password"
                category="users"
                type="secondary"
                onClick={() => setShowPasswordModal(true)}
              >
                {t('password.change')}
              </Button>
            </Form.Item>
          )}

          <Form.Item label={t('user.first-name')} name="firstName">
            <InputField className={classes.profileInput} />
          </Form.Item>

          <Form.Item label={t('user.last-name')} name="lastName">
            <InputField className={classes.profileInput} />
          </Form.Item>

          <Form.Item label={t('user.job')} name="jobDescription">
            <InputField className={classes.profileInput} />
          </Form.Item>

          <Form.Item label={t('user.photo')} name="upload">
            <ImgCrop rotate minZoom={0.1}>
              <Upload
                accept=".png,.jpeg,.jpg"
                fileList={fileList}
                onChange={onUpload}
                name="photo"
                listType="picture-card"
                beforeUpload={beforeUpload}
                onPreview={onPreview}
                onRemove={onRemove}
                multiple={false}
                customRequest={dummyRequest}
              >
                + {t('photo.upload')}
              </Upload>
            </ImgCrop>
          </Form.Item>

          <Form.Item label={t('user.language')} name="locale">
            <Select
              options={languages.map((lang) => ({
                key: lang.id,
                value: lang.id,
                name: lang.id,
                label: lang.name,
              }))}
              style={{ width: '100px' }}
              defaultValue={user?.locale || 'en'}
            />
          </Form.Item>

          <Form.Item label={t('user.timezone')} name="timezone">
            <Select
              showSearch
              options={moment.tz.names().map((name) => {
                return {
                  key: name,
                  value: name,
                  name: name,
                  label: <Text size="xl">{name}</Text>,
                }
              })}
              style={{ width: '400px' }}
              defaultValue={user?.timezone || ''}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" category="users" action="update user info">
              {t('actions.submit')}
            </Button>
          </Form.Item>

          <span className={classes.disclaimer}>
            <Text size="xl" color="default">
              {t('profile.disclaimer')}(
              <a href="https://cozero.io/privacy" target="_blank" rel="noreferrer">
                {t('profile.policy')}
              </a>
              ).
            </Text>
          </span>

          <Form.Item>{deleteUserPop()}</Form.Item>
        </Form>
      </Spin>

      <ChangePasswordModal
        visible={showPasswordModal}
        toggleVisibility={() => setShowPasswordModal(!showPasswordModal)}
      />
    </>
  )
}

export default UserForm
