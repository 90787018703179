import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineDocumentAdd } from 'react-icons/hi'
import { useNavigate } from 'react-router'

import { routes } from '@cozero/utils'

import Button from '../../atoms/Button'

import classes from './FactorRequestButton.module.less'

const FactorRequestButton = ({
  type = 'primary',
}: {
  type?: 'primary' | 'secondary'
}): ReactElement => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Button
      category="factor-requests"
      action="create factor request"
      type={type}
      className={classes.createButton}
      onClick={() => navigate(routes.log.factors.request)}
      prefixIcon={<HiOutlineDocumentAdd />}
    >
      {t('suppliers.factor.request-general')}
    </Button>
  )
}

export default FactorRequestButton
