import i18n from '../i18n'

export function round(amount: number, decimals = 2): number {
  const multiplier = 10 ** decimals
  return Math.round(amount * multiplier) / multiplier
}

export const formatNumber = (number: number, options?: Intl.NumberFormatOptions): string => {
  const defaultOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }

  const formatter = new Intl.NumberFormat(
    localStorage.getItem('i18nextLng') ?? i18n.language,
    defaultOptions,
  )
  if (number > 0 && number < 0.01) {
    return `< ${formatter.format(0.01)}`
  }

  return formatter.format(number)
}

export const formatCompact = (value: number, options?: Intl.NumberFormatOptions): string => {
  const locale = localStorage.getItem('i18nextLng') ?? i18n.language
  const absolute = Math.abs(value)
  const sign = Math.sign(value) < 0 ? '-' : ''

  const longFormatter = new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  })

  const shortformatter = new Intl.NumberFormat(locale, {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    ...options,
  })

  if (absolute === 0) {
    return longFormatter.format(value)
  }

  if (0 < absolute && absolute < 0.01) {
    return `< ${sign}${longFormatter.format(0.01)}`
  }

  if (absolute >= 1000) {
    return `${sign}${shortformatter.format(absolute)}`
  }

  if (absolute % 1 === 0) {
    return `${sign}${shortformatter.format(absolute)}`
  }

  return `${sign}${longFormatter.format(absolute)}`
}

/**
 * Convert tonnes to grams
 * @param {number} value -  tonnes value to be converted
 * @return {number}
 */
export const convertTonnesToGrams = (value: number): number => (value ?? 0) * 1000 * 1000

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isNumeric(n: any): boolean {
  return !isNaN(parseFloat(n)) && isFinite(n as number)
}

/**
 * Get thousand separator character depending on the locale.
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 * @return {string}
 */
export function getLocaleThousandSeparator(locale: string): string {
  return Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '')
}
/**
 * Get decimal separator character depending on the locale.
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 * @return {string}
 */
export function getLocaleDecimalSeparator(locale: string): string {
  return Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '')
}

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 * @return {number}
 */
export function parseLocaleNumber(stringNumber: string, locale: string): number {
  const thousandSeparator = getLocaleThousandSeparator(locale)
  const decimalSeparator = getLocaleDecimalSeparator(locale)

  return parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.'),
  )
}

/**
 * Return the variation in percentage between the first value and the second value. Taking into account the first value as the base.
 * @param {number} value1 - the first base number
 * @param {number} value2 - the second number
 * @return {number}
 */
export function compareValues(value1: number, value2: number): number {
  const diff = value2 - value1
  return (diff / value1) * 100
}
