import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Col, Modal, Progress, Row } from 'antd/es'

import produce from 'immer'
import { sortBy } from 'lodash-es'

import { Onboarding as IOnboarding } from '@cozero/models'
import { routes } from '@cozero/utils'

import OnboardingStepCard from '@/organisms/Onboarding/OnboardingStepCard'
import ProgressCard from '@/organisms/Onboarding/ProgressCard'
import ResourceCenterCard from '@/organisms/ResourceCenterCard'

import OnboardingStepModal, { onboardingModalStyle } from '@/molecules/OnboardingStepModal'

import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { useAppDispatch, useAppSelector } from '@/redux'
import {
  getIsUserReadOnly,
  selectUser,
  selectUserOrganization,
  useLazyGetUserDataQuery,
} from '@/redux/auth'
import { selectSelectedBusinessUnitKey } from '@/redux/businessUnits'
import { useGetLocationsQuery } from '@/redux/locations'
import {
  selectIsFinished,
  selectSteps,
  setSteps,
  useDisableOnboardingStepMutation,
  useUpdateOnboardingMutation,
} from '@/redux/onboarding'
import { useGetProductsQuery } from '@/redux/products'

import classes from './Onboarding.module.less'
import ResourceOverview from './ResourceOverview'

function Onboarding(): JSX.Element {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const user = useAppSelector(selectUser)
  const [getMe] = useLazyGetUserDataQuery()
  const [updateOnboarding] = useUpdateOnboardingMutation()
  const dispatch = useAppDispatch()
  const userIsReadOnly = useAppSelector(getIsUserReadOnly)
  const organization = useAppSelector(selectUserOrganization)
  const businessUnitKey = useAppSelector(selectSelectedBusinessUnitKey)
  const steps = useAppSelector(selectSteps)
  const isFinished = useAppSelector(selectIsFinished)
  const sortedSteps = sortBy(steps, ['onboardingStepId', 'completed'])
  const [disableOnboarding] = useDisableOnboardingStepMutation()
  const { data: locations, isLoading } = useGetLocationsQuery(
    { businessUnitKey: businessUnitKey ?? '' },
    { skip: !businessUnitKey },
  )
  const [progress, setProgress] = useState(3)
  const { data: products } = useGetProductsQuery({
    businessUnitKey: businessUnitKey?.toString() as string,
  })

  const showSuccessModal = async (): Promise<void> => {
    await disableOnboarding('finalOnboardingModal')
    Modal.success({
      content: <OnboardingStepModal stepKey="final" />,
      ...onboardingModalStyle,
    })
  }

  // Show the success modal
  // when the user is finished and the
  // modal has not been shown yet
  useEffect(() => {
    if (
      user?.onboardingSteps &&
      user?.onboardingSteps?.length > 0 &&
      isFinished &&
      user?.onboarding &&
      (user?.onboarding as IOnboarding).finalOnboardingModal === undefined
    ) {
      showSuccessModal()
    }
  }, [isFinished])

  // Update the progress
  // bar based on the steps.
  useEffect(() => {
    if (steps) {
      const completed = steps.filter((step) => step.completed)
      const value = completed.length
      setProgress(value === 0 ? 2 : value)
    }
  }, [steps])

  const handleUpdateSteps = useCallback(
    async (state: boolean) => {
      const stepIndex = steps?.findIndex((step) => step.onboardingStep.key === 'locations')
      if (stepIndex === undefined && stepIndex !== -1) {
        throw new Error('Step not found')
      }
      const clonedSteps = steps ? [...steps] : []
      const updatedSteps = produce(clonedSteps, (draft) => {
        if (draft && draft[stepIndex]) {
          draft[stepIndex].completed = state
        }
      })
      const data = await updateOnboarding({
        steps: updatedSteps,
      }).unwrap()
      if (data) {
        await getMe().unwrap()
        dispatch(setSteps(data))
      }
    },
    [user],
  )
  useEffect(() => {
    if (products && user?.role?.type === 'supplier') {
      if (products.length) {
        navigate(routes.onboardingSupplierStep2)
      } else {
        navigate(routes.onboardingSupplierStep1)
      }
    }
  }, [products])

  // If the org has locations, and the
  // users location steps is unfinished, set
  // it to completed
  useEffect(() => {
    const isNotCompleted =
      steps?.findIndex((step) => step.onboardingStep.key === 'locations' && step.completed) === -1
    if (locations && locations.length && isNotCompleted) {
      handleUpdateSteps(true)
    }

    if (!locations?.length && !isLoading) {
      handleUpdateSteps(false)
    }
  }, [locations])

  return (
    <div className={classes.onboardingContainer} data-cy="onboarding-page">
      <Row gutter={16}>
        <Col span={20}>
          <Row gutter={8} className={classes.section}>
            <Col span={24}>
              <Title as="h1">
                {!user?.firstName || isFinished || userIsReadOnly
                  ? t('onboarding.demo.get-started')
                  : t('onboarding.demo.title', { name: user.firstName })}
              </Title>
            </Col>
            <Col span={24}>
              <Text color="secondary" size="xl">
                {isFinished || userIsReadOnly
                  ? t('onboarding.demo.description-finished')
                  : t('onboarding.demo.description')}
              </Text>
            </Col>
          </Row>
        </Col>
        {!userIsReadOnly && progress > 0 && (
          <Col span={4}>
            <Row>
              <Text size="xl">{`${progress}/${steps.length} ${t(
                'onboarding.demo.complete',
              )}`}</Text>
            </Row>
            <Row>
              <Progress percent={Math.floor((progress / steps.length) * 100)} showInfo={false} />
            </Row>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 8]}>
        {!userIsReadOnly &&
          !isFinished &&
          sortedSteps &&
          sortedSteps.map((step, idx) => {
            const open = sortedSteps[idx - 1]?.completed || idx === 0
            return (
              <Col key={step.onboardingStep.key} span={24}>
                <OnboardingStepCard
                  className={idx === 0 ? classes.onboardingTopCard : classes.onboardingCard}
                  title={step.onboardingStep.title}
                  subtitle={step.onboardingStep.subtitle}
                  wikiLinkText={step.onboardingStep.wikiLinkText}
                  wikiLink={step.onboardingStep.wikiLink}
                  actionLink={step.onboardingStep.actionLink}
                  cardNumber={idx + 1}
                  open={open}
                  completed={step.completed}
                />
              </Col>
            )
          })}
        {organization?.pricing?.key === 'free' && (
          <Col span={24}>
            <ProgressCard />
          </Col>
        )}
      </Row>
      {(userIsReadOnly || isFinished) && <ResourceOverview />}
      <Row>
        <ResourceCenterCard title={t('onboarding.demo.get-started-cozero')} />
      </Row>
    </div>
  )
}

export default Onboarding
