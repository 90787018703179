import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiSelector } from 'react-icons/hi'
import { useDispatch } from 'react-redux'

import { Skeleton, TreeSelect } from 'antd/es'

import { skipToken } from '@reduxjs/toolkit/query/react'
import classNames from 'classnames'
import { flatten, isEmpty } from 'lodash-es'
import debounce from 'lodash/debounce'
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect'

import { BusinessUnit, UserFunction } from '@cozero/models'

import { useDelayedLoading } from '@/hooks/useDelayedLoading'
import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import {
  selectSelectedBusinessUnit,
  setSelectedBusinessUnit,
  useGetActiveBusinessUnitsQuery,
  useGetBusinessUnitScopeQuery,
  useLazyGetBusinessUnitQuery,
} from '@/redux/businessUnits'
import { parseBusinessUnitsTreeData, updateTreeData } from '@/utils/tree'

import classes from './classes.module.less'

const BusinessUnitTreeDropdown = (): ReactElement => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const user = useAppSelector(selectUser)
  const [searchTerm, setSearchTerm] = useState('')
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[] | null>(null)
  const { data: businessUnitScope, isLoading: loadingBusinessUnitScope } =
    useGetBusinessUnitScopeQuery(selectedBusinessUnit?.id ?? skipToken)
  const [getBusinessUnit] = useLazyGetBusinessUnitQuery()
  const [delayedLoading] = useDelayedLoading(loadingBusinessUnitScope, 750)

  const { data: activeBusinessUnits = [] } = useGetActiveBusinessUnitsQuery(
    {
      businessUnitKey: selectedBusinessUnit?.key ?? '',
      search: searchTerm,
    },
    { skip: !selectedBusinessUnit },
  )

  const fetchSubTreeData = async (treeNode: DefaultOptionType): Promise<void> => {
    if (treeNode.value) {
      const subTreeParent = await getBusinessUnit({
        id: treeNode?.value.toString(),
        active: true,
      }).unwrap()
      const newTree = updateTreeData(
        businessUnits ?? [],
        treeNode.value as number,
        (subTreeParent.children as BusinessUnit[]) || [],
      )

      return setBusinessUnits(newTree)
    }
  }

  const onBusinessUnitSelect = useCallback(async (businessUnitId: number): Promise<void> => {
    const newBusinessUnit = await getBusinessUnit({
      id: `${businessUnitId}`,
      active: true,
    }).unwrap()

    dispatch(setSelectedBusinessUnit(newBusinessUnit))
  }, [])

  const onSearch = useCallback(
    debounce((search: string) => setSearchTerm(search), 500),
    [setSearchTerm],
  )

  // If no businessUnits are present, default should be the scope tree
  useEffect(() => {
    if (!businessUnits && businessUnitScope) {
      setBusinessUnits(businessUnitScope?.businessUnitTree)
    }
  }, [businessUnitScope])

  // If no selectedBusinessUnit is set, set it
  useEffect(() => {
    if (!selectedBusinessUnit && user && user.businessUnit) {
      dispatch(setSelectedBusinessUnit(user?.businessUnit as BusinessUnit))
    }
  }, [user])

  if (delayedLoading || !businessUnits) {
    return <Skeleton.Input active className={classes.skeleton} />
  }

  const userFunctionBusinessUnits = (user?.userFunctions ?? []).map(
    (userFunction) => userFunction.businessUnits,
  )

  const businessUnitsTree: BusinessUnit[] = isEmpty(searchTerm)
    ? [...businessUnits, ...(flatten(userFunctionBusinessUnits) as BusinessUnit[])]
    : activeBusinessUnits

  return (
    <TreeSelect<number>
      virtual
      placeholder={t('business-unit.select')}
      onSelect={onBusinessUnitSelect}
      defaultValue={selectedBusinessUnit?.id}
      value={selectedBusinessUnit?.id}
      loadData={fetchSubTreeData}
      treeData={parseBusinessUnitsTreeData(businessUnitsTree)}
      showSearch
      onSearch={onSearch}
      treeNodeLabelProp="title"
      treeNodeFilterProp="title"
      suffixIcon={<HiSelector />}
      popupClassName={classes.dropdown}
      className={classNames(classes.treeSelect, classes.dark)}
      dropdownMatchSelectWidth={false}
      treeLine
    />
  )
}

export default BusinessUnitTreeDropdown
