import React, { ReactNode } from 'react'

import { Col, Row, Skeleton, Tooltip } from 'antd/es'

import classNames from 'classnames'

import Text from '@/atoms/Text'

import { CINDER_BLUE_60, CINDER_BLUE_80 } from '@/styles/variables'

import classes from './OverviewCard.module.less'

export interface OverviewCardProps {
  headerColor?: string
  headerTitle: string
  headerWeight?: 'bold' | 'medium' | 'semibold' | 'regular' | 'light'
  tooltip?: {
    triggerElement: ReactNode
    subtitle: string
  }
  content: ReactNode
  isVisible?: boolean
  style?: React.CSSProperties | undefined
  loading?: boolean
  className?: string
  truncateHeader?: boolean
}

const OverviewCard = ({
  headerTitle,
  headerColor = CINDER_BLUE_60,
  headerWeight = 'medium',
  tooltip,
  content,
  loading,
  className,
  truncateHeader,
  ...rest
}: OverviewCardProps): JSX.Element => {
  return (
    <>
      <div className={`${classes.overviewContainer} ${className && classes[className]}`} {...rest}>
        {loading ? (
          <Skeleton active paragraph={{ rows: 2 }} />
        ) : (
          <>
            <Row className={classes.header}>
              <Col>
                <Text
                  className={classNames({ [classes.truncate]: truncateHeader })}
                  size="xl"
                  fontWeight={headerWeight}
                  style={{ color: headerColor }}
                  title={headerTitle}
                >
                  {headerTitle}
                </Text>
              </Col>

              <Col>
                {tooltip && (
                  <Col className={classes.tooltipIcon}>
                    <Tooltip
                      overlayClassName={classes.tooltip}
                      color={CINDER_BLUE_80}
                      title={tooltip?.subtitle}
                    >
                      {tooltip.triggerElement}
                    </Tooltip>
                  </Col>
                )}
              </Col>
            </Row>

            {content}
          </>
        )}
      </div>
    </>
  )
}

export default OverviewCard
