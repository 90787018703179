import React, { ReactNode } from 'react'

import { Col, Row } from 'antd/es'

import { OverviewCardProps } from '@/molecules/OverviewCard'

import OverviewRow from '../OverviewRow'

import classes from './classes.module.less'

function CarbonFootprintWrapper({
  children,
  overviewLoading,
  overviewData,
  showOverview = true,
}: {
  children: ReactNode
  overviewLoading: boolean
  showOverview?: boolean
  overviewData: OverviewCardProps[]
}): JSX.Element {
  return (
    <>
      {/* The overview is temporarily hidden for PCF as the underlying reports are showing incorrect data. */}
      {showOverview && (
        <Row>
          <Col span={24} className={classes.headerWrapper}>
            <OverviewRow
              loadingOverview={overviewLoading}
              marginBetween={14}
              overviewData={overviewData}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>{children}</Col>
      </Row>
    </>
  )
}

export default CarbonFootprintWrapper
