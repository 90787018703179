import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineBookOpen, HiOutlineDocumentReport, HiOutlineLightningBolt } from 'react-icons/hi'

import { PieChartOutlined } from '@ant-design/icons'

import { routes } from '@cozero/utils'

import i18n from '@/i18n'

import { MenuItem } from './NavSidebar'
import { useMenuFeature } from './NavSidebar.useMenuFeature'

export const useMenuItems = (): { menuItems: MenuItem[] } => {
  const { t } = useTranslation()

  const { canUseMenuFeature } = useMenuFeature()

  const menuItems = useMemo(() => {
    const items = [
      {
        key: 'overview',
        title: t('layout.overview'),
        icon: <PieChartOutlined />,
        feature: 'log',
        children: [
          {
            key: 'home',
            title: t('layout.dashboard.title'),
            route: routes.overview.dashboard,
            feature: 'log',
          },
          {
            key: 'reports',
            title: t('layout.reports'),
            route: routes.overview.reports,
            feature: 'reports',
          },
        ],
      },
      {
        key: 'log',
        title: 'Log',
        icon: <HiOutlineBookOpen />,
        feature: 'log',
        children: [
          {
            key: 'organization',
            title: t('layout.organization'),
            route: routes.organization.locations.base,
            cy: 'organization',
            feature: 'log',
          },
          {
            key: 'carbonFootprint',
            title: t('layout.carbon-footprint'),
            route: routes.log.carbonFootprint.organization.base,
            cy: 'carbon-footprint',
            feature: 'log',
          },
          {
            key: 'factors',
            title: t('layout.factors'),
            route: routes.log.factors.base,
            feature: 'custom-factors',
          },
          {
            key: 'customers',
            title: t('layout.customers'),
            route: routes.log.customers,
            feature: 'log',
            excludeRoles: ['viewer'],
          },
          {
            key: 'suppliers',
            title: t('layout.suppliers'),
            route: routes.log.suppliers,
            feature: 'log',
            excludeRoles: ['viewer'],
          },
          {
            key: 'tags',
            title: t('location.tags'),
            route: routes.log.tags,
            feature: 'log',
            excludeRoles: ['viewer'],
          },
          {
            key: 'closedPeriods',
            title: t('layout.closed-periods'),
            route: routes.log['closed-periods'],
            feature: 'closed-period',
          },
        ],
      },
      {
        key: 'act',
        title: 'Act',
        icon: <HiOutlineLightningBolt />,
        feature: 'act',
        children: [
          {
            key: 'customActions',
            title: t('act.climateActions.title'),
            route: routes.act.climateActions,
            feature: 'act',
            newFeature: true,
          },
          {
            key: 'targets',
            title: t('act.targets.nav-title'),
            route: routes.act.targets,
            feature: 'act',
          },
          {
            key: 'marketplace',
            title: t('act.marketplace.title'),
            route: routes.act.marketplace,
            feature: 'act',
          },
        ],
      },
      {
        key: 'share',
        title: 'Share',
        icon: <HiOutlineDocumentReport />,
        feature: 'share',
        children: [
          {
            key: 'sharePages',
            title: t('layout.share-pages'),
            route: routes.share.sharePages,
            feature: 'share',
          },
          {
            key: 'stories',
            title: t('layout.stories'),
            route: routes.share.stories,
            feature: 'share',
          },
          {
            key: 'certifications',
            title: t('layout.certificates'),
            route: routes.share.certifications,
            feature: 'share',
          },
          {
            key: 'sustainabilityReports',
            title: t('layout.sustainability-reports'),
            route: routes.share.sustainabilityReports,
            feature: 'share',
          },
        ],
      },
    ]

    return items.filter((item) => canUseMenuFeature(item))
  }, [i18n.language])

  return { menuItems }
}
