import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Col, Form, Row, Spin } from 'antd/es'
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select'

import { FilterKey } from '..'
import { debounce } from 'lodash-es'

import { PageFilter } from '@cozero/models'

import Select, { Option } from '@/atoms/Select'

import {
  selectSelectedBusinessUnitKey,
  useGetActiveBusinessUnitsQuery,
} from '@/redux/businessUnits'
import { createFilterOptions } from '@/utils/filters'

import classes from './../FiltersDrawer.module.less'

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Select {
  export type value = number[]
  export type option = BaseOptionType | DefaultOptionType | (BaseOptionType | DefaultOptionType)[]
}

type PageFilterWithOptions = PageFilter & {
  options: {
    key?: string
    value: string
    label: string
  }[]
}

interface Props {
  featuresAllowed: string[]
  filterOptions: PageFilterWithOptions[]
  values: {
    [FilterKey.SCOPE]: number[]
    [FilterKey.BUSINESS_UNIT]: number[]
    [FilterKey.CATEGORY]: number[]
    [FilterKey.RESPONSIBLE]: number[]
    [FilterKey.ACTION_AREA]: number[]
  }
  onChange: (filter: PageFilter & { key: FilterKey }, value: Select.value | string) => void
}
const ClimateActionsFiltersForm = ({
  onChange,
  filterOptions,
  values,
  featuresAllowed,
}: Props): JSX.Element => {
  const { t } = useTranslation('')
  const selectedBusinessUnitKey = useSelector(selectSelectedBusinessUnitKey)
  const multipleAllowed = featuresAllowed.includes('multiple-filtering')

  const [searchTerms, setSearchTerms] = useState({
    [FilterKey.SCOPE]: '',
    [FilterKey.BUSINESS_UNIT]: '',
    [FilterKey.CATEGORY]: '',
    [FilterKey.RESPONSIBLE]: '',
    [FilterKey.ACTION_AREA]: '',
  })

  const handleChange = (value: Select.value | string, key: string): void => {
    const index = filterOptions?.findIndex((x) => x.key === key)
    // Add business unit filter options if the user has selected a business unit because they are not included in the initial filter options since they are fetched from the API
    // This is necessary to shw the BUs in the filter pills
    if (key === FilterKey.BUSINESS_UNIT) {
      const businessUnitFilterOptions =
        createFilterOptions({
          businessUnits: businessUnitOptions,
          t,
          shownOptions: ['businessUnits'],
          page: 'act',
          featuresAllowed: ['business-units'],
        }) ?? []

      if (index !== -1) {
        filterOptions[index].options = (
          businessUnitFilterOptions[0] as PageFilterWithOptions
        ).options
      }
    }

    if (filterOptions[index]) {
      return onChange(filterOptions[index] as PageFilter & { key: FilterKey }, value)
    }
  }

  const onSearch = (value: string, key: string): void => {
    setSearchTerms({ ...searchTerms, [key]: value })
  }

  const { data: paginatedBusinessUnits, isLoading: businessUnitLoading } =
    useGetActiveBusinessUnitsQuery(
      {
        businessUnitKey: selectedBusinessUnitKey ?? '',
        search: searchTerms[FilterKey.BUSINESS_UNIT],
      },
      { skip: !selectedBusinessUnitKey || !searchTerms[FilterKey.BUSINESS_UNIT] },
    )

  const {
    scope: scopeOptions,
    actionArea: actionAreaOptions,
    businessUnit: businessUnitOptions,
    ownerId: createdByOptions,
    categoryId: categoryOptions,
  } = useMemo(() => {
    const options = filterOptions.reduce(
      (acc, value) => {
        return {
          ...acc,
          [value.key as string]:
            value.options?.map((x) => ({
              ...x,
              value: +x.value,
              key: x.key ? +x.key : x.key,
            })) || [],
        }
      },
      {
        [FilterKey.CATEGORY]: [],
        [FilterKey.TAG]: [],
        [FilterKey.RESPONSIBLE]: [],
        [FilterKey.SCOPE]: [],
        [FilterKey.ACTION_AREA]: [],
      },
    )
    return {
      ...options,
      [FilterKey.BUSINESS_UNIT]: paginatedBusinessUnits,
    }
  }, [filterOptions, paginatedBusinessUnits])

  return (
    <>
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t('cozero.scope.title')}>
              <Select
                mode={multipleAllowed ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.placeholder')}
                fieldNames={{ label: 'label', value: 'value' }}
                optionFilterProp="label"
                value={values.scope}
                options={scopeOptions}
                onChange={(value) => {
                  const cleanValue = multipleAllowed ? value : [value]
                  handleChange(cleanValue, FilterKey.SCOPE)
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('cozero.actionArea.title')}>
              <Select
                mode={multipleAllowed ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.placeholder')}
                fieldNames={{ label: 'label', value: 'value' }}
                optionFilterProp="label"
                value={values.actionArea}
                options={actionAreaOptions}
                onChange={(value) => {
                  const cleanValue = multipleAllowed ? value : [value]
                  handleChange(cleanValue, FilterKey.ACTION_AREA)
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('business-unit.name')}>
              <Select
                showSearch
                defaultActiveFirstOption={false}
                filterOption={false}
                mode={multipleAllowed ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.search')}
                value={values.businessUnit}
                onChange={(value) => {
                  const cleanValue = multipleAllowed ? value : [value]
                  handleChange(cleanValue, FilterKey.BUSINESS_UNIT)
                }}
                onSearch={debounce((term) => onSearch(term, FilterKey.BUSINESS_UNIT), 500)}
                style={{ width: '100%' }}
                dropdownRender={(menu) => {
                  if (businessUnitLoading) {
                    return (
                      <span className={classes.dropdownContainer}>
                        <Spin />
                      </span>
                    )
                  }
                  return businessUnitOptions?.length ? (
                    menu
                  ) : (
                    <span className={classes.dropdownContainer}>{t('log.filter.search')}</span>
                  )
                }}
              >
                {businessUnitOptions?.map((bu) => (
                  <Option key={bu.id} value={bu.id}>
                    {bu.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('log.category')}>
              <Select
                mode={multipleAllowed ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.placeholder')}
                value={values.categoryId}
                options={categoryOptions}
                optionFilterProp="label"
                onChange={(value) => {
                  const cleanValue = multipleAllowed ? value : [value]
                  handleChange(cleanValue, FilterKey.CATEGORY)
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('log.owner')}>
              <Select
                mode={multipleAllowed ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.placeholder')}
                fieldNames={{ label: 'label', value: 'value' }}
                optionFilterProp="label"
                value={values.ownerId}
                options={createdByOptions}
                onChange={(value) => {
                  const cleanValue = multipleAllowed ? value : [value]
                  handleChange(cleanValue, FilterKey.RESPONSIBLE)
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default React.memo(ClimateActionsFiltersForm)
